import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./Page404.scss";
import Img404 from "../../assets/images/404.svg";
import LangTextLabel from "../../components/Common/LangTextLabel";
import { getConfig } from "../../utility";

const Page404 = () => {
  const history = useHistory();

  const goToBack = () => {
    history.goBack();
  };
  return (
    <div className="page404Content pageContent fullHeight">
      <div className="page404">
        <div className="linkWrap">
          <NavLink className="link" to="#" onClick={() => goToBack()}>
            <LangTextLabel lngCode="go-back" />
          </NavLink>
        </div>
        <div className="imgWrap">
          <img src={Img404} className="page404Img" alt="404" />
        </div>
      </div>
      <div className="loginFooter">
        <div className="versionDetails">
          <div className="icon version" />
          <div className="text">{getConfig("app.version")}</div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
