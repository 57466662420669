import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfirmationBoxState } from "./confirmationBoxModel";

// Define the initial state using that type
const initialState: ConfirmationBoxState = {
  title: "",
  message: "",
  okayTxt: "",
  cancelTxt: "",
  isOpen: false,
  type: "",
  hasExtraBtn: false,
  extraBtnText: "",
};

export const alertBoxSlice = createSlice({
  name: "confirmationBox",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    openConfirmationBox: (state) => {
      state.isOpen = true;
    },
    closeConfirmationBox: (state) => {
      state.isOpen = false;
    },
    updateConfirmationBoxContent: (
      state,
      {
        payload: {
          title,
          message,
          okayTxt,
          cancelTxt,
          type,
          hasExtraBtn,
          extraBtnText,
        },
      }: PayloadAction<{
        title: string;
        message: string;
        okayTxt: string;
        cancelTxt: string;
        type: string;
        hasExtraBtn?: boolean;
        extraBtnText?: string;
      }>
    ) => {
      state.title = title;
      state.message = message;
      state.okayTxt = okayTxt;
      state.cancelTxt = cancelTxt;
      state.type = type;
      state.hasExtraBtn = hasExtraBtn;
      state.extraBtnText = extraBtnText;
    },
    clearConfirmationBox: (state) => {
      state.title = "";
      state.message = "";
      state.okayTxt = "";
      state.cancelTxt = "";
      state.isOpen = false;
      state.type = "";
      state.hasExtraBtn = false;
      state.extraBtnText = "";
    },
  },
});

export const {
  openConfirmationBox,
  closeConfirmationBox,
  updateConfirmationBoxContent,
  clearConfirmationBox
} = alertBoxSlice.actions;

export default alertBoxSlice.reducer;
