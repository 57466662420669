import { authSlice } from './authSlice'
import { setTopNavConfectionery } from '../layout/layoutSlice'
import { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { AuthModal, LoginModel, TerminalModel, UserModel } from './authModel'
import AuthService from '../../services/authService'
import { getConfig, setToken } from '../../utility'
import { openAlertBox, updateAlertBoxContent } from '../alertBox/alertBoxSlice'
import ROUTE_CONSTANT_MAP from '../../route/routes-url'
import { layoutSlice } from '../layout/layoutSlice'
import moment from 'moment'
import { setCurrentDate } from '../ticketing/ticketingSlice'

const layoutActions = layoutSlice.actions

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay))
}

export const authActions = authSlice.actions

export const login = (
  data: LoginModel,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    try {
      dispatch(layoutActions.setIsPageLoader(true));
      const response: any = await AuthService.login(data)
      if (response.status_code === 1003) {
        dispatch(authActions.setValidationError(response.message))
        return
      } else {
     
        const { data } = response
        const {
          user_data,
          token,
          terminal_data,
          concession_enabled,
          currency_symbol,
          currency_code,
          pos_version,
          idle_time,
          date_format,
          time_format,
          date_format_with_placeholders,
          decimal_currency_separator,
          thousand_currency_separator
        } = data

        const user: UserModel = {
          username: user_data.username,
          id: user_data.uid,
          fullname: user_data.fullname,
          email: user_data.email,
          token: token,
          isManager: Boolean(user_data.is_manager),
        }
        setToken(token)
        dispatch(authActions.setUser(user))
        dispatch(
          authActions.setConcessionEnable(
            concession_enabled === 1 ? true : false,
          ),
        )
        dispatch(authActions.setCurrencySymbol(currency_symbol))
        dispatch(authActions.setCurrencyCode(currency_code))
        dispatch(authActions.setPosVersion(pos_version))
        dispatch(authActions.setIdleTime(Number(idle_time)))
        dispatch(authActions.setDateFormat(date_format))
        if(date_format_with_placeholders && date_format_with_placeholders[date_format]) {
          dispatch(authActions.setDateFormatPlaceholder(date_format_with_placeholders[date_format]));
        }else {
          dispatch(authActions.setDateFormatPlaceholder(date_format));
        }
        dispatch(authActions.setTimeFormat(time_format))
        dispatch(
          setTopNavConfectionery(concession_enabled === 1 ? true : false),
        )
        dispatch(authActions.setCurrencySeperator(decimal_currency_separator ?? "."))
        dispatch(authActions.setThousandSeperator(thousand_currency_separator ?? ","))

        const {
          id,
          code,
          name,
          location,
          terminal_url,
          api_key,
          software_house_id,
          installer_id,
          terminal_id,
        } = terminal_data
        const integrationType = getConfig('payment_sense_integration_type')
        const connectedVersion = getConfig('payment_sense_connect_version')

        const terminal: TerminalModel = {
          id: id,
          code: code,
          name: name,
          location: location,
          terminalUrl: terminal_url,
          apiKey: api_key,
          softwareHouseId: software_house_id,
          installerId: installer_id,
          terminalId: terminal_id,
          paymentWebsocketUrl: `wss://${terminal_url}/${integrationType}?token=${api_key}&api-version=${connectedVersion}&software-house-id=${software_house_id}&installer-id=${installer_id}`,
        }
        dispatch(authActions.setTerminal(terminal))
        dispatch(setCurrentDate(moment().format("YYYY-MM-DD")))
        return
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  }
}

export const counterValidate = (
  data: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const response: any = await AuthService.counterValidate(data)

    const { message, status } = response

    if (!status) {
      dispatch(authActions.setManagerValidation(message))
      return
    }
    dispatch(authActions.setManagerValidation('MANAGER_VALIDATED'))
  }
}
