import { Button } from "@material-ui/core";
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../hooks/useRedux";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import { getLangLabel } from "../../utility";
import LangTextLabel from "../Common/LangTextLabel";
import "./DataTable.scss";

interface DataTableListProps {
  dataList: any;
  totalRows: number;
  currentPage: number;
  handlePageChange: any;
  handlePerRowsChange: any;
}

const DataListTable = (props: DataTableListProps) => {
  const { terminal } = useAppSelector((state) => state.auth);
  const history = useHistory();

  const {
    dataList,
    totalRows,
    currentPage,
    handlePageChange,
    handlePerRowsChange,
  } = props;

  const columns: any = useMemo(
    () => [
      {
        name: getLangLabel("ref-no"),
        selector: "reference_no",
        sortable: true,
      },
      {
        name: getLangLabel("purchase-date"),
        selector: "purchased_date",
        sortable: true,
      },
      {
        name: getLangLabel("name"),
        selector: "name",
        sortable: true,
      },
      {
        name: getLangLabel("email"),
        selector: "email",
        sortable: true,
      },
      {
        name: getLangLabel("sales-channel"),
        selector: "sales_channel",
        sortable: true,
      },
      {
        name: getLangLabel("amount"),
        selector: "amount",
        sortable: true,
      },
      {
        name: getLangLabel("status"),
        selector: "status",
        sortable: true,
      },
      {
        // eslint-disable-next-line react/button-has-type
        cell: (row: any) => (
          <Button
            variant="contained"
            onClick={() => {
              history.push(ROUTE_CONSTANT_MAP.PAST_SALE_DETAIL, {
                saleId: row.reference_no,
                terminal_code: terminal?.code,
              });
            }}
            color="default"
            className="viewBtn"
          >
            <LangTextLabel lngCode="  View" />
          </Button>
        ),
      },
    ],
    []
    // [handleDelete]
  );

  return (
    <DataTable
      columns={columns}
      data={dataList}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
};

export default DataListTable;
