import React, { Component, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import TopNavigation from "./TopNavigation/TopNavigation";
import MainHeader from "./MainHeader/MainHeader";
import FooterNavigation from "./FooterNavigation/FooterNavigation";
import "./POSLayout.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import KeyboardNormal from "../KeyboardLayouts/KeyboardNormal/KeyboardNormal";
import {
  setKeyboardVisibility,
  setKeyboardInputValue,
} from "../../../redux/keyboard/keyboardSlice";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { getConfig } from "../../../utility";
import KeyboardDefault from "../KeyboardLayouts/KeyboardDefault";

interface POSLayoutProps {
  children: any;
}

const POSLayout: React.FC<POSLayoutProps> = (props: POSLayoutProps) => {
  const dispatch = useAppDispatch();
  const keyboard = useRef<any>();

  const topNav = useAppSelector((state) => state.layout.topNav);
  const header = useAppSelector((state) => state.layout.header);
  const keyboardVisibility = useAppSelector(
    (state) => state.keyboard.visibility
  );
  const { keyboardInputName, keyboardInputValue } = useAppSelector(
    (state) => state.keyboard
  );
  const layoutRef = useRef<any>();

  const onChangeKeyboard = (input: any) => {
    dispatch(setKeyboardInputValue(input));
  };

  const handleClickOutside = () => {
    dispatch(setKeyboardVisibility(false));
  };

  useOnClickOutside(layoutRef, handleClickOutside);
  return (
    <div className="mainLayoutPageContent pageContent fullHeight">
      {topNav ? <TopNavigation /> : null}
      {header ? <MainHeader /> : null}
      <div className="pageBody">
        {props.children}
        {getConfig("app.keyboard.enable") === "TRUE" ? (
          <div
            ref={layoutRef}
            className={`keyboardContainer ${!keyboardVisibility ? "hide" : ""}`}
          >
            <div className="numberPadWrap">
              <KeyboardDefault
                ref={keyboard}
                onChange={(inputs) => {
                  onChangeKeyboard(inputs);
                }}
                inputName={keyboardInputName}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default POSLayout;
