import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Button } from "@material-ui/core";
import PriceConverter from "../../../../PriceConverter";
import { useAppSelector } from "../../../../../../hooks/useRedux";
import { css } from "../../../../../../utility";
import { ProductModel } from "../../../../../../redux/concessions/concessionsModel";

interface ContentMainProps {
  type: string;
  tarifObj: any;
  onRemoveClick: (data: any) => void;
  onCountClick: (data: any) => void;
  isRetractable?: boolean;
  isEditable?: boolean;
}

const ContentMain = (props: ContentMainProps) => {
  const { tarifObj, onRemoveClick, onCountClick, isEditable, type, isRetractable } = props;
  const currencySymbol = useAppSelector((state) => state.auth.currencySymbol);
  const { basketObj } = useAppSelector((state) => state.basket);
  const { categoryDetails } = useAppSelector((state) => state.concessions);
  const [isError, setIsError] = useState(false);

  const styles = {
    iconButtonStyles: {
      padding: 0,
    },
    buttonStyles: {
      padding: 0,
      minWidth: 0,
      width: "100%",
      height: "100%",
    },
    buttonDisableStyles: {
      padding: 0,
      minWidth: 0,
      width: "100%",
      height: "100%",
      color: "var(--color-rgba-3)",
      boxShadow: "none",
      backgroundColor: "var(--color-rgba-2)",
    },
  };

  useEffect(() => {
    if (tarifObj && type === "confectionery") {
      let productsArray: ProductModel[] = [];
      categoryDetails.forEach((categoryObj) => {
        if (categoryObj) {
          productsArray.push(...categoryObj.product);
        }
      });

      const prodObj = productsArray.find((obj) => {
        return obj.id === tarifObj.id;
      });
      
      if (Number(tarifObj.qty) > Number(prodObj?.availableCount)) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }
  }, [tarifObj]);

  return (
    <div className="contentMain">
      <div className="contentMainBox">
        <div className="contentCountWrap">
          <Button
            style={
              isEditable ? styles.buttonStyles : styles.buttonDisableStyles
            }
            onClick={() => onCountClick(tarifObj)}
            disabled={!isEditable}
          >
            <div className={css("contentCount", isError ? "error" : "")}>
              {tarifObj.qty}
            </div>
          </Button>
        </div>
        <div className="contentLabelWrap">
          <div className={css("contentLabel", isError ? "error" : "")}>
            {tarifObj.label}
          </div>
          {tarifObj.supplementId ? (
            <div className={css("subContentLabel", isError ? "error" : "")}>
              {tarifObj.supplementName}
            </div>
          ) : null}
        </div>
        <div className="contentValueWrap">
          <div className={css("contentValue", isError ? "error" : "")}>
            {currencySymbol}
            <PriceConverter
              priceToConvert={
                tarifObj.totalPrice
                  ? `${parseFloat(tarifObj.totalPrice.toString()).toFixed(2)}`
                  : 0
              }
            />
          </div>
          {tarifObj.supplementId ? (
            <div className={css("subContentValue", isError ? "error" : "")}>
              {currencySymbol}
              <PriceConverter
                priceToConvert={
                  tarifObj.supplementTotalPrice
                    ? `${parseFloat(
                        tarifObj.supplementTotalPrice.toString()
                      ).toFixed(2)}`
                    : 0
                }
              />
            </div>
          ) : null}
        </div>
        {isRetractable ? (
          <div className="contentRemoveWrap">
            <IconButton
              style={styles.iconButtonStyles}
              onClick={() => onRemoveClick(tarifObj.id)}
            >
              <div className="contentRemove" />
            </IconButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ContentMain;
