import { Button, Grid } from '@material-ui/core'
import React from 'react'
import Legend from '../legend'
import Screen from '../../../assets/images/screen.png'
import './SeatLayout.scss'

interface SeatLayoutProps {
  seats: any
  maxPositions: {x: string, y: string}
}

const SeatPlan: React.FC<SeatLayoutProps> = (props: SeatLayoutProps) => {
  const { seats, maxPositions } = props
  return (
      <div className="seatSelectionCont">
        <div className="seatSelectionWrap">
          <div className="seatPlan">
            <Legend />
            <div className="screenCont">
              <div className="screenWrap">
                <div className="screenImg">
                  <img
                    src={Screen}
                    alt="Screen"
                    className="img-fluid img-100"
                  />
                </div>
              </div>
            </div>
            <div className="seatStructureWrap">
              <div className="seatStructure">
                <div className="blockPlanContainer" style={{width: `${parseInt(maxPositions.x) * 2}px`, height: `${parseInt(maxPositions.y) * 2}px`}}>{seats}</div>
              </div>
            </div>
          </div>
          {/* <div className="ticketSelecterWrap">
            <div className="ticketSelecter"></div>
          </div> */}
        </div>
      </div>
    
  )
}
export default SeatPlan
