import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LangTextLabel from '../../../LangTextLabel';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setBookingCountDown, setBookingRenewStatus } from '../../../../../redux/basket/basketSlice';
import { getConfig } from '../../../../../utility';

interface BasketTimerProps {
}

const BasketTimer = (props: BasketTimerProps) => {

  //const { bookingTime } = useAppSelector((state) => state.auth)
  const { bookingCountDown, bookingRenewStatus } = useAppSelector((state) => state.basket)
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  const dispatch = useAppDispatch();

  const bookingTime = getConfig("app.booking_time")
  useEffect(() => {
    if(bookingTime) {
      if(bookingCountDown > 0) {
        setCountDown(bookingCountDown);
      }
      togglerTimer()
    }
    
  },[])

  useEffect(() => {

    if(bookingRenewStatus && bookingRenewStatus === "renew") {
      setCountDown(bookingCountDown);
      dispatch(setBookingRenewStatus(null))
    }
  },[bookingRenewStatus])

  useEffect(() => {
    let timerId: string | number | NodeJS.Timeout | undefined;

    if (runTimer) {
      if(countDown > 0) {
        setCountDown(countDown);
      } else{
        setCountDown(60 * bookingTime);
        dispatch(setBookingCountDown(60 * bookingTime))
      }
     
      
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    /**
     * decrease one sec for minimize the delay
     */
    dispatch(setBookingCountDown(countDown))
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
      dispatch(setBookingCountDown(-1))
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer(true);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");
    return (
      <div className="timerTxt">
        {minutes}:{seconds}
        </div>
    );
  }

export default BasketTimer;
