import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import ConcessionService from "../../services/concessionsService";
import { CategoryModel, ProductModel } from "./concessionsModel";
import { concessionsSlice } from "./concessionsSlice";
import { layoutSlice } from "../layout/layoutSlice";
import { openAlertBox, updateAlertBoxContent } from "../alertBox/alertBoxSlice";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import { updateAvailableCountOfConfectionoryItem } from "../basket/basketSlice";

export const concessionsActions = concessionsSlice.actions;
const layoutActions = layoutSlice.actions;

export const getConcessions = (
  avaialableList?: string[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await ConcessionService.getConcessions();
      const { data } = response;

      let categoryDetailsArray: CategoryModel[] = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        const { category_id, category_name, color, products } = element;

        if (index == 0) {
          dispatch(concessionsActions.setSelectedCategory(category_id));
        }

        let productDetailsArray: ProductModel[] = [];
        for (let index = 0; index < products.length; index++) {
          const product = products[index];

          const {
            product_id,
            product_name,
            product_description,
            product_image,
            unit_price,
            currency_symbol,
            qty,
            barcode
          } = product;
          const productData: ProductModel = {
            id: product_id,
            productName: product_name,
            productDescription: product_description,
            productImage: product_image,
            productUnitPrice: unit_price,
            currencySymbol: currency_symbol,
            availableCount: qty,
            barcode: barcode, 
            categoryId: category_id
          };

          productDetailsArray.push(productData);
        }

        const categoryData: CategoryModel = {
          id: category_id,
          color: color,
          categoryName: category_name,
          product: productDetailsArray,
        };
        categoryDetailsArray.push(categoryData);
      }

      dispatch(concessionsActions.setCategory(categoryDetailsArray));

      /**
       * update basket item's avaialable count
       */
      if (avaialableList && avaialableList.length > 0) {
        avaialableList.forEach((itemId) => {
          let productsArray: ProductModel[] = [];
          categoryDetailsArray.forEach((categoryObj) => {
            if (categoryObj) {
              productsArray.push(...categoryObj.product);
            }
          });

          const prodObj = productsArray.find((obj) => {
            return obj.id === itemId;
          });
          
          if(prodObj) {
            dispatch(
              updateAvailableCountOfConfectionoryItem({
                id: itemId,
                avaialableCount: prodObj?.availableCount ?? 0,
              })
            );
          } else {
            dispatch(
              updateAvailableCountOfConfectionoryItem({
                id: itemId,
                avaialableCount: 0,
              })
            );
          }
          
        });
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const validateIssueConsession = (
  dataItem: any,
  history: any,
  stateObj: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    dispatch(concessionsActions.setBarcodeScannerError(""));
    try {
      const response: any = await ConcessionService.issuingConsession(dataItem);

      const { status, status_code, message } = response;

      if (!status && (status_code === 1003 || status_code === 1103)) {
        dispatch(concessionsActions.setBarcodeScannerError(message));
      }

      if (status) {
        dispatch(
          concessionsActions.toggleConsessionIssueModalVisibility(false)
        );
        history.push(ROUTE_CONSTANT_MAP.PAST_SALE_DETAIL, stateObj);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const issueConsession = (
  dataItem: any,
  history: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await ConcessionService.issuingConsession(dataItem);
      if (response.status && response.status_code == 5000) {
        dispatch(
          updateAlertBoxContent({
            alertType: "success",
            message: response.message,
          })
        );
        dispatch(openAlertBox());
        history.push(ROUTE_CONSTANT_MAP.PAST_SALES);
        return;
      }

      if (!response.status) {
        dispatch(concessionsActions.setBarcodeScannerError(response.message ? response.message : response.status_message ? response.status_message : "Concession issue failed"));
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};
