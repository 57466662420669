/* eslint-disable */
const ROUTE_CONSTANT_MAP = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  TICKETING: "/cashier/ticketing",
  TICKETING_NOW: "/cashier/ticketing/now",
  TICKETING_TODAY: "/cashier/ticketing/today",
  TICKETING_CALENDAR: "/cashier/ticketing/calendar",
  TICKETING_PMR: "/cashier/ticketing/pmr",
  TARIFS: "/cashier/ticketing/tarifs",
  PAYMENT: "/cashier/payment",
  PRODUCT_WITH_PARAMS: "/cashier/product/view/:id",
  VIEW_PRODUCT: "/cashier/product/view/{}",
  MANAGEMENT: "/cashier/management",
  CONFECTIONARY: "/cashier/confectionery",
  STORES: "/cashier/stores",
  VIRTUAL_REALITY: "/cashier/virtual-reality",
  PAST_SALES: "/cashier/past-sales",
  PAST_SALE_DETAIL: "/cashier/past-sales-view",
  PAST_SALE_REFUND_DETAIL: "/cashier/past-sales-refund",
  LOG_OUT: "/cashier/log-out",
  TEST: "/test",
};

export default ROUTE_CONSTANT_MAP;
