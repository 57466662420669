import React, { useImperativeHandle, useRef, useState } from "react";
// import moment from 'moment';
import moment from "moment-timezone";
import CalendarView from "../../Common/CalendarView";
import NumberPad from "../../Common/KeyboardLayouts/NumberPad/NumberPad";
import CustomInput from "../../Common/CustomInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../../Common/InputError";

interface NumberPadModalRef {
  getNumberValue(): void;
}
const NumberPadModal = React.forwardRef<NumberPadModalRef, any>(
  (props, ref) => {
    const [numberValue, setNumberValue] = useState(null);
    const keyboardRef = useRef<any>(null);
    const numberPadSchema = Yup.object().shape({
      numberValue: Yup.number().required("Filed is required"),
    });

    const formik = useFormik({
      initialValues: {
        numberValue: "",
      },
      validationSchema: numberPadSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values));
      },
    });

    const onChangeKeyboard = (input: any) => {
      formik.setFieldValue("numberValue", input);
    };

    const handleInputChange = (event: any) => {
      formik.setFieldValue("numberValue", event.target.value);
    };

    useImperativeHandle(ref, () => ({
      getNumberValue() {
        if(formik.values.numberValue.length > 0) {

          if(parseFloat(formik.values.numberValue) === 0) {
            formik.setFieldError("numberValue", "Invalid Quantity");
            
          } else {
            return formik.values.numberValue
          }
          
        } else {
          formik.setFieldError("numberValue", "Field is required");
        }
        return null;
      },
    }));

    return (
      <>
        <div className="formWrap">
          <CustomInput
            name="numberValue"
            onChange={(e) => handleInputChange(e)}
            type="number"
            value={formik.values.numberValue}
          />
        </div>
        <div>
          {formik.errors.numberValue ? (
            <InputError error={formik.errors.numberValue} />
          ) : null}
        </div>

        <NumberPad
          keyboardRef={keyboardRef}
          onChange={onChangeKeyboard}
          inputName="countModInput"
          currentValue={""}
        />
      </>
    );
  }
);
export default NumberPadModal;
