import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import LangTextLabel from "../../LangTextLabel";
import { useAppSelector } from "../../../../hooks/useRedux";

interface CardPayementProps {
  type: string;
  icon: string;
  id: string | number;
  onPaymentClick: (id: any) => void;
  isAcive?: boolean
  isDisable?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBaseStyles: {
      backgroundColor: 'transparent',
      padding: 0,
      minHeight: 50,
      width: '100%',
      color:"var( --color-black)",
    },
    buttonBaseActiveStyles: {
      backgroundColor: 'var(--color-14) !important',
      padding: 0,
      minHeight: 50,
      width: '100%',
      color:'var(  --color-black)',
    },
    buttonBaseDisableStyles: {
      backgroundColor: "var(  --color-rgba-2)",
      padding: 0,
      minHeight: 50,
      width: '100%',
      color: "var( --color-rgba-3)",
      cursor: "default",
      pointerEvents: "none",
    }
  }),
);

const CardPayement = (props: CardPayementProps) => {
  const { type, icon, id, onPaymentClick, isAcive, isDisable } = props;
  const classes = useStyles();
  const [buttonClass, setButtonClass] = useState(classes.buttonBaseStyles)

  useEffect(() => {

    if(isAcive) {
      setButtonClass(classes.buttonBaseActiveStyles)
    }else {
      setButtonClass(classes.buttonBaseStyles)
    }
  },[isAcive])

  useEffect(() => {

    if(isDisable) {
      setButtonClass(classes.buttonBaseDisableStyles)
    }else {
      if(isAcive) {
        setButtonClass(classes.buttonBaseActiveStyles)
      }else {
        setButtonClass(classes.buttonBaseStyles)
      }
    }
  },[isDisable])
  
  return (
    <div className="cardBtn cardPayement">
      <ButtonBase className={buttonClass} onClick={() => onPaymentClick(id)}>
        <div className="btnContent">
          <div className="btnWrap">
            <div className={`icon`}>
              <img src={icon} className="img-fluid" />
            </div>
            <div className="text">{type}</div>
          </div>
        </div>
      </ButtonBase>
    </div>
  );
};

export default CardPayement;
