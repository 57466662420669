import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConcessionsModel, CategoryModel } from "./concessionsModel";

// Define the initial state using that type
const initialState: ConcessionsModel = {
  selectedCategory: null,
  categoryDetails: [],
  consessionMenuVisibility: false,
  consessionIssueModalVisibility: false,
  barcodeScannerError: null,
  consessionNotAvaialableList: [],
  isssueConsessionBtnEnabled: false,
};

export const concessionsSlice = createSlice({
  name: "concessions",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearConcession(state) {
      state.selectedCategory = null;
      state.categoryDetails = [];
      state.consessionMenuVisibility = false;
      state.consessionIssueModalVisibility = false;
      state.barcodeScannerError = "";
      state.consessionNotAvaialableList = [];
    },
    setCategory(state, action: PayloadAction<CategoryModel[]>) {
      state.categoryDetails = action.payload;
    },
    setSelectedCategory(state, action: PayloadAction<any>) {
      state.selectedCategory = action.payload;
    },
    toggleConseionMenuVisibility(state, action: PayloadAction<boolean>) {
      state.consessionMenuVisibility = action.payload;
    },
    toggleConsessionIssueModalVisibility(
      state,
      action: PayloadAction<boolean>
    ) {
      state.consessionIssueModalVisibility = action.payload;
    },
    setBarcodeScannerError(state, action: PayloadAction<string>) {
      state.barcodeScannerError = action.payload;
    },
    setConsessionNotAvailableList(state, action: PayloadAction<string[]>) {
      state.consessionNotAvaialableList = action.payload;
    },
    setIsssueConsessionBtnEnabled(state, action: PayloadAction<boolean>) {
      state.isssueConsessionBtnEnabled = action.payload;
    },
    updateProductAvaialableCount(
      state,
      action: PayloadAction<{
        categoryDetails: any[];
        categoryId: string | number;
        productId: string | number;
        avaialable: number;
      }>
    ) {

      const { categoryDetails, categoryId, productId, avaialable } = action.payload;
      const categoryIndex = categoryDetails.findIndex((obj) => { return obj.id === categoryId});

      if(categoryIndex < 0) {
        return;
      }
      const categoryObj = categoryDetails[categoryIndex];

      const productIndex = categoryObj.product.findIndex((pobj: any) => { return pobj.id === productId});

      if(productIndex < 0) {
        return;
      }
      let productObj = {...categoryObj.product[productIndex]};
      productObj.availableCount = avaialable;

      state.categoryDetails[categoryIndex].product[productIndex] = productObj;

    },
  },
});
export default concessionsSlice.reducer;
