import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import "./ConfirmationDialog.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useAppSelector } from "../../../hooks/useRedux";
import { closeConfirmationBox } from "../../../redux/confirmationBox/confirmationBoxSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1500
    },
    paperStyle: {
      padding: "20px 20px",
      paddingTop: "15px",
      minHeight: '18vh',
      maxWidth: "40vw",
      zIndex: 1500
    },
  })
);

interface ConfirmationDialogProps {
  onOkayClick?: (type: any) => void;
  onCancelClick?: (type: any) => void;
  onExtraBtnClick?: (type: any) => void;
}
const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const classes = useStyles();
  const { onOkayClick, onCancelClick, onExtraBtnClick } = props;
  const { isOpen, title, message, okayTxt, cancelTxt, type, hasExtraBtn, extraBtnText } = useAppSelector(
    (state) => state.confirmationBox
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeConfirmationBox());
    onCancelClick && onCancelClick(type)
  };
  return (
    <Dialog
    className="popup-custom-styles"
      open={isOpen}
      // onClose={handleClose}
      fullWidth
      maxWidth="sm"
      classes={{
        paper: classes.paperStyle,
        root:classes.root
      }}
      keepMounted
    >
      {title && title !== "" ? <DialogTitle>{title}</DialogTitle> : ""}
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onOkayClick  && onOkayClick(type)}
         className="confirmBtn"
          autoFocus          
        >
          {okayTxt}
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          color="default"
          className="cancelBtn"
        >
          {cancelTxt}
        </Button>
        {hasExtraBtn ? <Button
          variant="contained"
          onClick={() => onExtraBtnClick && onExtraBtnClick(type)}
          color="default"
          className="cancelBtn"
        >
          {extraBtnText}
        </Button> : null }
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
