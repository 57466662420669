import React, { Component } from "react";
import { ButtonBase, Grid } from "@material-ui/core";
import { css, getLangLabel } from "../../../../utility";
import "./ProductDetails.scss";
import PriceConverter from "../../PriceConverter";

interface ProductDetailsProps {
  id: string | number;
  name: string;
  description: string;
  image: string;
  price: string;
  color: string;
  currencySymbol: string;
  availableCount: number;
  onProductClick?: (obj: any) => void;
}

const ProductDetails = (props: ProductDetailsProps) => {
  const {
    id,
    name,
    description,
    image,
    price,
    currencySymbol,
    color,
    onProductClick,
    availableCount
  } = props;
  return (
    <div
      className={css("cardBtn", "products")}
      style={{ borderColor: color }}
      onClick={() => onProductClick && onProductClick({ id, label: name, price, availableCount })}
    >
      <ButtonBase className={"categoryBtn"}>
        <div className="btnContent">
          <div className="btnWrap">
            <div className="detailWrap">
              <div className="productImageWrap">
                <img className="productImage img-fluid" src={image} />
              </div>
              <div className="descWrap">
                <div className="productNameWrap">
                  <div className="productName">{name}</div>
                </div>
                <div className="productDescWrap">
                  <div className="productDesc">{description}</div>
                </div>
                <div className="productPriceWrap">
                  <div className="productPrice">
                    {currencySymbol}
                    <PriceConverter priceToConvert={price}/> 
                  </div>
                </div>
                <div className="productQtyWrap">
                  <div className="productDesc">{`${availableCount} ${getLangLabel("items-available")}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ButtonBase>
    </div>
  );
};

export default ProductDetails;
