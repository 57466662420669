import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch } from '../../../hooks/useRedux'
import { getConfig } from '../../../utility'
import InputError from '../../Common/InputError'
import KeyboardDefault from '../../Common/KeyboardLayouts/KeyboardDefault'
import LangTextLabel from '../../Common/LangTextLabel'
import { concessionsActions } from '../../../redux/concessions/concessionsAction'
import barcode from '../../../assets/icons/action/barcode.svg'
import qr from '../../../assets/icons/action/qr.svg'
import './BarcodeScanner.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      // minHeight: '30vh',
      maxWidth: '30vw',
      // padding: '30px',
      boxShadow: 'none',
    },
    confirmBtnStyle: {
      margin: 0,
      padding: '15px',
      backgroundColor: 'var( --cta-color)',
      borderRadius: 5,
      color: 'var(--color-white)',
      width: '100%',
      display: 'block',
      lineHeight: '25px',
      '&:hover': {
        backgroundColor: 'var(  --cta-color-hover)',
      },
    },
    confirmBtnDiableStyle: {
      margin: 0,
      padding: '15px',
      backgroundColor: 'var(  --color-rgba-2)',
      borderRadius: 5,
      color: 'var( --color-rgba-3)',
      width: '100%',
      display: 'block',
      lineHeight: '25px',
    },
    boxStyle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 'auto',
      height: '100%',
      marginTop: '10px',
    },
  }),
)

interface BarcodeScannerProps {
  onConfirm: (value: string) => void
  isError?: boolean
  errorText?: string
  type: 'qr' | 'barcode',
  descriptionText: string
}
const BarcodeScanner = (props: BarcodeScannerProps) => {
  const { onConfirm, isError, errorText, type, descriptionText = "" } = props
  const classes = useStyles()
  const keyboard = useRef<any>()
  const inputRef = useRef<HTMLInputElement>(null)

  const [input, setInput] = useState<any>('')
  const [inputName, setInputName] = useState('consessionBarCode')

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(concessionsActions.setBarcodeScannerError(''))
    if (inputRef) {
      inputRef.current?.focus()
    }
  }, [])

  const onChangeKeyboard = (input: any) => {
    setInput(input)
  }

  const onChangeInput = (event: any) => {
    const inputVal = event.target.value
    setInput(inputVal)
  }

  const handleConfirm = (inputVal: string) => {
    onConfirm(inputVal)
    setInput('')
  }

  const onKeyPressBarcode = (event: any) => {
    if (event.keyCode === 13) {
      setInput(event.target.value)
      onConfirm(event.target.value)
      setInput('')
    }
  }
  return (
    <Box className={classes.boxStyle}>
      <Card className="scannerCard">
        <div className="iconContentWrapper">
          {type === 'barcode' ? (
            <>
              <div className="icon scanner">
                <img src={barcode} className="popupIcon" alt="barcode" />
              </div>
              <LangTextLabel lngCode="scanner" />
            </>
          ) : (
            <>
              <div className="icon qr">
                <img src={qr} className="popupIcon" alt="qr" />
              </div>
              <LangTextLabel lngCode="QR" />
            </>
          )}
        </div>
        <CardContent>
          <div className="formWrap">
            <div className="formGroup">
              {type === 'barcode' ? (
                <div className="descWrapper">{descriptionText}</div>
              ) : (
                <div className="descWrapper">{descriptionText}</div>
              )}
              <div className="formControl ">
                <input
                  autoFocus={true}
                  ref={inputRef}
                  type="text"
                  name="consessionBarCode"
                  value={input}
                  className="formInput barcodeInput"
                  onChange={onChangeInput}
                  onKeyDown={onKeyPressBarcode}
                />
              </div>
              <div className='errorWrapper'>
                {isError ? <InputError error={errorText ?? ''} /> : null}
              </div>
            </div>
          </div>
        </CardContent>
        {/* <CardActions disableSpacing>
          <Button
            onClick={() => {
              handleConfirm(input)
            }}
            className={classes.confirmBtnStyle}
          >
            <LangTextLabel lngCode={'confirm'} />
          </Button>
        </CardActions> */}
      </Card>

      {/* {getConfig('app.keyboard.enable') === 'TRUE' ? (
        <div className={`keyboardContainer`}>
          <div className="numberPadWrap">
            <KeyboardDefault
              ref={keyboard}
              onChange={(input) => {
                onChangeKeyboard(input)
              }}
              inputName={inputName}
            />
          </div>
        </div>
      ) : null} */}
    </Box>
  )
}

export default BarcodeScanner
