import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// import moment from 'moment';
import moment from "moment-timezone";
import CalendarView from "../../Common/CalendarView";
import NumberPad from "../../Common/KeyboardLayouts/NumberPad/NumberPad";
import CustomInput from "../../Common/CustomInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../../Common/InputError";
import "./PaymentSuccessModal.scss";
import success from "../../../assets/images/success.png";
import PriceConverter from "../../Common/PriceConverter";
import LangTextLabel from "../../Common/LangTextLabel";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { getPrintDetails } from "../../../redux/pastSales/pastSalesAction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../Common/ModalBox/ModalBox.scss";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../../Common/TicketLayout/ComponentToPrint";
import { setIsPageLoader } from "../../../redux/layout/layoutSlice";
import { setIsPaymentSuccesModalOpen } from "../../../redux/basket/basketSlice";

interface PaymentSuccessModalProps {
  orderRef: any;
  modalTitle: string;
  onClose?: () => void;
  visibility: boolean;
  onConfirm?: () => void;
  confirmText?: string;
  maxWidth: any;
  confimDisable?: boolean;
}

interface PaymentSuccessModalRef {
  getNumberValue(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButtonStyles: {
      position: "absolute",
      right: "5px",
      top: "5px",
    },
    paperStyle: {
      padding: " 30px",
    },
    titleStyles: {
      padding: 0,
      textAlign: "center",
      marginBottom: 15,
      fontWeight: 700,
    },
    contentStyles: {
      padding: "0px 0px",
    },
    actionStyles: {
      margin: 0,
      marginTop: 15,
      padding: "0px 0px",
    },
    confirmBtnStyle: {
      margin: 0,
      padding: 15,
      backgroundColor: "var(--cta-color)",
      borderRadius: 5,
      color: "var(--color-white)",
      width: "100%",
      display: "block",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "var(--cta-color-hover)",
      },
    },
    confirmBtnDisableStyle: {
      margin: 0,
      padding: 15,
      backgroundColor: "var(--color-rgba-2)",
      cursor: "default",
      pointerEvents: "none",
      borderRadius: 5,
      color: "var( --color-rgba-3)",
      width: "100%",
      display: "block",
      lineHeight: "25px",
    },
  })
);

const PaymentSuccessModal = React.forwardRef<
  PaymentSuccessModalRef,
  PaymentSuccessModalProps
>((props, ref) => {
  const {} = props;
  const dispatch = useAppDispatch();
  const {
    orderRef,
    modalTitle,
    onClose,
    visibility,
    onConfirm,
    confirmText = "confirm",
    maxWidth,
    confimDisable,
  } = props;

  const classes = useStyles();
  let componentRef: any = useRef(null);
  const { printDetailsObj } = useAppSelector((state) => state.passales);
  const { isTicketPrintEnable, reservationId } = useAppSelector(
    (state) => state.basket
  );
  const [printBtnEnable, setPrintBtnEnable] = useState(false);
  const printBtnRef = useRef<HTMLButtonElement>(null);

  const onBeforeGetContentResolve = useRef<any>(null);

  const [loading, setLoading] = React.useState(false);

  const handleAfterPrint = useCallback(() => {}, []);

  const handleBeforePrint = useCallback(() => {}, []);

  const handleOnBeforeGetContent = useCallback(() => {

    setLoading(true);
    dispatch(
      getPrintDetails({
        reference_no: orderRef,
        type: "all",
        booking_id: null,
        reservation_id: reservationId,
      })
    );

    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setPrintBtnEnable(true)
        resolve();
        setTimeout(() => {
          dispatch(setIsPageLoader(false));
          onClose && onClose()
        },500)
        
      }, 1500);
    });
  }, [setLoading]);

  useEffect(() => {
    if (
      printDetailsObj &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [printDetailsObj]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;
    // Good
    return (
      <Button
        color="primary"
        className={
          loading ? classes.confirmBtnDisableStyle : classes.confirmBtnStyle
        }
      >
        <div className="btnTxt">
          <LangTextLabel lngCode="Print Ticket" />
        </div>
      </Button>
    );
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={visibility}
      fullWidth
      maxWidth={maxWidth}
      scroll="body"
      classes={{
        paper: classes.paperStyle,
      }}
    >
      <IconButton className={classes.iconButtonStyles} onClick={onClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.titleStyles}>
        <div className="tarifCountModifierTitleTxt">{modalTitle}</div>
      </DialogTitle>
      <DialogContent className={classes.contentStyles}>
        <div className="paymentSuccessWrapper">
          <div className="iconWrapper">
            <img src={success} alt="" />
          </div>
          {isTicketPrintEnable ? (
            <div className="textWrapper">
              {" "}
              <div className="text">{"Print your ticket"}</div>
            </div>
          ) : null}
        </div>
        <div className="hide">
          <ComponentToPrint
            ticketPrintData={printDetailsObj}
            ref={componentRef}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actionStyles}>
        {isTicketPrintEnable ? (
          <>
            <ReactToPrint
              content={reactToPrintContent}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              trigger={reactToPrintTrigger}
            />
          </>
        ) : null}
      </DialogActions>
    </Dialog>
  );
});
export default PaymentSuccessModal;
