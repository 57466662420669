import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { authActions } from "../../../redux/auth/authActions";
import { getConfig, getLangLabel } from "../../../utility";
import InputError from "../../Common/InputError";
// import moment from 'moment';
import NumberPad from "../../Common/KeyboardLayouts/NumberPad/NumberPad";
import LangTextLabel from "../../Common/LangTextLabel";
import "./OtpModal.scss";

interface OtpModalRef {
  getNumberValue(): void;
}

const OtpModal = React.forwardRef<OtpModalRef, any>((props, ref) => {
  const [numberValue, setNumberValue] = useState<string>("");
  const [inputs, setInputs] = useState<any>({});
  const [inputName, setInputName] = useState("otpNo1");
  const [inputError, setInputError] = useState("");
  const keyboardRef = useRef<any>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const otp1Ref = useRef<HTMLInputElement>(null);
  const otp2Ref = useRef<HTMLInputElement>(null);
  const otp3Ref = useRef<HTMLInputElement>(null);
  const otp4Ref = useRef<HTMLInputElement>(null);

  const managerValidation = useAppSelector((state) => state.auth.managerValidation)
  const dispatch = useAppDispatch();

  useEffect(() => {
    otp1Ref.current?.focus();
  }, []);

  useEffect(() => {
    let value = Object.values(inputs).join("");
    setNumberValue(value);
  }, [inputs]);

  useEffect(() => {
    if(managerValidation && managerValidation !== "MANAGER_VALIDATED") {
      setInputError(managerValidation);
      dispatch(authActions.setManagerValidation(""))
    }
  },[managerValidation])

  const onChangeKeyboard = (input: any) => {
    if (input.length <= 4) {
      let inputVal = `${input}`;
      setInputs({
        ["otpNo1"]: inputVal.charAt(0),
        ["otpNo2"]: inputVal.charAt(1),
        ["otpNo3"]: inputVal.charAt(2),
        ["otpNo4"]: inputVal.charAt(3),
      });
      setNumberValue(input);
    }
    keyboardRef.current && keyboardRef.current.setInput(input);
  };

  useImperativeHandle(ref, () => ({
    getNumberValue() {
      if (numberValue && numberValue.length === 4) {
        return numberValue;
      } else {
        setInputError(getLangLabel("validate-code-is-required"));
        return null;
      }
    },
  }));

  const onChangeInput = (event: any) => {
    const inputVal = event.target.value;

    if (inputVal.length === 1 || inputVal.length === 0) {
      setInputs({
        ...inputs,
        [inputName]: inputVal,
      });
    }
    keyboardRef.current && keyboardRef.current.setInput(inputVal);

    if (inputName === "otpNo1" && inputVal) {
      otp2Ref.current?.focus();
    } else if (inputName === "otpNo1" && inputVal.length === 0) {
      otp1Ref.current?.focus();
      return;
    }

    if (inputName === "otpNo2" && inputVal) {
      otp3Ref.current?.focus();
    } else if (inputName === "otpNo2" && inputVal.length === 0) {
      otp1Ref.current?.focus();
      return;
    }

    if (inputName === "otpNo3" && inputVal) {
      otp4Ref.current?.focus();
    } else if (inputName === "otpNo3" && inputVal.length === 0) {
      otp2Ref.current?.focus();
      return;
    }

    if (inputName === "otpNo4" && inputVal.length === 0) {
      otp3Ref.current?.focus();
      return;
    }
  };

  const getInputValue = (inputName: any) => {
    return inputs[inputName] || "";
  };

  const handleNumberValueChange = () => {};

  const handleKeyUp = (e: any) => {
    if (e.key === "Backspace") {
      if (!numberValue && !inputs["otpNo1"]) {
        otp1Ref.current?.focus();
      }

      if (numberValue.length === 1 && !inputs["otpNo2"]) {
        setInputs({
          ...inputs,
          ["otpNo1"]: "",
        });
        otp1Ref.current?.focus();
      }

      if (numberValue.length === 2 && !inputs["otpNo3"]) {
        setInputs({
          ...inputs,
          ["otpNo2"]: "",
        });
        otp2Ref.current?.focus();
      }

      if (numberValue.length === 3 && !inputs["otpNo4"]) {
        setInputs({
          ...inputs,
          ["otpNo3"]: "",
        });
        otp3Ref.current?.focus();
      }
    }
  };

  const onKeyPress = (button: string) => {
    if (button === "{backspace}") {
      if (!numberValue && !inputs["otpNo1"]) {
        otp1Ref.current?.focus();
      }

      if (numberValue.length === 1 && !inputs["otpNo2"]) {
        setInputs({
          ...inputs,
          ["otpNo1"]: "",
        });
        otp1Ref.current?.focus();
      }

      if (numberValue.length === 2 && !inputs["otpNo3"]) {
        setInputs({
          ...inputs,
          ["otpNo2"]: "",
        });
        otp2Ref.current?.focus();
      }

      if (numberValue.length === 3 && !inputs["otpNo4"]) {
        setInputs({
          ...inputs,
          ["otpNo3"]: "",
        });
        otp3Ref.current?.focus();
      }
    }
  };

  const handleModalClick = () => {
    if (!numberValue && !inputs["otpNo1"]) {
      otp1Ref.current?.focus();
    }

    if (numberValue.length === 1 && inputs["otpNo1"]) {
      otp1Ref.current?.focus();
    }

    if (numberValue.length === 1 && inputs["otpNo2"]) {
      otp2Ref.current?.focus();
    }

    if (numberValue.length === 2 && inputs["otpNo3"]) {
      otp3Ref.current?.focus();
    }

    if (numberValue.length === 3 && inputs["otpNo4"]) {
      otp4Ref.current?.focus();
    }
  };

  return (
    <div>
      <div className="subDescription">
        <LangTextLabel lngCode="otp-text" />
      </div>
      
      <div className="validationText">{inputError ? <InputError error={inputError} /> : null}</div>
      <div className="otpInputWrap">
        <input
          id="otpNumberValue"
          name="otpNumberValue"
          className={"formInput"}
          type="hidden"
          placeholder={""}
          autoComplete="off"
          value={numberValue}
          onChange={handleNumberValueChange}
          maxLength={4}
          required
        />
        <div className="otpInput">
          <input
            ref={otp1Ref}
            id="otpNo1"
            name="otpNo1"
            className={"formInput"}
            type="number"
            placeholder={""}
            autoComplete="off"
            value={getInputValue("otpNo1")}
            onChange={onChangeInput}
            onKeyUp={handleKeyUp}
            onFocus={() => {
              setInputName("otpNo1");
            }}
            maxLength={1}
            required
          />
          <div className="indicator"></div>
        </div>
        <div className="otpInput">
          <input
            ref={otp2Ref}
            id="otpNo2"
            name="otpNo2"
            className={"formInput"}
            type="number"
            placeholder={""}
            autoComplete="off"
            value={getInputValue("otpNo2")}
            onChange={onChangeInput}
            onKeyUp={handleKeyUp}
            onFocus={() => {
              setInputName("otpNo2");
            }}
            maxLength={1}
            required
          />
          <div className="indicator"></div>
        </div>
        <div className="otpInput">
          <input
            ref={otp3Ref}
            id="otpNo3"
            name="otpNo3"
            className={"formInput"}
            type="number"
            placeholder={""}
            autoComplete="off"
            value={getInputValue("otpNo3")}
            onChange={onChangeInput}
            onKeyUp={handleKeyUp}
            onFocus={() => {
              setInputName("otpNo3");
            }}
            maxLength={1}
            required
          />
          <div className="indicator"></div>
        </div>
        <div className="otpInput">
          <input
            ref={otp4Ref}
            id="otpNo4"
            name="otpNo4"
            className={"formInput"}
            type="number"
            placeholder={""}
            autoComplete="off"
            value={getInputValue("otpNo4")}
            onChange={onChangeInput}
            onKeyUp={handleKeyUp}
            onFocus={() => {
              setInputName("otpNo4");
            }}
            maxLength={1}
            required
          />
          <div className="indicator"></div>
        </div>

      </div>
      {getConfig("app.keyboard.enable") === "TRUE" ? (
        <NumberPad
          keyboardRef={keyboardRef}
          onChange={onChangeKeyboard}
          inputName="countModInput"
          currentValue={numberValue}
          layout={"otp"}
          maxLength={4}
          onKeyPress={onKeyPress}
        />
      ) : null}
    </div>
  );
});
export default OtpModal;
