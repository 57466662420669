import React from "react";
import { Redirect } from "react-router-dom";
import withAuth from "../../HOC/withAuth";
import useAuth from "../../hooks/useAuth";
import { useAppSelector } from "../../hooks/useRedux";
import AppRoutes from "../../route";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import { getToken, setPosDomain } from "../../utility";

const RouteLayout = () => {

  const { appLogout } = useAuth();

  if(!getToken()) {
    appLogout();
  }
  return <AppRoutes />
};

export default withAuth(RouteLayout);