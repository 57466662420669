import React, { Children, Component, useEffect, useRef, useState } from "react";
import "./DetailsBody.scss";


interface PastSaleDetailBodyProps {
children: any;
  }


const PastSaleDetailBody = (props:PastSaleDetailBodyProps) => {
  
    const { children } = props;
  return (
    <div className="contentBodyWrapper">
        <div className="contentBody">
            <div className="gridWrapper">
                <div className="gridHead">
                    {children}                 
                </div>
            </div>
        </div>
    </div>
    );
};

export default PastSaleDetailBody;
