import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import RouteLayout from "./components/RouteLayout";
import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import PageLoader from "./components/Common/PageLoader";
import { getConfig, setPosDomain } from "./utility";

export default function App() {
  let persistor = persistStore(store);
  const folderPath = getConfig("app.folder_path");

  const pageLoaderComp = () => {
    return <PageLoader />
  }
  
  return (
    <Provider store={store}>
      <PersistGate loading={pageLoaderComp()} persistor={persistor}>
        <BrowserRouter basename={folderPath}>
          <RouteLayout />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
