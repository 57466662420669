import React, {
  useImperativeHandle,
  useRef, useState
} from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

interface KeyboardDefaultProps {
  onChange: (input: string) => void;
  onKeyPressHandle?: (input: string) => void;
  inputName: string;
}

interface KeyboardDefaultRef {
  onChangeInput(input: any): void;
}

const KeyboardDefault = React.forwardRef<
  KeyboardDefaultRef,
  KeyboardDefaultProps
>((props, ref) => {
  const { onChange, inputName, onKeyPressHandle } = props;
  const [layoutName, setLayoutName] = useState("default");

  const keyboardRef = useRef<any>(null);

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
    onKeyPressHandle && onKeyPressHandle(button);
  };

  const onChangeValue = (input: any) => {
    keyboardRef.current.setInput(input);
    onChange(input);
  };

  useImperativeHandle(ref, () => ({
    onChangeInput(input) {
      onChangeValue(input);
    },
  }));

  return (
    <Keyboard
      keyboardRef={(r: any) => (keyboardRef.current = r)}
      layoutName={layoutName}
      onChange={onChangeValue}
      onKeyPress={(button: string) => onKeyPress(button)}
      inputName={inputName}
      physicalKeyboardHighlight={true}
      syncInstanceInputs={true}
    />
  );
});

export default KeyboardDefault;
