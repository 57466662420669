import { Button, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import cart from "../../../assets/icons/action/cart.svg";
import usePayment from "../../../hooks/usePayment";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import {
  cancelBooking,
  completeBooking,
  createBooking,
} from "../../../redux/basket/basketAction";
import {
  calculateTarifCountAndTotalPrice,
  clearBasket,
  clearTicketing,
  setBasketBookingTimerExtnded,
  setBasketEmailValue,
  setBookingCountDown,
  setBookingRenewStatus,
  setEmailEnable,
  setIsPaymentConfirmModalOpen,
  setIsPaymentSuccesModalOpen,
  setPaybleamount,
  setTicketPrintEnable,
} from "../../../redux/basket/basketSlice";
import {
  closeConfirmationBox,
  openConfirmationBox,
  updateConfirmationBoxContent,
} from "../../../redux/confirmationBox/confirmationBoxSlice";
import {
  setIsPageLoader,
  setIsPageLoaderText,
} from "../../../redux/layout/layoutSlice";
import {
  clearPayment,
  setCompleteBookingStatus,
  setPaymentSenseSignatureRequestParams,
  setPointsToRedeem,
  setPointsValueToRedeem,
} from "../../../redux/payment/paymentSlice";
import { setTicketPrintable } from "../../../redux/printTicket/printTicketSlice";
import {
  clearTickets,
  setSelectedSeats,
  setSelectedTickets,
  setTotalTicketCount,
} from "../../../redux/ticketing/ticketingSlice";
import ROUTE_CONSTANT_MAP from "../../../route/routes-url";
import { getConfig, getLangLabel } from "../../../utility";
import EmailEnterModal from "../../Modals/EmailEnterModal";
import PaymentSuccessModal from "../../Modals/PaymentSuccessModal";
import ValidatePaymentModal from "../../Modals/ValidatePaymentModal";
import ConfirmationDialog from "../ConfirmationDialog";
import CustomSwitch from "../CustomSwitch";
import LangTextLabel from "../LangTextLabel";
import ModalBox from "../ModalBox";
import PriceConverter from "../PriceConverter";
import BasketContent from "./BasketContent";
import "./BasketScreen.scss";
import { pastSalesActions } from "../../../redux/pastSales/pastSalesAction";
import {
  openAlertBox,
  updateAlertBoxContent,
} from "../../../redux/alertBox/alertBoxSlice";

interface BasketScreenProps {
  isResetBtn: boolean;
  isCloseEnable?: boolean;
  isRetractable: boolean;
  isEditable?: boolean;
  onCloseClick?: () => void;
  onBasketCleared?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButtonStyles: {
      color: "var(--color-white)",
    },
    basketFooterButtonStyle: {
      padding: 0,
      backgroundColor: "var(  --cta-color)",
      borderRadius: 0,
      color: "var( --color-white)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "var(--cta-color-hover)",
      },
    },
    basketFooterDisableButtonStyle: {
      padding: 0,
      borderRadius: 0,
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      color: "var( --color-rgba-3)",
      boxShadow: "none",
      backgroundColor: "var(  --color-rgba-2)",
      cursor: "default",
      pointerEvents: "none",
    },
    removeButtonStyles: {
      backgroundColor: "transparent",
      width: "100%",
      boxShadow: "none",
      padding: "15px 0",
      borderRadius: 5,
      color: "var( --color-18)",
      "&:hover": {
        color: "var(  --danger-color)",
      },
    },
    deleteIconStyle: {
      marginRight: 10,
    },
  })
);

const BasketScreen: React.FC<BasketScreenProps> = (
  props: BasketScreenProps
) => {
  const classes = useStyles();
  const { onCloseClick, isRetractable, isResetBtn, isCloseEnable } = props;
  const [isEnterEmailModalVisibility, setIsEnterEmailModalVisibility] =
    useState(false);
  const dispatch = useAppDispatch();
  const {
    checkPaymentMethodAndPerform,
    sendSignatureRequest,
    sendDuplicateRequest,
    sendCancelTransaction,
  } = usePayment();
  const history = useHistory();

  const {
    basketObj,
    tarifCount,
    totalPrice,
    reservationId,
    discountData,
    taxData,
    selectedPaymentMethod,
    isPaymentConfirmModalOpen,
    orderReference,
    isPaymentSuccesModalOpen,
    isEmailEnable,
    isTicketPrintEnable,
    basketEmailValue,
    bookingCountDown,
    basketBookingTimerExtnded,
    paybleAmount,
  } = useAppSelector((state) => state.basket);
  const { isTicketPrintable } = useAppSelector((state) => state.printTicket);
  const {
    paymentSenseSignatureRequestParams,
    paymentSenseResult,
    isPaymentConfirmEnabled,
    paymentCampaign,
    pointsToRedeem,
    pointsValueToRedeem,
    customerLoyaltyEmail,
  } = useAppSelector((state) => state.payment);
  const { terminal, currencySymbol } = useAppSelector((state) => state.auth);
  const { topNavProps, basketProps } = useAppSelector((state) => state.layout);

  const enterEmailModalRef = useRef<any>(null);

  useEffect(() => {
    const bookingAlertTime = getConfig("app.booking_renew_alert_time_in_sec");
    if (basketObj.ticketing.selectedShowTimes.length > 0) {
      if (basketBookingTimerExtnded && bookingCountDown < 0) {
        if (reservationId) {
          let bookingCancelData = {
            reservation_id: reservationId,
          };
          dispatch(cancelBooking(bookingCancelData));
        }
        dispatch(closeConfirmationBox());
        dispatch(setBookingCountDown(0));
        dispatch(setCompleteBookingStatus(""));
        dispatch(setSelectedSeats([]));
        dispatch(setSelectedTickets([]));
        dispatch(setTotalTicketCount(0));
        dispatch(clearBasket());
        dispatch(setBasketBookingTimerExtnded(false));
        dispatch(setTicketPrintable(false));
        dispatch(clearPayment());
        dispatch(setIsPaymentConfirmModalOpen(false))
        setTimeout(() => {
          history.push(ROUTE_CONSTANT_MAP.TICKETING_NOW);
          dispatch(
            updateAlertBoxContent({
              alertType: "info",
              message:
                "Session expired. The cart has been cleared automatically",
            })
          );
          dispatch(openAlertBox());
        }, 500);
        return;
      }

      if (!basketBookingTimerExtnded && bookingCountDown === bookingAlertTime) {
        dispatch(setBasketBookingTimerExtnded(true));
        const contentObj = {
          title: "",
          message: getLangLabel("your-time-is-running-out-renew-or-clear"),
          okayTxt: getLangLabel("renew-the-session"),
          cancelTxt: getLangLabel("start-new-booking"),
          type: "time_out_confirm_renew_or_new_bookking",
        };
        dispatch(updateConfirmationBoxContent(contentObj));
        dispatch(openConfirmationBox());
      }
    }
  }, [bookingCountDown]);

  const handleBasketContinueClick = () => {
    const selectedShowTimesArr = basketObj.ticketing.selectedShowTimes;
    let _ticketingTarifs: any[] = [];
    selectedShowTimesArr.forEach((showTimeObj) => {
      const ticketingTarifs = showTimeObj.tarifs;

      ticketingTarifs.forEach((tarifObj) => {
        _ticketingTarifs.push({
          qty: tarifObj.qty,
          tariff_id: tarifObj.id,
        });
      });
    });

    const confectioneryTarifs = basketObj.confectionery.tarifs;
    let _confectioneryTarifs = confectioneryTarifs.map((obj) => ({
      qty: obj.qty,
      product_id: obj.id,
    }));

    let bookingData: {
      reservation_id?: string | null;
      tariff: any[];
      products: any[];
      selected_seats?: any[];
    } = {
      tariff: selectedShowTimesArr,
      products: _confectioneryTarifs,
    };

    if (reservationId) {
      bookingData.reservation_id = reservationId;
    }

    dispatch(createBooking(bookingData, history));
  };

  const confirmationBoxOkayClick = (type: string) => {
    dispatch(closeConfirmationBox());
    if (type === "order_cancel") {
      if (reservationId) {
        let bookingCancelData = {
          reservation_id: reservationId,
        };
        dispatch(cancelBooking(bookingCancelData));
      }

      dispatch(setBookingCountDown(0));
      dispatch(setCompleteBookingStatus(""));
      dispatch(setBasketBookingTimerExtnded(false));
      dispatch(setSelectedSeats([]));
      dispatch(setSelectedTickets([]));
      dispatch(setTotalTicketCount(0));
      dispatch(setTicketPrintable(false));
      dispatch(clearBasket());
      dispatch(clearPayment());
      setTimeout(() => {
        history.push(ROUTE_CONSTANT_MAP.TICKETING_NOW);
        dispatch(
          updateAlertBoxContent({
            alertType: "success",
            message: "The cart has been cleared",
          })
        );
        dispatch(openAlertBox());
      }, 500);
    } else if (type === "payment_sense_signature_request") {
      dispatch(setIsPageLoader(true));
      sendSignatureRequest(paymentSenseSignatureRequestParams, true);
      dispatch(setPaymentSenseSignatureRequestParams(null));
    } else if (type === "payment_sense_time_out") {
      sendCancelTransaction(paymentSenseResult.tid);

      let bookingData: any = {
        reservation_id: reservationId,
        response: paymentSenseResult,
        amount: paybleAmount,
        payment_method_id: selectedPaymentMethod?.id,
      };

      if (basketEmailValue) {
        bookingData.email = basketEmailValue;
      }

      if (pointsToRedeem && pointsToRedeem > 0) {
        bookingData.redeemed_points = pointsToRedeem;
        bookingData.redeemed_amount = pointsValueToRedeem;
        bookingData.loyalty_email = customerLoyaltyEmail;
      }
      dispatch(completeBooking(bookingData));
    } else if (type === "confirm_clear_basket_for_another_date") {
      dispatch(clearBasket());
    } else if (type === "time_out_confirm_renew_or_new_bookking") {
      const newBookingTime = getConfig("app.booking_renew_time") * 60;
      dispatch(setBookingCountDown(newBookingTime));
      dispatch(setBookingRenewStatus("renew"));
    } else if (type === "cancel_loyalty_points") {
      const _pointsValue = pointsValueToRedeem;
      const newPayble = (Number(paybleAmount) + Number(_pointsValue)).toFixed(
        2
      );
      dispatch(setPointsToRedeem(0));
      dispatch(setPointsValueToRedeem(0.0));
      dispatch(setPaybleamount(newPayble));
    }
  };

  const confirmationBoxCancelClick = (type: string) => {
    if (type === "payment_sense_signature_request") {
      dispatch(setIsPageLoader(true));
      sendSignatureRequest(paymentSenseSignatureRequestParams, false);
      dispatch(setPaymentSenseSignatureRequestParams(null));
    } else if (type === "payment_sense_time_out") {
      sendCancelTransaction(paymentSenseResult.tid);
    } else if (type === "time_out_confirm_renew_or_new_bookking") {
      if (reservationId) {
        let bookingCancelData = {
          reservation_id: reservationId,
        };
        dispatch(cancelBooking(bookingCancelData));
      }

      dispatch(setBookingCountDown(0));
      dispatch(setCompleteBookingStatus(""));
      dispatch(setBasketBookingTimerExtnded(false));
      dispatch(setSelectedSeats([]));
      dispatch(setSelectedTickets([]));
      dispatch(setTotalTicketCount(0));
      dispatch(setTicketPrintable(false));
      dispatch(clearBasket());
      dispatch(clearPayment());
      dispatch(setIsPaymentConfirmModalOpen(false))
      setTimeout(() => {
        history.push(ROUTE_CONSTANT_MAP.TICKETING_NOW);
        dispatch(
          updateAlertBoxContent({
            alertType: "success",
            message: "The cart has been cleared.",
          })
        );
        dispatch(openAlertBox());
      }, 500);
    } else if (type === "cancel_loyalty_points") {
      dispatch(closeConfirmationBox());
    }
  };

  const confirmationBoxExtraButtonClick = (type: string) => {
    if (type === "payment_sense_time_out") {
      sendDuplicateRequest(paymentSenseResult.tid);
      const contentObj = {
        title: "",
        message: getLangLabel("terminal-timeout-message"),
        okayTxt: getLangLabel("successfull"),
        cancelTxt: getLangLabel("unsuccessfull"),
        type: "payment_sense_time_out",
      };
      dispatch(updateConfirmationBoxContent(contentObj));
    }
  };

  const beforePaymentValidation = () => {
    dispatch(setIsPaymentConfirmModalOpen(true));
  };

  /**
   * check payment method
   * cash - directly go to the complete booking
   * card - call the payment gateway webhhok and cll the complete booking
   */
  const completeOrderToPrint = async () => {
    if (terminal?.terminalId) {
      dispatch(closeConfirmationBox());
      dispatch(setIsPageLoader(true));
      dispatch(setBookingCountDown(0));
      dispatch(setBasketBookingTimerExtnded(false));
      dispatch(setIsPaymentConfirmModalOpen(false));
      checkPaymentMethodAndPerform(
        reservationId,
        selectedPaymentMethod
          ? selectedPaymentMethod
          : {
              id: "0",
              name: "",
              paymentMethodId: "0",
              icon: "",
            },
        paybleAmount,
        terminal?.terminalId,
        basketEmailValue,
        pointsToRedeem,
        pointsValueToRedeem,
        customerLoyaltyEmail
      );
    }
  };

  const onPrintTicketClose = () => {
    dispatch(setIsPaymentSuccesModalOpen(false));
    dispatch(setIsPageLoaderText(""));
    dispatch(clearBasket());
    dispatch(clearPayment());
    dispatch(clearTickets());
    dispatch(setTicketPrintable(false));
    dispatch(pastSalesActions.setPrinttDetails(null));
    history.push(ROUTE_CONSTANT_MAP.TICKETING_NOW);
  };

  const handleTicketSwitchChange = () => {
    dispatch(setTicketPrintEnable(!isTicketPrintEnable));
  };

  const handleEmailSwitchChange = () => {
    if (!isEmailEnable) {
      setIsEnterEmailModalVisibility(true);
    } else {
      dispatch(setEmailEnable(false));
      dispatch(setBasketEmailValue(null));
    }
  };

  const onEmailEnterApply = () => {
    const emailValue = enterEmailModalRef.current?.getEmailValue();

    if (emailValue) {
      setIsEnterEmailModalVisibility(false);
      dispatch(setEmailEnable(true));
      dispatch(setBasketEmailValue(emailValue));
    }
  };

  //window size
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  const size = windowDimenion.winHeight - 112;

  const handleRedeemPointsClear = () => {
    const contentObj = {
      title: "",
      message: getLangLabel(
        "are-you-sure-you-want-to-cancel-the-loyalty-points"
      ),
      okayTxt: getLangLabel("confirm"),
      cancelTxt: getLangLabel("cancel"),
      type: "cancel_loyalty_points",
    };
    dispatch(updateConfirmationBoxContent(contentObj));
    dispatch(openConfirmationBox());
  };

  return (
    <>
      <div className="basketScreen" style={{ maxHeight: size }}>
        {isCloseEnable ? (
          <div className="basketHeader">
            <div className="title">
              <LangTextLabel lngCode="pending-order" />
            </div>
            <div className="closeIconWrap">
              <IconButton
                className={classes.iconButtonStyles}
                onClick={onCloseClick}
              >
                <Close />
              </IconButton>
            </div>
          </div>
        ) : null}

        <div className="basketBody">
          <div className="basketBodyscrollable">
            {basketObj.ticketing.selectedShowTimes.length > 0 ||
            basketObj.confectionery.tarifs.length > 0 ? (
              <>
                <BasketContent
                  key={"ticketing"}
                  contObj={basketObj["ticketing"]}
                  isRetractable={isRetractable}
                />
                {topNavProps.confectionery ? (
                  <BasketContent
                    key={"confectionery"}
                    contObj={basketObj["confectionery"]}
                    isRetractable={isRetractable}
                  />
                ) : null}
              </>
            ) : (
              <div className="emptyCartMsg">
                <div className="emptyCartWrapper">
                  <img src={cart} className="cartImg" alt="cart" />
                  <LangTextLabel lngCode="empty-cart" />
                </div>
              </div>
            )}
          </div>
        </div>
 
        <div className="basketFooter">
        {basketProps.ticketSwitch || basketProps.emailSwitch ? (        
            <div className="actionCont">
              <div className="actionWrap switchActionWrap">
                {basketProps.ticketSwitch ? (
                  <CustomSwitch
                    switchVal={isTicketPrintEnable}
                    onChange={handleTicketSwitchChange}
                    labelCode={"ticket-switch"}
                  />
                ) : null}
                {basketProps.emailSwitch ? (
                  <CustomSwitch
                    switchVal={isEmailEnable}
                    onChange={handleEmailSwitchChange}
                    labelCode={"email-switch"}
                  />
                ) : null}
              </div>
            </div>         
        ) : null}
          <div className="actionCont">
            <div className="actionWrap buttonActionWrap">
              <Button
                className={classes.removeButtonStyles}
                onClick={() => {
                  const contentObj = {
                    title: "",
                    message: getLangLabel(
                      "are-you-sure-you-want-to-abort-this-command"
                    ),
                    okayTxt: getLangLabel("confirm"),
                    cancelTxt: getLangLabel("cancel"),
                    type: "order_cancel",
                  };
                  dispatch(updateConfirmationBoxContent(contentObj));
                  dispatch(openConfirmationBox());
                }}
                disabled={tarifCount === 0}
              >
                <DeleteIcon className={classes.deleteIconStyle} />{" "}
                <div className="btnTxt">
                  <LangTextLabel lngCode="clear" />
                </div>
              </Button>
            </div>
          </div>
          {taxData && parseFloat(taxData.bookingFee.toString()) > 0 ? (
            <div className="mainContentWrap">
              <div className="contentLabel">
                <LangTextLabel lngCode="booking-fee" />
              </div>
              <div className="contentValue">
                {currencySymbol}
                <PriceConverter priceToConvert={taxData.bookingFee} />
              </div>
            </div>
          ) : null}
          {taxData && parseFloat(taxData.tax.toString()) > 0 ? (
            <div className="mainContentWrap">
              <div className="contentLabel">
                <LangTextLabel lngCode="tax" />
              </div>
              <div className="contentValue">
                {currencySymbol}
                <PriceConverter priceToConvert={taxData.tax} />
              </div>
            </div>
          ) : null}
          {discountData &&
          parseFloat(discountData.discountAmount.toString()) > 0 ? (
            <div className="mainContentWrap">
              <div className="contentLabel">
                <LangTextLabel lngCode="discount" />
              </div>
              <div className="contentValue">
                {currencySymbol}
                <PriceConverter priceToConvert={discountData?.discountAmount} />
              </div>
            </div>
          ) : null}
          <div className="mainContentWrap">
            <div className="contentLabel">
              <LangTextLabel lngCode="total" />
            </div>
            <div className="contentValue">
              {currencySymbol}
              <PriceConverter priceToConvert={totalPrice} />
            </div>
          </div>
          {paymentCampaign && pointsToRedeem > 0 ? (
            <div className="mainContentWrap">
              <div className="contentLabel">
                <LangTextLabel lngCode="redeem" />{" "}
                {`(${pointsToRedeem} ${paymentCampaign.name})`}
              </div>
              <div className="contentValue">
                {currencySymbol}
                <PriceConverter priceToConvert={pointsValueToRedeem} />
              </div>

              <div className="contentRemoveWrap">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={handleRedeemPointsClear}
                >
                  <div className="contentRemove" />
                </IconButton>
              </div>
            </div>
          ) : null}
          <div className="mainBtnWrap">
            {isResetBtn ? (
              <Button
                variant="contained"
                className={classes.basketFooterButtonStyle}
                color="primary"
                disabled={!isTicketPrintable}
                onClick={beforePaymentValidation}
              >
                <div className="btnTxt payBtn">
                  <LangTextLabel lngCode="pay" />
                  <span className="paybleText">{`${currencySymbol}`}<PriceConverter priceToConvert={paybleAmount}/></span>
                </div>
              </Button>
            ) : (
              <Button
                className={
                  tarifCount === 0
                    ? classes.basketFooterDisableButtonStyle
                    : classes.basketFooterButtonStyle
                }
                onClick={handleBasketContinueClick}
                disabled={tarifCount === 0}
              >
                <div className="btnTxt">
                  <LangTextLabel lngCode="continue" />
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
      <ConfirmationDialog
        onOkayClick={(type: any) => confirmationBoxOkayClick(type)}
        onCancelClick={(type: any) => confirmationBoxCancelClick(type)}
        onExtraBtnClick={(type: any) => confirmationBoxExtraButtonClick(type)}
      />
      {
        <ModalBox
          maxWidth="xs"
          modalTitle={`Payment in ${
            selectedPaymentMethod
              ? selectedPaymentMethod?.name
              : paymentCampaign ? paymentCampaign?.name : ""
          }`}
          visibility={isPaymentConfirmModalOpen}
          onClose={() => {
            dispatch(setIsPaymentConfirmModalOpen(false));
          }}
          onConfirm={completeOrderToPrint}
          confimDisable={!isPaymentConfirmEnabled}
        >
          <ValidatePaymentModal
            total={selectedPaymentMethod ? paybleAmount : pointsValueToRedeem}
            paymentMethod={
              selectedPaymentMethod
                ? selectedPaymentMethod
                : {
                    icon: paymentCampaign ? paymentCampaign?.image : "",
                    paymentMethodId: 0,
                  }
            }
          />
        </ModalBox>
      }
      {
        <PaymentSuccessModal
          orderRef={orderReference}
          maxWidth="xs"
          modalTitle={`Payment success`}
          visibility={isPaymentSuccesModalOpen}
          onClose={() => {
            onPrintTicketClose();
          }}
          onConfirm={completeOrderToPrint}
          confirmText={"print-ticket"}
        />
      }
      {
        <ModalBox
          maxWidth="xs"
          modalTitle={getLangLabel("enter-email-address")}
          visibility={isEnterEmailModalVisibility}
          onClose={() => {
            setIsEnterEmailModalVisibility(false);
          }}
          onConfirm={onEmailEnterApply}
          confirmText={getLangLabel("validate")}
        >
          <EmailEnterModal ref={enterEmailModalRef} />
        </ModalBox>
      }
    </>
  );
};

export default BasketScreen;
