import { LoginModel } from "../redux/auth/authModel";
import { API } from "./Api";
import API_CONSTANT_MAP from "./api-url";

export default {
  async login(data: LoginModel) {
    var response = await API.post(API_CONSTANT_MAP.LOGIN, data);
    return response.data;
  },
  async counterValidate(data: LoginModel) {
    var response = await API.post(API_CONSTANT_MAP.COUNTER_VALIDATE, data);
    return response.data;
  },

  async validateCustomer(data: any) {
    var response = await API.post(API_CONSTANT_MAP.VALIDATE_CUSTOMER, data);
    return response.data;
  },

  async validateCustomerOtp(data: any) {
    var response = await API.post(API_CONSTANT_MAP.VALIDATE_CUSTOMER_OTP, data);
    return response.data;
  },
};
