import React, { ReactNode, FC, useRef, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import AlertDialog from "../Common/AlertDialog";
import NoInternet from "../Common/NoInternet";
import PageLoader from "../Common/PageLoader";
import {useIdleTimer} from "react-idle-timer";
import useAuth from "../../hooks/useAuth";
import { setCurrentDate } from "../../redux/ticketing/ticketingSlice";
import moment from "moment";

interface AuthLayoutProps {
  children: ReactNode;
}
const AuthLayout: React.FC<AuthLayoutProps> = (props: AuthLayoutProps) => {
  const isPageLoader = useAppSelector((state) => state.layout.isPageLoader);
  const idleTime = useAppSelector((state) => state.auth.idleTime);
  const [timeoutValue, setTimoutValue] = useState<number>(600000)
  const { appLogout } = useAuth();

  useEffect(() => {
    if(idleTime) {
      setTimoutValue(idleTime * 60000)
    }
  }, [idleTime])

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    appLogout()
  }

  const onActive = (event: any) => {
    // Close Modal Prompt
    // Do some active action
  }

  const idleTimer = useIdleTimer({ onIdle, onActive, timeout: timeoutValue, promptTimeout: timeoutValue })

  useEffect(() => {
    idleTimer.start()
  },[])

  return (
    <NoInternet>
      {isPageLoader ? <PageLoader /> : ""}
      <AlertDialog />
      {props.children}
    </NoInternet>
  );
};

const AuthLayoutRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps: any) => (
        <AuthLayout>
          <Component {...matchProps} />
        </AuthLayout>
      )}
    />
  );
};

export default AuthLayoutRoute;

