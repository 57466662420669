import React, { Component } from "react";
import PropTypes from "prop-types";

import "./CustomInput.scss";

interface CustomInputProps {
  name: string;
  value: string;
  onChange: (e: any) => void;
  type: string;
  label?: string;
  helperTxt?: string;
  inputMsg?: string;
  placeholder?: string;
  other?: any
}

const CustomInput = (props: CustomInputProps) => {
  const {
    name,
    value,
    onChange,
    type,
    label,
    helperTxt,
    inputMsg,
    placeholder,
    other
  } = props;
  return (
    <div className="formGroup">
      {label && label !== "" ? <div className="inputLabel">{label}</div> : ""}
      <div className="formControl req">
        <input
          onChange={onChange}
          name={name}
          type={type}
          className="formInput"
          value={value}
          placeholder={placeholder}
        />
      </div>
      {helperTxt && helperTxt !== "" ? (
        <div className="helperTxt">{helperTxt}</div>
      ) : (
        ""
      )}
      {inputMsg && inputMsg !== "" ? (
        <div className="inputMsg error success">{inputMsg}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomInput;
