import { Button, Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import LangTextLabel from "../../Common/LangTextLabel";
import { useFormik } from "formik";
import { setKeyboardVisibility } from "../../../redux/keyboard/keyboardSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import * as Yup from "yup";
import InputError from "../../Common/InputError";
import { css } from "../../../utility";
import { login } from "../../../redux/auth/authActions";
import { useHistory } from "react-router-dom";
import ROUTE_CONSTANT_MAP from "../../../route/routes-url";
import { authSlice } from "../../../redux/auth/authSlice";
import ReactDOM from "react-dom";
import KeyboardNormal from "../../Common/KeyboardLayouts/KeyboardNormal/KeyboardNormal";

export const authActions = authSlice.actions;

interface LoginFormProps {
  keyboard: any;
  setInputNameValue: (id: string) => void
  scrollToBottomLogin: () => void
}

interface LoginFormref {
  handleSetInput(inputs: any): void;
  getInputName(): void;
  moveToNextInput(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    connectButtonStyle: {
      backgroundColor: "var(--cta-color)",
      borderRadius: 5,
      color: "var(--color-white)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      padding: "12px 30px",
      marginTop: "15px",
      "&:hover": {
        backgroundColor: "var(--cta-color-hover)",
      },
    },
  })
);

const LoginForm = React.forwardRef<LoginFormref, LoginFormProps>(
  (props, ref) => {
    const { setInputNameValue, scrollToBottomLogin } = props;
    const [inputs, setInputs] = useState<any>({});
    const [inputName, setInputName] = useState("terminalCode");
    const [buttonDisable, setButtonDisable] = useState(false);

    const dispatch = useAppDispatch();
    const history = useHistory();
    const classes = useStyles();

    const formRef = useRef<HTMLFormElement>(null);
    const keyboard = useRef<any>();
    const terminalCodeRef = useRef<HTMLInputElement>(null);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const { validationError } = useAppSelector((state) => state.auth);
    const { user } = useAppSelector((state) => state.auth);

    const LoginSchema = Yup.object().shape({
      terminalCode: Yup.string().required("Filed is required"),
      username: Yup.string().required("Filed is required"),
      password: Yup.string().required("Filed is required"),
    });

    // Pass the useFormik() hook initial form values and a submit function that will
    // be called when the form is submitted
    const formik = useFormik({
      initialValues: {
        terminalCode: "",
        username: "",
        password: "",
      },
      validationSchema: LoginSchema,
      onSubmit: (values) => {
        setButtonDisable(true);
        dispatch(setKeyboardVisibility(false));
        dispatch(
          login({
            terminal_code: values.terminalCode,
            username: values.username,
            password: values.password,
            remember_me: 0,
          })
        );
      },
    });

    useEffect(() => {
      if (validationError) {
        formik.setFieldError("password", validationError);
        dispatch(authActions.setValidationError(null));
        setButtonDisable(false);
      }
      if (user) {
        history.push(ROUTE_CONSTANT_MAP.DASHBOARD);
      }
    }, [validationError, user]);

    useImperativeHandle(ref, () => ({
      handleSetInput(inputs: any) {
        formik.setFieldValue(inputName, inputs[inputName]);
        setInputs(inputs);
      },
      getInputName() {
        return inputName;
      },
      moveToNextInput() {
        if (inputName === "username") {
          passwordRef.current?.focus();
          setInputNameValue("password");
          return;
        }

        if (inputName === "terminalCode") {
          usernameRef.current?.focus();
          setInputNameValue("username");
          return;
        }
      },
    }));

    const onChangeInput = (event: any) => {
      const inputVal = event.target.value;
      setInputs({
        ...inputs,
        [inputName]: inputVal,
      });

      formik.setFieldValue(inputName, inputVal);
      keyboard.current && keyboard.current.setInput(inputVal);
    };

    const getInputValue = (inputName: any) => {
      return inputs[inputName] || "";
    };

    return (
      <form onSubmit={formik.handleSubmit} autoComplete="off" ref={formRef}>
        <div className="formGroup">
          <div className={"inputLabel"}>
            <LangTextLabel lngCode="terminalCode" />
          </div>
          <div className="formControl">
            <input
              ref={terminalCodeRef}
              id="terminalCode"
              name="terminalCode"
              className={"formInput"}
              type="text"
              placeholder={""}
              autoComplete="off"
              value={getInputValue("terminalCode")}
              onChange={onChangeInput}
              onFocus={() => {
                setInputName("terminalCode");
                dispatch(setKeyboardVisibility(true));
              }}
            />
          </div>
          <div>
            {formik.touched.terminalCode && formik.errors.terminalCode ? (
              <InputError error={formik.errors.terminalCode} />
            ) : null}
          </div>
        </div>
        <div className="formGroup">
          <div className={"inputLabel"}>
            <LangTextLabel lngCode="username" />
          </div>
          <div className="formControl">
            <input
              ref={usernameRef}
              id="username"
              name="username"
              className={"formInput"}
              type="text"
              placeholder={""}
              autoComplete="off"
              value={getInputValue("username")}
              onChange={onChangeInput}
              onFocus={() => {
                dispatch(setKeyboardVisibility(true));
                setInputName("username");
              }}
            />
          </div>
          <div>
            {formik.touched.username && formik.errors.username ? (
              <InputError error={formik.errors.username} />
            ) : null}
          </div>
        </div>
        <div className="formGroup">
          <div className={"inputLabel"}>
            <LangTextLabel lngCode="password" />
          </div>
          <div className="formControl">
            <input
              ref={passwordRef}
              id="password"
              name="password"
              className={"formInput"}
              type="password"
              placeholder={""}
              autoComplete="off"
              value={getInputValue("password")}
              onChange={onChangeInput}
              onFocus={() => {
                scrollToBottomLogin();   
                dispatch(setKeyboardVisibility(true));
                setInputName("password");
              }}
            />
          </div>
          <div>
            {formik.touched.password && formik.errors.password ? (
              <InputError error={formik.errors.password} />
            ) : null}
          </div>
        </div>
        <div className="formGroup">
          <div className="btnWrap">
            <Button
              variant="contained"
              className={classes.connectButtonStyle}
              type="submit"
              disabled = {buttonDisable}
            >
              <LangTextLabel lngCode="connect" />
            </Button>
          </div>
        </div>
      </form>
    );
  }
);
export default LoginForm;
