import React, { Component, Fragment } from "react";
import "../../../i18n/config";
import { useTranslation } from "react-i18next";

interface LangTextLabelProps {
  lngCode: string;
}
const LangTextLabel: React.FC<LangTextLabelProps> = (props: LangTextLabelProps) => {
  const { lngCode } = props;
  const { t } = useTranslation();
  return <>{t(lngCode)}</>;
};
export default LangTextLabel;
