import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarView.scss';

interface CalendarViewProps {
  onDateChange: Function,
  selectedDate: string
}
const CalendarView = (props: CalendarViewProps) => {

  const {onDateChange, selectedDate} = props;
  
  return <><Calendar className="reactCalendar" onChange={onDateChange} value={new Date(selectedDate)} minDate={new Date()}/></>;
};
export default CalendarView;
