import React, { Component } from 'react';
import MainLayout from '../Common/POSLayout/POSLayout';
import SampleTemplate from '../SampleTemplate';


export default class Management extends Component {
  render() {
    return (
      <MainLayout>
        <SampleTemplate pageName="management" />
      </MainLayout>
    );
  }
}
