import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import CurrentDate from "../../components/Common/CurrentDate";
import CurrentTime from "../../components/Common/CurrentTime";
import LangTextLabel from "../../components/Common/LangTextLabel";
import useAuth from "../../hooks/useAuth";
import usePayment from "../../hooks/usePayment";
import { useAppSelector } from "../../hooks/useRedux";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import "./Dashboard.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    listItem: {
      borderBottom: "2px solid var(--color-26)",
      padding: 0,
    },
    listItemFooter: {
      padding: 0,
    },
  })
);

const Dashboard: React.FC = () => {
  const { user, posVersion } = useAppSelector((state) => state.auth);
  const classes = useStyles();
  const history = useHistory();
  const { appLogout } = useAuth();
  const { currentPaymentSenceMessage } = useAppSelector(
    (state) => state.payment
  );
  const { terminal, dateFormat, timeFormat } = useAppSelector((state) => state.auth);
  const { manageEndOfTheDayPayment, sendEndOfDayRequest } = usePayment();

  useEffect(() => {
    if (currentPaymentSenceMessage) {
      manageEndOfTheDayPayment(currentPaymentSenceMessage);
    }
  }, [currentPaymentSenceMessage]);

  const logout = () => {
    appLogout();
  };

  const handleEodReportSend = () => {
    sendEndOfDayRequest(terminal?.terminalId ?? "");
  };

  return (
    <div className="dashboardPageContent pageContent fullHeight">
      <div className="pageMain fullHeight">
        <div className="header">
          <div className="title">
            <div className="icon" />
            <div className="text">
              <LangTextLabel lngCode="hello" />
              &nbsp;{user?.fullname}!
            </div>
          </div>
        </div>
        <div className="mainDetails">
          <div className="systemDetailsCont">
            <div className="systemDetailsWrap">
              <ul className="systemDetailsList">
                <li>
                  <div className="icon time" />
                  <div className="text">
                    <CurrentDate format={dateFormat} />{" "}
                  </div>
                  <div className="text">
                    <CurrentTime format={timeFormat} />{" "}
                  </div>
                </li>
                <li>
                  <div className="icon user" />
                  <div className="text">{user?.fullname}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pageBody">
          <div className="bodyCont fullHeight">
            <div className="bodywrap fullHeight">
              <div className="bodyGraphic fullHeight" />
            </div>
          </div>
        </div>
        <div className="pageFooter">
          <div className="footerCont">
            <div className="footerWrap">
              <ul className="footerList">
                <li>
                  <ListItem button className={classes.listItemFooter}>
                    <Link className="list" to="#" onClick={handleEodReportSend}>
                      <div className="icon report" />
                      <div className="text">
                        <LangTextLabel lngCode="eod-report" />
                      </div>
                    </Link>
                  </ListItem>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sideNav fullHeight">
        <div className="navHeader">
          <div className="logoCont">
            <div className="logoWrap">
              <img className="App-logo img-fluid" alt="logo" />
            </div>
          </div>
        </div>
        <div className="navListCont">
          <div className="navListWrap">
            <List component="nav" className={classes.root}>
              <ListItem button className={classes.listItem}>
                <Link to={ROUTE_CONSTANT_MAP.TICKETING_NOW} className="link">
                  <div className="icon cashier" />
                  <div className="text">
                    <LangTextLabel lngCode="cashier" />
                  </div>
                </Link>
              </ListItem>
              <ListItem button className={classes.listItem}>
                <Link
                  onClick={() => {
                    logout();
                  }}
                  to={"#"}
                  className="link"
                >
                  <div className="icon logOut" />
                  <div className="text">
                    <LangTextLabel lngCode="logout" />
                  </div>
                </Link>
              </ListItem>
            </List>
          </div>
        </div>
        <div className="navFooter">
          <div className="versionDetails">
            <div className="icon version" />
            <div className="text">{posVersion}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
