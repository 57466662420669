import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BasketLayoutState, HeaderState, LayoutState, TopNavState } from "./layoutModel";

// Define a type for the slice state

// Define the initial state using that type
const initialState: LayoutState = {
  isPageLoader: false,
  pageLoaderText: "",
  topNav: true,
  topNavProps: {
    backIcon: true,
    ticketing: true,
    confectionery: true,
    pastSales: true,
    management: false,
    logOut: true,
  },
  header: true,
  headerProps: {
    time: true,
    date: true,
    datePicker: false,
    userName: true,
    userId: true,
    searchBtn: true,
    cartBtn: true,
    consessionMenu: false,
    issueConsessionBtn: false,
    qrSearchBtn: false
  },
  basketProps: {
    ticketSwitch: false,
    emailSwitch: false
  }
};

export const layoutSlice = createSlice({
  name: "layout",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initLayout: (state) => {
      state.topNav = true;
      state.topNavProps = {
        backIcon: false,
        ticketing: true,
        confectionery: state.topNavProps.confectionery,
        pastSales: true,
        management: false,
        logOut: true,
      };
      state.header = true;
      state.headerProps = {
        time: true,
        date: true,
        datePicker: false,
        userName: true,
        userId: true,
        searchBtn: false,
        cartBtn: true,
        consessionMenu: false,
        issueConsessionBtn: false,
        qrSearchBtn: false,
      };
      state.basketProps = {
        ticketSwitch: false,
        emailSwitch: false
      }
    },
    setTopNav: (state, { payload }: PayloadAction<boolean>) => {
      state.topNav = payload;
    },
    setTopNavProps: (state, { payload }: PayloadAction<TopNavState>) => {
      state.topNavProps = payload;
    },
    setTopNavConfectionery: (state, { payload }: PayloadAction<boolean>) => {
      state.topNavProps.confectionery = payload;
    },
    setHeader: (state, { payload }: PayloadAction<boolean>) => {
      state.header = payload;
    },
    setHeaderProps: (state, { payload }: PayloadAction<HeaderState>) => {
      state.headerProps = payload;
    },
    setHeaderDatePicker: (state, { payload }: PayloadAction<boolean>) => {
      state.headerProps.datePicker = payload;
    },
    setIsPageLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.isPageLoader = payload;
    },
    setIsPageLoaderText: (state, { payload }: PayloadAction<string>) => {
      state.pageLoaderText = payload;
    },
    setBasketProps: (state, { payload }: PayloadAction<BasketLayoutState>) => {
      state.basketProps = payload;
    },
    setIssueConcessionBtn: (state, { payload }: PayloadAction<boolean>) => {
      state.headerProps.issueConsessionBtn = payload;
    }
  },
});

export const {
  initLayout,
  setTopNav,
  setTopNavProps,
  setHeader,
  setHeaderProps,
  setIsPageLoader,
  setIsPageLoaderText,
  setTopNavConfectionery,
  setHeaderDatePicker,
  setBasketProps,
  setIssueConcessionBtn
} = layoutSlice.actions;

export default layoutSlice.reducer;
