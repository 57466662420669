import axios, { AxiosRequestConfig } from "axios";
import { getConfig, getToken } from "../utility";

const protocol = window.location.protocol;
const apiUrl = window.location.hostname.replace("pos.", "")

export const API = axios.create({
  baseURL: getConfig("app.is_dynamic_api_url") ? `${protocol}//${apiUrl}/${getConfig("app.api_base")}` : getConfig("api_url"),
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Accept-Language": "en",
    "Content-Language": "en"
  },
});


API.interceptors.request.use(
  function (config: AxiosRequestConfig) {

    let configObj: any = config;
    configObj.headers.common["token"] = getToken() ?? "";
    // Get token from local Storage.

    // Set `Bearer token` to the auth it is avalible in local storage and otherwise empty string.
    return configObj;
  },
  function (error: any) {
    console.log("Error on request");
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: {
    response: { status: number; status_code: number };
    request: any;
    message: any;
  }) {
    return Promise.reject(error.message);
  }
);
