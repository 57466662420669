import React, { useImperativeHandle, useRef, useState } from "react";
import CustomInput from "../../Common/CustomInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../../Common/InputError";
import './OTPEnterModal.scss';
import { getConfig } from "../../../utility";
import NumberPad from "../../Common/KeyboardLayouts/NumberPad/NumberPad";
import LangTextLabel from "../../Common/LangTextLabel";

interface OTPEnterModalRef {
  getOtpValue(): void;
}

interface OTPEnterModalProps {
  descriptionText: string
}
const OTPEnterModal = React.forwardRef<OTPEnterModalRef, OTPEnterModalProps>(
  (props, ref) => {

    const { descriptionText } = props;
     const otpSchema = Yup.object().shape({
      otp: Yup.number().required("Filed is required"),
    });

    const keyboardRef = useRef<any>(null);

    const formik = useFormik({
      initialValues: {
        otp: "",
      },
      validationSchema: otpSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values));
      },
    });

    const handleInputChange = (event: any) => {
      formik.setFieldValue("otp", event.target.value);
    };

    useImperativeHandle(ref, () => ({
      getOtpValue() {

        let isError = false;
        if (!formik.values.otp) {
          formik.setFieldError("otp", "Field is required");
          isError = true;
        }

        if(!isError) {
          return formik.values.otp
        }
        
      },
    }));

    const onChangeKeyboard = (input: any) => {
      if (input.length <= 5) {
        formik.setFieldValue("otp", input);
      }
      keyboardRef.current && keyboardRef.current.setInput(input);
    };

    return (
      <>
        <div className="subDescription">
          {descriptionText}
        </div>
        <div className="formWrap otpForm">
          <CustomInput
            name="otp"
            onChange={handleInputChange}
            type="text"
            value={formik.values.otp}
          />
        </div>
        <div>
          {formik.errors.otp ? (
            <InputError error={formik.errors.otp} />
          ) : null}
        </div>
        {getConfig("app.keyboard.enable") === "TRUE" ? (
        <NumberPad
          keyboardRef={keyboardRef}
          onChange={onChangeKeyboard}
          inputName="countModInput"
          currentValue={formik.values.otp}
          layout={"otp"}
          maxLength={5}
        />
      ) : null}
      </>
    );
  }
);
export default OTPEnterModal;
