import {
  PrintAPIModel,
  RefundAPIModel,
  SalesModel,
  SingleSaleAPIModel,
} from "../redux/pastSales/pastSalesModel";
import { API } from "./Api";
import API_CONSTANT_MAP from "./api-url";

export default {
  async getPastSales(data: SalesModel) {
    var response = await API.post(API_CONSTANT_MAP.GET_PAST_SALES, data);
    return response.data;
  },

  async getSinglePastSale(data: SingleSaleAPIModel) {
    var response = await API.post(API_CONSTANT_MAP.GET_SINGLE_PAST_SALE, data);
    return response.data;
  },

  async getPrintDetails(data: PrintAPIModel) {
    var response = await API.post(API_CONSTANT_MAP.GET_PRINT_DETAILS, data);
    return response.data;
  },

  async createRefund(data: RefundAPIModel) {
    var response = await API.post(API_CONSTANT_MAP.REFUND, data);
    return response.data;
  },

  async checkQrValid(data: any) {
    var response = await API.post(API_CONSTANT_MAP.GET_SINGLE_PAST_SALE, data);
    return response.data;
  },
};
