import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { authSlice } from "../auth/authSlice";

import keyboardReducer from "../keyboard/keyboardSlice";
import basketReducer from "../basket/basketSlice";
import layoutReducer from "../layout/layoutSlice";
import alertBoxReducer from "../alertBox/alertBoxSlice";
import confirmationBoxReducer from "../confirmationBox/confirmationBoxSlice";
import printTicketReducer from "../printTicket/printTicketSlice";
import ticketingReducer from "../ticketing/ticketingSlice";
import concessionsReducer from "../concessions/concessionsSlice";
import pastsalesReducer from "../pastSales/pastSalesSlice";
import paymentReducer from "../payment/paymentSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  auth: authSlice.reducer,
  layout: layoutReducer,
  keyboard: keyboardReducer,
  basket: basketReducer,
  alertBox: alertBoxReducer,
  confirmationBox: confirmationBoxReducer,
  printTicket: printTicketReducer,
  ticketing: ticketingReducer,
  concessions: concessionsReducer,
  passales: pastsalesReducer,
  payment: paymentReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
