import { createTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: "#667db5" },
  secondary: { main: '#F0F2F7' },
  error: { main: '#d32f2f'},
  warning: { main: '#ed6c02'}
};

// A custom theme for this app
const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Source Sans Pro',
    h5: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 500,
      lineHeight: 1,
    },
    h6: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 300,
      lineHeight: 1,
    },

  },
  overrides: {
    MuiButton: {
      text: {
        color: 'white', // Some CSS
      },
    },
  },
});

export default theme;
