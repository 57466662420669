import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import "./MainHeader.scss";

import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import LangTextLabel from "../../LangTextLabel";
import CurrentTime from "../../CurrentTime";
import CurrentDate from "../../CurrentDate";
import BasketScreen from "../../BasketScreen";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useRedux";
import {
  closeFixedSideBasket,
  openFixedSideBasket,
} from "../../../../redux/basket/basketSlice";
import ModalBox from "../../ModalBox";
import { css, getLangLabel } from "../../../../utility";
import CalendarModal from "../../../Modals/CalendarModal";
import { setCurrentDate } from "../../../../redux/ticketing/ticketingSlice";
import { getPastSales, getSinglePastSaleByQr, pastSalesActions } from "../../../../redux/pastSales/pastSalesAction";
import SearchTypeModal from "../../../Modals/SearchTypeModal";
import {
  setKeyboardInputName,
  setKeyboardVisibility,
} from "../../../../redux/keyboard/keyboardSlice";
import { concessionsActions } from "../../../../redux/concessions/concessionsAction";
import BarcodeScanner from "../../../Modals/BarcodeScanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: 0,
    },
    overlayButtonStyle: {
      padding: 0,
      display: "block",
      background: "transparent",
      height: "100%",
      width: "100%",
    },
  })
);

const MainHeader: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { headerProps } = useAppSelector((state) => state.layout);
  const { tarifCount, isFixedSideBasketOpen } = useAppSelector(
    (state) => state.basket
  );
  const { user } = useAppSelector((state) => state.auth);
  const { terminal, dateFormat, timeFormat, dateFormatPlaceholder, concessionEnabled } =
    useAppSelector((state) => state.auth);
  const { currentDate, selectedSeatsList } = useAppSelector(
    (state) => state.ticketing
  );
  const { keyboardInputName, keyboardInputValue } = useAppSelector(
    (state) => state.keyboard
  );

  const { isssueConsessionBtnEnabled, barcodeScannerError } = useAppSelector(
    (state) => state.concessions
  );
  const [dateModalVisibility, setDateModalVisibilty] = useState(false);
  const [qrScanModalVisibility, setQrScanModalVisibilty] = useState(false);

  const [searchType, setSearchType] = useState("ref_no");
  const [perPage, setPerPage] = useState(10);
  const [isChangeWork, setIsChangeWork] = useState(false);
  const [searchTypeModalVisible, setSearchTypeModalVisible] = useState(false);

  const [searchText, setSearchText] = useState("");

  const calendarRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (keyboardInputName && keyboardInputName === "pastSaleSearch") {
      setSearchText(keyboardInputValue);
    }
  }, [keyboardInputValue]);

  const handleCurrentDateClick = () => {
    setDateModalVisibilty(!dateModalVisibility);
  };

  const onCalendarApply = () => {
    dispatch(setCurrentDate(calendarRef.current.getDateValue()));
    setDateModalVisibilty(false);
  };

  useEffect(() => {
    setIsChangeWork(true);
    if (isChangeWork) {
      const timeOutId = setTimeout(() => keyUpChange(), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [searchText]);

  const handlePageChange = (page: React.SetStateAction<any>) => {
    dispatch(
      getPastSales({
        type: searchType,
        searchTerm: searchText,
        rowsPerPage: perPage,
        currentPage: page,
      })
    );
  };

  const keyUpChange = () => {
    if (searchText.length > 2 || searchText.length === 0) {
      handlePageChange(1);
    }
  };

  const onSelectType = () => {
    setSearchText("");
    setSearchType(inputRef.current.getSelectedValue());
    setSearchTypeModalVisible(false);
  };

  const handleSearchModal = () => {
    setSearchTypeModalVisible(true);
  };

  const handleConsessionMenu = () => {
    dispatch(concessionsActions.toggleConseionMenuVisibility(true));
  };

  const handleConsessionIssuing = () => {
    dispatch(concessionsActions.toggleConsessionIssueModalVisibility(true));
  };

  return (
    <>
      <div className="mainHeader">
        <div className="mainHeaderWrap">
          <div className="mainDetails">
            <div className="systemDetailsCont">
              <div className="systemDetailsWrap">
                <ul className="systemDetailsList">
                  {headerProps.time ? (
                    <li>
                      <div className="icon time" />
                      <div className="text">
                        <CurrentDate format={dateFormat} />
                      </div>
                      <div className="text">
                        <CurrentTime format={timeFormat} />
                      </div>
                    </li>
                  ) : null}
                  <li>
                    <div className="icon user" />
                    <div className="text">{user?.fullname}</div>
                  </li>
                  <li>
                    <div className="icon pos" />
                    <div className="text">{terminal?.name}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mainActions">
            <div className="mainActionsWrap">
              <ul className="mainActionList">
                {headerProps.datePicker ? (
                  <li>
                    <ListItem button className={classes.listItem}>
                      <div
                        className="actionBtn"
                        onClick={() => handleCurrentDateClick()}
                      >
                        <div className="dateWrapper">
                          <div className="icon date" />
                          <div>
                            <CurrentDate
                              curDate={currentDate}
                              format={dateFormat}
                            />
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  </li>
                ) : null}
                {headerProps.consessionMenu ? (
                  <li>
                    <ListItem button className={classes.listItem}>
                      <div
                        className="actionBtn"
                        onClick={() => handleConsessionMenu()}
                      >
                        <div className="iconWrapper">
                          <div className="icon confectionery-menu" />
                          <div>
                            <LangTextLabel lngCode="menu" />
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  </li>
                ) : null}
                {headerProps.issueConsessionBtn && concessionEnabled ? (
                  <li>
                    <ListItem
                      button
                      className={classes.listItem}
                      disabled={!isssueConsessionBtnEnabled}
                    >
                      <div
                        className={css(
                          "actionBtn",
                          isssueConsessionBtnEnabled ? "" : "disable"
                        )}
                        onClick={() => handleConsessionIssuing()}
                      >
                        <div className="iconWrapper">
                          <div className="icon confectionery-card" />
                          <div>
                            <LangTextLabel lngCode="issuing-consessions" />
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  </li>
                ) : null}
                {headerProps.qrSearchBtn ? (
                  <li>
                    <ListItem button className={classes.listItem}>
                      <div
                        className={css("actionBtn")}
                        onClick={() =>  dispatch(pastSalesActions.setReceiptScanModalVisibility(true))}
                      >
                        <div className="iconWrapper">
                          <div className="icon confectionery-card-qr" />
                          <div>
                            <LangTextLabel lngCode="receipt-scan" />
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  </li>
                ) : null}
                {headerProps.cartBtn ? (
                  <li className="listIconOnly">
                    <ListItem
                      button
                      className={classes.listItem}
                      onClick={() => {
                        dispatch(openFixedSideBasket());
                      }}
                      disabled={tarifCount === 0}
                    >
                      <div className="actionBtn">
                        <div className="icon cart">
                          {tarifCount !== 0 ? (
                            <span className="tarifCount">{tarifCount}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </ListItem>
                  </li>
                ) : null}

                {/* //// */}
                {headerProps.searchBtn ? (
                  <>
                    <div className="listItemWrapper seperator">
                      {/* <div className="text">
                        <LangTextLabel lngCode="search-by" />
                      </div> */}
                      <li>
                        <ListItem button className={classes.listItem}>
                          <div
                            className="actionBtn"
                            onClick={() => handleSearchModal()}
                          >
                            <div>
                              <div>
                                <LangTextLabel lngCode="search-by" />
                                <LangTextLabel
                                  lngCode={
                                    searchType === "ref_no"
                                      ? getLangLabel("ref-number")
                                      : searchType === "name"
                                      ? getLangLabel("name")
                                      : searchType === "created_date"
                                      ? getLangLabel("purchased-date")
                                      : getLangLabel("email")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </ListItem>
                      </li>
                    </div>
                    {/**
                     * ////
                     */}
                    <div className="listItemWrapper">
                      {/* <div className="text">
                        <LangTextLabel lngCode="search-text" />
                      </div> */}
                      <li>
                        <div className="searchSales">
                          <div className="formControl ">
                            <input
                              type="text"
                              name="pastSaleSearch"
                              placeholder={
                                searchType === "created_date"
                                  ? dateFormatPlaceholder
                                  : getLangLabel("search-type")
                              }
                              value={searchText}
                              onChange={(event) =>
                                setSearchText(event.target.value)
                              }
                              onFocus={() => {
                                dispatch(
                                  setKeyboardInputName("pastSaleSearch")
                                );
                                dispatch(setKeyboardVisibility(true));
                              }}
                              className="formInput no-border"
                            />
                          </div>
                        </div>
                      </li>
                    </div>
                  </>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {headerProps.cartBtn && tarifCount !== 0 ? (
        <div
          className={`fixedSideBasketCont ${
            isFixedSideBasketOpen ? "active" : ""
          }`}
        >
          <div className="fixedSideBascketOverlay">
            <Button
              className={classes.overlayButtonStyle}
              onClick={() => {
                dispatch(closeFixedSideBasket());
              }}
            >
              <div className="overlay" />
            </Button>
          </div>
          <div className="fixedSideBasketWrap">
            <BasketScreen
              isCloseEnable={true}
              isResetBtn={false}
              isRetractable={true}
              onCloseClick={() => {
                dispatch(closeFixedSideBasket());
              }}
            />
          </div>
        </div>
      ) : null}
      {
        <ModalBox
          maxWidth="sm"
          visibility={dateModalVisibility}
          modalTitle={getLangLabel("change-date")}
          onClose={() => setDateModalVisibilty(false)}
          onConfirm={onCalendarApply}
        >
          <CalendarModal ref={calendarRef} selectedDate={currentDate} />
        </ModalBox>
      }
      {
        <ModalBox
          maxWidth="xs"
          visibility={searchTypeModalVisible}
          modalTitle={getLangLabel("search-by")}
          onClose={() => setSearchTypeModalVisible(false)}
          onConfirm={onSelectType}
        >
          <SearchTypeModal ref={inputRef} searchType={searchType} />
        </ModalBox>
      }
    </>
  );
};

export default MainHeader;
