import React from "react";

import LangTextLabel from "../../LangTextLabel";
import BasketContentBox from "./BasketContentBox";
import BasketTimer from "./BasketTimer";

interface BasketContentProps {
  contObj: any;
  isRetractable?: boolean;
  isEditable?: boolean;
}

const BasketContent = (props: BasketContentProps) => {
  const { contObj, isRetractable } = props;
 
  return (
    <div className="basketContent">
      {contObj.label === "ticketing" && contObj.selectedShowTimes.length > 0 ? (
        <div className="contentHeader">
          <div className="headerTxt">
            <LangTextLabel
              lngCode={contObj && contObj.title ? contObj.title : ""}
            />
            <BasketTimer />
          </div>
        </div>
      ) : null}
      {contObj.label === "confectionery" && contObj.tarifs && contObj.tarifs.length > 0 ? (
        <div className="contentHeader">
          <div className="headerTxt">
            <LangTextLabel
              lngCode={contObj && contObj.title ? contObj.title : ""}
            />
          </div>
        </div>
      ) : null}
      {contObj.label === "ticketing" ? (
        <div className="contentBody">
          {contObj && contObj.selectedShowTimes.length > 0 ? (
            <BasketContentBox
              key={"ticketing_box"}
              rateObj={contObj}
              basketType={contObj.label}
              isRetractable={isRetractable}
            />
          ) : null}
        </div>
      ) : null}
      {contObj.label === "confectionery" ? (
        <div className="contentBody">
          {contObj && contObj.tarifs && contObj.tarifs.length > 0 ? (
            <BasketContentBox
              key={"confectionery_box"}
              rateObj={contObj}
              basketType={contObj.label}
              isRetractable={isRetractable}
            />
          ) : // <div className="emptyCartMsg">
          //   <LangTextLabel lngCode="empty-cart" />
          // </div>
          null}
        </div>
      ) : null}
    </div>
  );
};

export default BasketContent;
