import React, { useImperativeHandle, useState } from "react";
// import moment from 'moment';
import moment from "moment-timezone";
import CalendarView from "../../Common/CalendarView";
import NumberPad from "../../Common/KeyboardLayouts/NumberPad/NumberPad";
import CustomInput from "../../Common/CustomInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../../Common/InputError";
import './EmailEnterModal.scss';

interface EmailEnterModalRef {
  getEmailValue(): void;
}
const EmailEnterModal = React.forwardRef<EmailEnterModalRef, any>(
  (props, ref) => {
    const emailSchema = Yup.object().shape({
      email: Yup.string().email("Email must be a valid format").required("Filed is required"),
    });

    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: emailSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values));
      },
    });

    const handleInputChange = (event: any) => {
      formik.setFieldValue("email", event.target.value);
    };

    useImperativeHandle(ref, () => ({
      getEmailValue() {

        let isError = false;
        formik.setTouched({email: false})
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!formik.values.email) {
          formik.setFieldError("email", "Field is required");
          formik.setTouched({email: true})
          isError = true;
        } else if (!regex.test(formik.values.email)) {
          formik.setFieldError("email", "Email must be a valid format");
          formik.setTouched({email: true})
          isError = true
        }

        if(!isError) {
          return formik.values.email
        }
        
      },
    }));

    return (
      <>
        <div className="formWrap emailForm">
          <CustomInput
            name="email"
            onChange={handleInputChange}
            type="text"
            value={formik.values.email}
          />
        </div>
        <div>
          {formik.touched.email && formik.errors.email ? (
            <InputError error={formik.errors.email} />
          ) : null}
        </div>
      </>
    );
  }
);
export default EmailEnterModal;
