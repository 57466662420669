import React, { Component, useEffect, useState } from "react";
import "../ScreenCards.scss";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";
import ROUTE_CONSTANT_MAP from "../../../../route/routes-url";
import { TicketItem } from "../../../../redux/ticketing/ticketingModel";
import { css } from "../../../../utility";

interface CardNowProps {
  cardObj: TicketItem;
  currentDate: string;
}
const CardNow: React.FC<CardNowProps> = (props: CardNowProps) => {
  const { cardObj, currentDate } = props;
  const [isDisable, setIsDisable] = useState(false)
  const updatedCardObj = { ...cardObj, currentDate };
  const styles = {
    buttonBaseStyles: {
      padding: 0,
      minHeight: 50,
      width: "100%",
    },
  };

  const CardNowLink = React.forwardRef((props, ref) => (
    <Link
      to={{
        pathname: ROUTE_CONSTANT_MAP.TARIFS,
        state: { showTimeObj: updatedCardObj },
      }}
      {...props}
    />
  ));

  useEffect(() => {

    if(cardObj.availableCount <= 0) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }

  },[cardObj])


  return (
    <div className={css(`cardBtn cardNow`, isDisable ? 'gray' : '')}>
      <ButtonBase style={styles.buttonBaseStyles} component={CardNowLink} disabled={isDisable}>
        <div className="btnContent">
          <div className="btnWrap">
            <div className="detailWrap">
              <div className="headerDetails">
                <div className="flagTxtWrap">
                  <div className="flagTxt">{cardObj.roomName}</div>
                </div>
                <div className="cardTitleWrap">
                  <div className="cardTitle">{cardObj.title}</div>
                </div>
              </div>
              <div className="cardDetails">
                <ul className="detailList">
                  <li>
                    <div className="mainTxt">{cardObj.startTime}</div>
                    <div className="subTxt">
                      ({cardObj.sessionStartTime}-{cardObj.sessionEndTime})
                    </div>
                  </li>
                  {cardObj.bbfcLogo ? (
                    <li>
                      <div className="mainTxt">
                        <img src={cardObj.bbfcLogo} className="bbfcLogo"/>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="amountWrap">
              <div className="amountTxt">
                {`${cardObj.availableCount} / ${cardObj.ticketCount}`}
              </div>
            </div>
          </div>
        </div>
      </ButtonBase>
    </div>
  );
};

export default CardNow;
