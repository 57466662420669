import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getLangLabel } from '../../../utility';

interface CustomSwitchProps {
  switchVal: any,
  onChange: () => void,
  labelCode: string
}

const CustomSwitch: React.FC<CustomSwitchProps> = (props: CustomSwitchProps) => {
  const {
    switchVal, onChange, labelCode,
  } = props;


  const styles = {
    iconStyle: {
      backgroundColor: 'var(  --color-16)',
    },
    iconActiveStyle: {
      backgroundColor: 'var( --color-17)',
    },
    barStyle: {
      opacity: '1 !important',
      backgroundColor: 'var( --color-18) !important',
    },
    barActiveStyle: {
      opacity: '1 !important',
      backgroundColor: 'var(  --color-23) !important',
    },
    rootStyle: {
      height: 20,

    },
    lblclass: {
      color: 'var(  --color-3)',
      fontWeight: '600',
    },
  };

  return (
    <FormGroup row>
      <FormControlLabel
        label={getLangLabel(labelCode)}
        style={styles.lblclass}
        control={(
          <Switch
            checked={switchVal}
            onChange={onChange}
            value={switchVal}
            color="primary"
          />
        )}
      />
    </FormGroup>
  );
}

export default CustomSwitch;
