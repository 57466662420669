import React, { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useRedux";
import {
  openAlertBox,
  updateAlertBoxContent,
} from "../../../../../redux/alertBox/alertBoxSlice";
import {
  calculateTarifCountAndTotalPrice,
  closeFixedSideBasket,
  closeTarifCountChangeModal,
  openTarifCountChangeModal,
  removeRateConfectionery,
  removeRateFromTicketing,
  updateTarifQunatityOnConfectionery,
  updateTarifQunatityOnTicketing,
  setBasketSelectedShowtime,
  setBasketSelectedTarif,
  setBasketSelectedType,
  removePaymentSummary,
} from "../../../../../redux/basket/basketSlice";
import { concessionsActions } from "../../../../../redux/concessions/concessionsAction";
import { ProductModel } from "../../../../../redux/concessions/concessionsModel";
import {
  setIsSeatPlanNeedToLoad,
  setSelectedTickets,
  setTotalTicketCount,
} from "../../../../../redux/ticketing/ticketingSlice";
import { getLangLabel } from "../../../../../utility";
import NumberPadModal from "../../../../Modals/NumberPadModal";
import ModalBox from "../../../ModalBox";
import PriceConverter from "../../../PriceConverter";
import ContentMain from "./BasketContentItems/ContentMain";
import ContentTitle from "./BasketContentItems/ContentTitle";

interface BasketContentBoxProps {
  rateObj: any;
  basketType: any;
  isRetractable?: boolean;
  isEditable?: boolean;
  tarrifs?: any[];
}
const BasketContentBox = (props: BasketContentBoxProps) => {
  const { rateObj, basketType, isEditable, isRetractable } = props;
  const {
    basketObj,
    isTarifCountChangeModalOpen,
    basketSelectedShowtime,
    basketSelectedTarif,
    basketSelectedType,
    discountData,
  } = useAppSelector((state) => state.basket);
  const { totalTicketCount, selectedTicketList, cardItems } = useAppSelector(
    (state) => state.ticketing
  );
  const { categoryDetails } = useAppSelector(
    (state) => state.concessions
  );

  const dispatch = useAppDispatch();

  const tarifCountModalRef = useRef<any>(null);

  const onCountClick = (data: any, type: string, showTimeId?: string) => {
    dispatch(setBasketSelectedType(type));
    dispatch(setBasketSelectedTarif(data.id));
    dispatch(setBasketSelectedShowtime(showTimeId ?? null));
    dispatch(openTarifCountChangeModal());
  };

  const onRemoveClick = (id: any, showTimeId?: string) => {
    if (rateObj.label === "ticketing") {
      const showTimeObjIndex: any =
        basketObj.ticketing.selectedShowTimes.findIndex((obj) => {
          return obj.id === showTimeId;
        });
      const selectedShowtimeObj =
        basketObj.ticketing.selectedShowTimes[showTimeObjIndex];
      const selectedTarifObj: any = selectedShowtimeObj.tarifs.find((obj) => {
        return obj.id === id;
      });

      /**
       * update total ticket count
       */
      dispatch(
        setTotalTicketCount(
          totalTicketCount - Number(`${selectedTarifObj.qty}`)
        )
      );

      /**
       * updated the ticket type count
       */
      const _selectedTicketList = selectedTicketList.filter(
        (obj) => obj.id !== id
      );
      dispatch(setSelectedTickets(_selectedTicketList));

      const selectedShowtimeFromCardItems = cardItems.find((obj) => {
        return obj.id === showTimeId;
      });

      if (selectedShowtimeFromCardItems?.hasSeatPlan) {
        const currentSeats = selectedShowtimeObj.seats.slice(
          0,
          selectedShowtimeObj.seats.length - Number(`${selectedTarifObj.qty}`)
        );

        dispatch(
          removeRateFromTicketing({
            tarifId: id,
            showTimeId: showTimeId,
            seats: currentSeats,
          })
        );

        dispatch(
          setIsSeatPlanNeedToLoad({
            showTimeId: showTimeId,
            seats: currentSeats,
          })
        );
      } else {
        dispatch(
          removeRateFromTicketing({ tarifId: id, showTimeId: showTimeId })
        );
      }

      dispatch(removePaymentSummary())
      dispatch(calculateTarifCountAndTotalPrice());
    } else if (rateObj.label === "confectionery") {
      dispatch(removeRateConfectionery(id));

      dispatch(removePaymentSummary())
      dispatch(calculateTarifCountAndTotalPrice());
    }
    dispatch(closeFixedSideBasket());
  };

  const onTarifCountChangeApply = () => {
    const newQty = tarifCountModalRef.current?.getNumberValue();

    if (!newQty) return;

    if (basketSelectedType === "ticketing") {
      const _showTimeObj = basketObj.ticketing.selectedShowTimes.find((obj) => {
        return obj.id === basketSelectedShowtime;
      });
      const tarifObj = _showTimeObj?.tarifs.find((tarif) => {
        return tarif.id === basketSelectedTarif;
      });

      const qty = tarifObj?.qty ? tarifObj.qty : 0;
      const _avaialableCount = Number(`${_showTimeObj?.availableCount}`);
      let calculateAvaialable =
        Number(`${_avaialableCount}`) + Number(`${qty}`) - Number(`${newQty}`);

      if (calculateAvaialable < 0) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: getLangLabel("not-avaialable-count-error-message"),
          })
        );
        dispatch(openAlertBox());
        return;
      }

      dispatch(
        updateTarifQunatityOnTicketing({
          id: basketSelectedTarif ?? "",
          qty: newQty,
          showTimeId: basketSelectedShowtime ?? "",
        })
      );
      dispatch(removePaymentSummary())
      dispatch(calculateTarifCountAndTotalPrice());
      dispatch(closeTarifCountChangeModal());
      dispatch(setBasketSelectedType(null));
      dispatch(setBasketSelectedShowtime(null));
      dispatch(setBasketSelectedTarif(null));
    } else if (basketSelectedType === "confectionery") {
      const _productObj = basketObj.confectionery.tarifs.find((product) => {
        return product.id === basketSelectedTarif;
      });
      const qty = _productObj?.qty ? _productObj.qty : 0;
      const _avaialableCount = Number(`${_productObj?.availableCount}`);

      let calculateAvaialable =
        Number(`${_avaialableCount}`) + Number(`${qty}`) - Number(newQty);

      if (calculateAvaialable < 0) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: getLangLabel(
              "not-avaialable-consesion-count-error-message"
            ),
          })
        );
        dispatch(openAlertBox());
        return;
      }

      dispatch(
        updateTarifQunatityOnConfectionery({
          id: basketSelectedTarif ?? "",
          qty: newQty,
          availableCount: calculateAvaialable,
        })
      );

      let productsArray: ProductModel[] = [];
      categoryDetails.forEach((categoryObj) => {
        if (categoryObj) {
          productsArray.push(...categoryObj.product);
        }
      });

      const prodObj = productsArray.find((obj) => {
        return obj.id === basketSelectedTarif;
      });

      if(prodObj) {
        dispatch((concessionsActions.updateProductAvaialableCount({
          categoryDetails: categoryDetails,
          categoryId: prodObj?.categoryId,
          productId: prodObj.id,
          avaialable: Number(`${_avaialableCount}`) + Number(`${qty}`)
        })))
      }
      dispatch(removePaymentSummary())
      dispatch(calculateTarifCountAndTotalPrice());
      dispatch(closeTarifCountChangeModal());
      dispatch(setBasketSelectedType(null));
      dispatch(setBasketSelectedShowtime(null));
      dispatch(setBasketSelectedTarif(null));
    }
    
  };

  const DiscountRow = (props: { uuid: string }) => {
    const { uuid } = props;
    const bookings = useAppSelector((state) => state.basket.orderBookings);
    const currencySymbol = useAppSelector((state) => state.auth.currencySymbol);

    if (uuid !== "concession_uuid") {
      const bookObj = bookings.find((obj) => {
        return obj.uuid === uuid;
      });

      if (!bookObj) {
        return null;
      }
      return (
        <div className="discountMain">
          <div className="discountMainBox">
            <div className="discountContentLabelWrap">
              <div className={"contentLabel"}>{bookObj.discountName}</div>
            </div>
            <div className="discountContentValueWrap">
              <div className={"contentValue"}>
                {"-"}
                {currencySymbol}
                <PriceConverter priceToConvert={bookObj.discountAmount} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {

      const concessionObj = bookings.find((obj) => {
        return obj.isConcession;
      });

      if (!concessionObj) {
        return null;
      }
      return (
        <div className="discountMain">
          <div className="discountMainBox">
            <div className="discountContentLabelWrap">
              <div className={"contentLabel"}>{concessionObj.discountName}</div>
            </div>
            <div className="discountContentValueWrap">
              <div className={"contentValue"}>
                {"-"}
                {currencySymbol}
                <PriceConverter priceToConvert={concessionObj.discountAmount} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    // if title:ticketing there should be "contentBox" else no tarifs remove "contentBox"
    <div className="contentBox">
      {basketType === "ticketing" && rateObj && rateObj.selectedShowTimes
        ? rateObj.selectedShowTimes.map((showtime: any) => {
            return (
              <>
                <ContentTitle
                  titleTxt={showtime ? showtime?.title : ""}
                  subText={showtime ? showtime?.startTime : ""}
                  dateTxt={showtime ? showtime?.startDate : ""}
                />
                {showtime.tarifs
                  ? showtime.tarifs.map((tarifObj: any, key: any) => (
                      <ContentMain
                        key={`${key}_ticketing_item`}
                        tarifObj={tarifObj}
                        onCountClick={(data: any) =>
                          onCountClick(data, basketType, showtime.id)
                        }
                        onRemoveClick={(id: any) =>
                          onRemoveClick(id, showtime.id)
                        }
                        isEditable={
                          showtime.seats.length > 0 ? false : isRetractable
                        }
                        type={basketType}
                        isRetractable={isRetractable}
                      />
                    ))
                  : null}
                {discountData &&
                parseFloat(discountData.discountAmount.toString()) > 0 ? (
                  <DiscountRow uuid={showtime.uuid} />
                ) : null}
              </>
            );
          })
        : null}
      {basketType === "confectionery" && rateObj.tarifs
        ? rateObj.tarifs.map((tarifObj: any, key: any) => (
            <ContentMain
              key={`${key}_confectionery_item`}
              tarifObj={tarifObj}
              onCountClick={(data: any) => onCountClick(data, basketType)}
              onRemoveClick={(id: any) => onRemoveClick(id)}
              isEditable={isRetractable}
              isRetractable={isRetractable}
              type={basketType}
            />
          ))
        : null}
      {basketType === "confectionery" &&
      discountData &&
      parseFloat(discountData.discountAmount.toString()) > 0 ? (
        <DiscountRow uuid={"concession_uuid"} />
      ) : null}
      {
        <ModalBox
          maxWidth="xs"
          modalTitle={"Quantity Change"}
          visibility={isTarifCountChangeModalOpen}
          onClose={() => {
            dispatch(closeTarifCountChangeModal());
          }}
          onConfirm={onTarifCountChangeApply}
        >
          <NumberPadModal ref={tarifCountModalRef} />
        </ModalBox>
      }
    </div>
  );
};

export default BasketContentBox;
