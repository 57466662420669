import React, { useEffect, useImperativeHandle, useState } from "react";
// import moment from 'moment';
import moment from "moment-timezone";
import CalendarView from "../../Common/CalendarView";
import { formatDate } from "../../../utility";

interface CalendarModalProps {
  selectedDate: string;
}

interface CalendarModalRef {
  getDateValue(): void;
}
const CalendarModal = React.forwardRef<CalendarModalRef, CalendarModalProps>(
  (props, ref) => {
    
    const { selectedDate } = props;

    const [changedDate, setChangeDate] = useState(selectedDate);

    const handleDateChange = (event: any) => {
      setChangeDate(formatDate(new Date(event).toDateString()));
    };

    useImperativeHandle(ref, () => ({
      getDateValue() {
        return changedDate;
      },
    }));
    return (
      <>
        <CalendarView
          onDateChange={handleDateChange}
          selectedDate={selectedDate}
        />
      </>
    );
  }
);
export default CalendarModal;
