import { Button, Grid } from "@material-ui/core";
import React from "react";
import { css } from "../../../utility";
import "./SeatBox.scss";

interface SeatBoxProps {
  id: string;
  label: string;
  top?: any;
  bottom?: any;
  left?: any;
  right?: any;
  type: string | undefined;
  onSeatClick: (obj: any) => void;
}
const SeatBox = (props: SeatBoxProps) => {
  const { id, label, top, bottom, left, right, type, onSeatClick } = props;

  return (
    <div
      className={css("seatBox", ` ${type}`)}
      style={{ top: top, bottom: bottom, left: left, right: right }}
      onClick={() =>
        onSeatClick({
          id,
          label,
          type
        })
      }
    >
      {label}
    </div>
  );
};
export default SeatBox;
