import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAppSelector } from '../../../../../../hooks/useRedux';

interface ContentTitleProps {
  titleTxt: string,
  dateTxt?: string
  subText?: string
}

const ContentTitle = (props: ContentTitleProps) => {
    const { titleTxt, subText, dateTxt } = props;
    const dateFormat = useAppSelector((state) => state.auth.dateFormat)

    const [formattedDate, setFormattedDate] = useState(moment(dateTxt).format(dateFormat ? dateFormat : "DD/MM/YYYY"));

    useEffect(() => {

      if(dateTxt) {
        setFormattedDate(moment(dateTxt).format(dateFormat ? dateFormat : "DD/MM/YYYY"))
      }
    },[dateTxt])
    return (
      <div className="contentTitle">
        <div className="titleTxt">
          {titleTxt}
          <div className="movieDate">{formattedDate}</div>
        </div>
        <div className="subTxt">
          {subText}
        </div>
      </div>
    );
  }

export default ContentTitle;
