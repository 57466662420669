import React, { useCallback, useEffect, useRef, useState } from "react";

import usePayment from "../../hooks/usePayment";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { openAlertBox, updateAlertBoxContent } from "../../redux/alertBox/alertBoxSlice";
import { setPaymentMethod } from "../../redux/basket/basketAction";
import {
  closeFixedSideBasket,
  setPaybleamount,
  updateSelectedPaymentMethod,
} from "../../redux/basket/basketSlice";
import {
  setHeaderProps,
  setBasketProps,
  setIsPageLoaderText,
  setTopNavProps,
} from "../../redux/layout/layoutSlice";
import {
  validateCustomer,
  validateCustomerOtp,
} from "../../redux/payment/paymentAction";
import {
  setCustomerLoyaltyEmail,
  setLoyaltyCustomerEmailModalVisibility,
  setLoyaltyCustomerOtpModalVisibility,
  setLoyaltyPointsModalVisibility,
  setPointsToRedeem,
  setPointsValueToRedeem,
} from "../../redux/payment/paymentSlice";
import { setTicketPrintable } from "../../redux/printTicket/printTicketSlice";
import { getLangLabel } from "../../utility";
import BasketScreen from "../Common/BasketScreen";
import LangTextLabel from "../Common/LangTextLabel";
import ModalBox from "../Common/ModalBox";
import POSLayout from "../Common/POSLayout/POSLayout";
import CardPayement from "../Common/ScreenCards/CardPayement";
import EmailEnterModal from "../Modals/EmailEnterModal";
import LoyaltyPaymentModal from "../Modals/LoyaltyPaymentModal";
import OTPEnterModal from "../Modals/OTPEnterModal";
import "./Payment.scss";

const Payment = () => {
  const {
    paymentMethods,
    reservationId,
    totalPrice,
    selectedPaymentMethod,
    basketEmailValue,
    paybleAmount,
  } = useAppSelector((state) => state.basket);
  const { headerProps, basketProps, topNavProps } = useAppSelector(
    (state) => state.layout
  );
  const { terminal } = useAppSelector((state) => state.auth);
  const {
    currentPaymentSenceMessage,
    completeBookingStatus,
    loyaltyPointsModalVisibility,
    customerLoyaltyEmail,
    loyaltyCustomerEmailModalVisibility,
    loyaltyCustomerOtpModalVisibility,
    paymentCampaign,
    loyaltyData,
    pointsToRedeem,
    pointsValueToRedeem,
  } = useAppSelector((state) => state.payment);
  const { applyPaymentSteps } = usePayment();
  const [isEnterEmailModalVisibility, setIsEnterEmailModalVisibility] =
    useState(false);
  const enterEmailModalRef = useRef<any>(null);

  const [paymentMethodsDisable, setPaymentMethodsDisable] = useState(false);
  const otpModalRef = useRef<any>(null);
  const loyaltyModalRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(closeFixedSideBasket());

    let _topNavProps = { ...topNavProps };
    _topNavProps.backIcon = true;
    dispatch(setTopNavProps(_topNavProps));

    let _headerProps = { ...headerProps };
    _headerProps.cartBtn = false;
    _headerProps.datePicker = false;
    _headerProps.consessionMenu = false;
    _headerProps.issueConsessionBtn = false;
    dispatch(setHeaderProps(_headerProps));

    let _basketProps = { ...basketProps };
    _basketProps.ticketSwitch = true;
    _basketProps.emailSwitch = true;
    dispatch(setBasketProps(_basketProps));

    if (selectedPaymentMethod && selectedPaymentMethod.id) {
      dispatch(
        setPaymentMethod(
          {
            reservation_id: reservationId,
            payment_method_id: selectedPaymentMethod.id,
            paymentObj: selectedPaymentMethod
            
          },
          pointsValueToRedeem
        )
      );
    } else {
      dispatch(
        setPaymentMethod(
          {
            reservation_id: reservationId,
            payment_method_id: 0,
            paymentObj: null
          },
          pointsValueToRedeem
        )
      );
    }

    return () => {
      dispatch(setIsPageLoaderText(""));
      dispatch(
        setBasketProps({
          ticketSwitch: false,
          emailSwitch: false,
        })
      );
      let _topNavProps = { ...topNavProps };
      _topNavProps.backIcon = false;
      dispatch(setTopNavProps(_topNavProps));
    };
  }, []);

  useEffect(() => {
    if (currentPaymentSenceMessage) {
      applyPaymentSteps(
        currentPaymentSenceMessage,
        paybleAmount,
        reservationId,
        completeBookingStatus,
        basketEmailValue,
        selectedPaymentMethod ? selectedPaymentMethod.id : "",
        pointsToRedeem,
        pointsValueToRedeem,
        customerLoyaltyEmail
      );
    }
  }, [currentPaymentSenceMessage]);

  const handlePaymentMethodClick = (id: any) => {
    if (
      selectedPaymentMethod &&
      id === selectedPaymentMethod?.id
    ) {
      dispatch(updateSelectedPaymentMethod(null));
      dispatch(
        setPaymentMethod(
          {
            reservation_id: reservationId,
            payment_method_id: 0,
            paymentObj: null
          },
          pointsValueToRedeem
        )
      );

      dispatch(setTicketPrintable(false))
    } else {
      const _paymentObj = paymentMethods.find((obj) => { return obj.id === id})
      dispatch(
        setPaymentMethod(
          {
            reservation_id: reservationId,
            payment_method_id: id,
            paymentObj: _paymentObj
          },
          pointsValueToRedeem
        )
      );

      dispatch(setTicketPrintable(true))
    }
  };

  const handleLoyaltyClick = () => {
    dispatch(setLoyaltyCustomerEmailModalVisibility(true));
  };

  const onEmailEnterApply = () => {
    const emailValue = enterEmailModalRef.current?.getEmailValue();

    if (emailValue) {
      dispatch(setCustomerLoyaltyEmail(emailValue));
      dispatch(
        validateCustomer({
          email: emailValue,
        })
      );
    }
  };

  const resendLoyaltyCustomerOTP = () => {
    dispatch(
      validateCustomer({
        email: customerLoyaltyEmail,
      })
    );
  };

  const validateOtpConfirm = () => {
    const optValue = otpModalRef.current.getOtpValue();

    if (!optValue) {
      return;
    }
    dispatch(
      validateCustomerOtp({
        email: customerLoyaltyEmail,
        otp_code: optValue,
      })
    );

    //send to otp varification and redirect to the refund page
  };

  const loyaltyPointsConfirm = () => {
    const pointValue = loyaltyModalRef.current.getNumberValue();
    const points = loyaltyModalRef.current.getPointsValue();

    if (!pointValue) {
      return;
    }

    if (loyaltyData) {
      dispatch(setPointsToRedeem(points));
      dispatch(setPointsValueToRedeem(pointValue));
      const _paybleAmount = (Number(paybleAmount) - Number(pointValue)).toFixed(
        2
      );
      dispatch(setPaybleamount(_paybleAmount));
    }

    dispatch(setLoyaltyPointsModalVisibility(false));
  };

  useEffect(() => {

    if(Number(paybleAmount) === 0) {
      dispatch(setTicketPrintable(true))
      setPaymentMethodsDisable(true)
    } else if(Number(paybleAmount) < 0) {
      dispatch(
        updateAlertBoxContent({
          alertType: "validation",
          message: getLangLabel("invalid-payble-amount"),
        })
      );
      dispatch(openAlertBox());
      const _pointsValue = pointsValueToRedeem;
      const newPayble = (Number(paybleAmount) + Number(_pointsValue)).toFixed(2)
      dispatch(setPointsToRedeem(0));
      dispatch(setPointsValueToRedeem(0.0))
      dispatch(setPaybleamount(newPayble))
    } else {
      if(!selectedPaymentMethod) {
        dispatch(setTicketPrintable(false))
      }
      setPaymentMethodsDisable(false)
    }
  }, [paybleAmount])

  return (
    <POSLayout>
      <div className="paymentsView">
        <div className="paymentsMainCont">
          <div className="paymentsMainWrap">
            <div className="paymentsMain">
              <div className="paymentsMainBody">
                <div className="paymentsMainScrollable">
                  <div className="paymentsSection">
                    <div className="title">
                      <LangTextLabel lngCode="types-of-payment" />
                    </div>
                    <div className="paymentCardsContainer">
                      <div className="paymentCardsRow">
                        {paymentMethods.length > 0
                          ? paymentMethods.map((paymentType) => {
                              return (
                                <div
                                  className="paymentCardsGrid"
                                  key={paymentType.id}
                                >
                                  <CardPayement
                                    type={paymentType.name}
                                    icon={paymentType.icon}
                                    onPaymentClick={(id) =>
                                      handlePaymentMethodClick(id)
                                    }
                                    id={paymentType.id}
                                    isAcive={
                                      selectedPaymentMethod?.id ===
                                      paymentType.id
                                    }
                                    isDisable={paymentMethodsDisable}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                  {paymentCampaign ? (
                    <div className="paymentsSection loyaltyPointsSec">
                      <div className="title">
                        <LangTextLabel lngCode="use-loyalty-points" />
                      </div>
                      <div className="paymentCardsContainer">
                        <div className="paymentCardsRow">
                          <div
                            className="paymentCardsGrid"
                            key={`loyalty_${paymentCampaign.id}`}
                          >
                            <CardPayement
                              type={paymentCampaign.name}
                              icon={paymentCampaign.image}
                              onPaymentClick={() => handleLoyaltyClick()}
                              id={`loyalty_${paymentCampaign.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basketScreenCont">
          <div className="basketScreenWrap">
            <BasketScreen
              isResetBtn={true}
              isRetractable={false}
              isEditable={false}
            />
          </div>
        </div>
        {
          <ModalBox
            maxWidth="xs"
            modalTitle={getLangLabel("enter-email-address")}
            visibility={loyaltyCustomerEmailModalVisibility}
            onClose={() => {
              dispatch(setLoyaltyCustomerEmailModalVisibility(false));
            }}
            onConfirm={onEmailEnterApply}
            confirmText={getLangLabel("validate")}
          >
            <EmailEnterModal ref={enterEmailModalRef} />
          </ModalBox>
        }
        {
          <ModalBox
            maxWidth="xs"
            modalTitle={getLangLabel("enter-otp")}
            visibility={loyaltyCustomerOtpModalVisibility}
            onClose={() => {
              dispatch(setLoyaltyCustomerOtpModalVisibility(false));
            }}
            onConfirm={validateOtpConfirm}
            isCta2Enable
            cta2BtnText={getLangLabel("resend-otp")}
            onCta2Click={resendLoyaltyCustomerOTP}
          >
            <OTPEnterModal
              ref={otpModalRef}
              descriptionText={`This action requires the validation of the user ${customerLoyaltyEmail}. Please enter code.`}
            />
          </ModalBox>
        }
        {
          <ModalBox
            maxWidth="xs"
            modalTitle={`${getLangLabel("redeem")} ${paymentCampaign?.name}`}
            visibility={loyaltyPointsModalVisibility}
            onClose={() => {
              dispatch(setLoyaltyPointsModalVisibility(false));
            }}
            onConfirm={loyaltyPointsConfirm}
          >
            <LoyaltyPaymentModal ref={loyaltyModalRef} />
          </ModalBox>
        }
      </div>
    </POSLayout>
  );
};

export default Payment;
