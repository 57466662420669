import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import PastSalesService from "../../services/pastSalesService";
import {
  ConcessionItemModel,
  PrintAPIModel,
  RefundAPIModel,
  SalesModel,
  SingleSaleAPIModel,
  SingleSaleModel,
  TicketsModel,
  SummeryInfoModel,
  PrintListModel,
  DiscountTypeModel,
  PaymentMethodListState,
} from "./pastSalesModel";
import { layoutSlice } from "../layout/layoutSlice";
import { pastSalesSlice } from "./pastSalesSlice";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import { openAlertBox, updateAlertBoxContent } from "../alertBox/alertBoxSlice";
import { concessionsActions } from "../concessions/concessionsAction";

export const pastSalesActions = pastSalesSlice.actions;
const layoutActions = layoutSlice.actions;

export const getPastSales = (
  dataItem: SalesModel
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await PastSalesService.getPastSales(dataItem);
      const { data, totalRows } = response;
      dispatch(pastSalesActions.setPastSales(data));
      dispatch(pastSalesActions.setTotalRows(totalRows));
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const getSinglePastSale = (
  dataItem: SingleSaleAPIModel
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      dispatch(pastSalesActions.setSinglePastDetails(null));
      const response: any = await PastSalesService.getSinglePastSale(dataItem);

      const { data } = response;
      const {
        cashier_name,
        payment_method,
        sub_total,
        purchased_date,
        booking_fee,
        discount,
        total,
        currency_symbol,
        reference_no,
        tax,
        tariffData,
        concessionData,
        print_item_list,
        is_refund_available,
        reservation_id,
        applied_discounts,
        payment_method_list,
        refund_reason
      } = data;

      const { tickets } = tariffData || { tickets: [] };
      const { items } = concessionData;

      let ticketDetailsArray: TicketsModel[] = [];
      let printDetailsArray: PrintListModel[] = [];
      let discountTypeArray: DiscountTypeModel[] = [];

      for (let index = 0; index < applied_discounts.length; index++) {
        const discount_type = applied_discounts[index];

        const { name } = discount_type;
        const discountTypeData: DiscountTypeModel = {
          name: name,
        };
        discountTypeArray.push(discountTypeData);
      }

      const summeryDetails: SummeryInfoModel = {
        sub_total: sub_total,
        tax: tax,
        booking_fee: booking_fee,
        discount: discount,
        total: total,
        discount_types: discountTypeArray,
      };

      for (let index = 0; index < print_item_list.length; index++) {
        const printList = print_item_list[index];

        const { label, booking_id, reference_no, key } = printList;

        const printData: PrintListModel = {
          label: label,
          booking_id: booking_id,
          reference_no: reference_no,
          key: key,
        };

        printDetailsArray.push(printData);
      }

      for (let index = 0; index < tickets.length; index++) {
        const ticketList = tickets[index];

        const {
          name,
          single_price,
          qty,
          total_price,
          is_supplement,
          price_with_supplement,
          name_with_supplement,
          movie,
          show_time,
          reference_no,
          id,
          status,
          print_count,
          strikethrough,
          description,
          supplement_txt,
          supplement_ticket_price,
        } = ticketList;

        const ticketData: TicketsModel = {
          ticketName: name,
          ticketPrice: single_price,
          ticketQty: qty,
          totalPrice: single_price,
          isSupplement: is_supplement,
          priceWithSupplement: price_with_supplement,
          nameWithSupplement: name_with_supplement,
          description: description,
          showTime: show_time,
          movieName: movie,
          referenceNo: reference_no,
          isTicket: 1,
          id: id,
          printCount: print_count,
          status: status,
          supplementText: supplement_txt,
          isStrikethrough: strikethrough,
          supplementTicketPrice: supplement_ticket_price,
        };

        ticketDetailsArray.push(ticketData);
      }

      for (let index = 0; index < items.length; index++) {
        const concession = items[index];

        const {
          product_name,
          qty,
          price,
          total,
          reference_no,
          product_id,
          print_count,
          status,
          strikethrough,
          is_issued
        } = concession;
        const concessionData: TicketsModel = {
          ticketName: product_name,
          ticketPrice: price,
          ticketQty: qty,
          totalPrice: total,
          priceWithSupplement: price,
          nameWithSupplement: product_name,
          isSupplement: 0,
          showTime: null,
          movieName: null,
          referenceNo: reference_no,
          isTicket: 0,
          id: product_id,
          printCount: print_count,
          status: status,
          isStrikethrough: strikethrough,
          description: product_name,
          supplementText: null,
          supplementTicketPrice: null,
          isIssued: is_issued ? true : false
        };
        ticketDetailsArray.push(concessionData);
      }

      let paymentMethodText = "";
      let _paymentMethodList:PaymentMethodListState[] = [];
      if(payment_method_list) {
        payment_method_list.forEach((element: any, index: number) => {
            const { amount, payment_method_id, payment_method_name, amount_only } = element;
            if(index === payment_method_list.length - 1) {
              paymentMethodText +=`${payment_method_name} = ${amount}`
            }else {
              paymentMethodText +=`${payment_method_name} = ${amount}, `
            }
            
            const paymentMethodObj: PaymentMethodListState = {
              paymentMethodId: payment_method_id,
              amount: amount_only,
              amountText: amount
            }

            _paymentMethodList.push(paymentMethodObj)
        });
      }

      const singleSalesModel: SingleSaleModel = {
        ticketNumber: reference_no,
        refundAmount: null,
        cashierName: cashier_name,
        isRefundAvailable: is_refund_available,
        purchaseDate: purchased_date,
        ticketList: ticketDetailsArray,
        paymentMethod: payment_method,
        totalAmount: total,
        currencySymbol: currency_symbol,
        concessionInfo: null,
        referenceNo: reference_no,
        summeryInfo: summeryDetails,
        printListDetails: printDetailsArray,
        reservationId: reservation_id,
        paymentMethodText: paymentMethodText,
        refundReason: refund_reason ?? "",
        paymentMethodList: _paymentMethodList
      };
      dispatch(pastSalesActions.setSinglePastDetails(singleSalesModel));
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const getPrintDetails = (
  dataItem: PrintAPIModel
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    dispatch(layoutActions.setIsPageLoaderText(""));
    try {
      const response: any = await PastSalesService.getPrintDetails(dataItem);
      dispatch(pastSalesActions.setPrinttDetails(response));
    } catch (ex) {
      console.log(ex);
    } finally {
      // dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const createRefund = (
  dataItem: RefundAPIModel,
  history: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await PastSalesService.createRefund(dataItem);
      if (response.status_code == 5000) {
        dispatch(
          updateAlertBoxContent({
            alertType: "success",
            message: response.message,
          })
        );
        dispatch(openAlertBox());
        history.push(ROUTE_CONSTANT_MAP.PAST_SALES);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const getSinglePastSaleByQr = (
  dataItem: {barcode: string, terminal_code: string, type: "receipt" | "concession", valid: boolean},
  history: any,
  stateObj: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      dispatch(pastSalesActions.setSinglePastDetails(null));
      const response: any = await PastSalesService.checkQrValid(dataItem);

      const { status, status_code, data, message } = response;

      if(!status) {
        dispatch(concessionsActions.setBarcodeScannerError(message));
        return
      }

      const {
        cashier_name,
        payment_method,
        sub_total,
        purchased_date,
        booking_fee,
        discount,
        total,
        currency_symbol,
        reference_no,
        tax,
        tariffData,
        concessionData,
        print_item_list,
        is_refund_available,
        reservation_id,
        applied_discounts,
        payment_method_list,
        refund_reason
      } = data;

      const { tickets } = tariffData || { tickets: [] };
      const { items } = concessionData;

      let ticketDetailsArray: TicketsModel[] = [];
      let printDetailsArray: PrintListModel[] = [];
      let discountTypeArray: DiscountTypeModel[] = [];

      for (let index = 0; index < applied_discounts.length; index++) {
        const discount_type = applied_discounts[index];

        const { name } = discount_type;
        const discountTypeData: DiscountTypeModel = {
          name: name,
        };
        discountTypeArray.push(discountTypeData);
      }

      const summeryDetails: SummeryInfoModel = {
        sub_total: sub_total,
        tax: tax,
        booking_fee: booking_fee,
        discount: discount,
        total: total,
        discount_types: discountTypeArray,
      };

      for (let index = 0; index < print_item_list.length; index++) {
        const printList = print_item_list[index];

        const { label, booking_id, reference_no, key } = printList;

        const printData: PrintListModel = {
          label: label,
          booking_id: booking_id,
          reference_no: reference_no,
          key: key,
        };

        printDetailsArray.push(printData);
      }

      for (let index = 0; index < tickets.length; index++) {
        const ticketList = tickets[index];

        const {
          name,
          single_price,
          qty,
          total_price,
          is_supplement,
          price_with_supplement,
          name_with_supplement,
          movie,
          show_time,
          reference_no,
          id,
          status,
          print_count,
          strikethrough,
          description,
          supplement_txt,
          supplement_ticket_price,
        } = ticketList;

        const ticketData: TicketsModel = {
          ticketName: name,
          ticketPrice: single_price,
          ticketQty: qty,
          totalPrice: single_price,
          isSupplement: is_supplement,
          priceWithSupplement: price_with_supplement,
          nameWithSupplement: name_with_supplement,
          description: description,
          showTime: show_time,
          movieName: movie,
          referenceNo: reference_no,
          isTicket: 1,
          id: id,
          printCount: print_count,
          status: status,
          supplementText: supplement_txt,
          isStrikethrough: strikethrough,
          supplementTicketPrice: supplement_ticket_price,
        };

        ticketDetailsArray.push(ticketData);
      }

      for (let index = 0; index < items.length; index++) {
        const concession = items[index];

        const {
          product_name,
          qty,
          price,
          total,
          reference_no,
          product_id,
          print_count,
          status,
          strikethrough,
          is_issued
        } = concession;
        const concessionData: TicketsModel = {
          ticketName: product_name,
          ticketPrice: price,
          ticketQty: qty,
          totalPrice: total,
          priceWithSupplement: price,
          nameWithSupplement: product_name,
          isSupplement: 0,
          showTime: null,
          movieName: null,
          referenceNo: reference_no,
          isTicket: 0,
          id: product_id,
          printCount: print_count,
          status: status,
          isStrikethrough: strikethrough,
          description: product_name,
          supplementText: null,
          supplementTicketPrice: null,
          isIssued: is_issued ? true : false
        };
        ticketDetailsArray.push(concessionData);
      }

      let paymentMethodText = "";
      let _paymentMethodList:PaymentMethodListState[] = [];
      if(payment_method_list) {
        payment_method_list.forEach((element: any, index: number) => {
            const { amount, payment_method_id, payment_method_name, amount_only } = element;
            if(index === payment_method_list.length - 1) {
              paymentMethodText +=`${payment_method_name} = ${amount}`
            }else {
              paymentMethodText +=`${payment_method_name} = ${amount}, `
            }

            const paymentMethodObj: PaymentMethodListState = {
              paymentMethodId: payment_method_id,
              amount: amount_only,
              amountText: amount
            }

            _paymentMethodList.push(paymentMethodObj)
            
        });
      }

      const singleSalesModel: SingleSaleModel = {
        ticketNumber: reference_no,
        refundAmount: null,
        cashierName: cashier_name,
        isRefundAvailable: is_refund_available,
        purchaseDate: purchased_date,
        ticketList: ticketDetailsArray,
        paymentMethod: payment_method,
        totalAmount: total,
        currencySymbol: currency_symbol,
        concessionInfo: null,
        referenceNo: reference_no,
        summeryInfo: summeryDetails,
        printListDetails: printDetailsArray,
        reservationId: reservation_id,
        paymentMethodText: paymentMethodText,
        refundReason: refund_reason ?? "",
        paymentMethodList: _paymentMethodList
      };
      dispatch(pastSalesActions.setSinglePastDetails(singleSalesModel));

      /**
       * check the type and close related popup modal
       */
      if(dataItem.type === "receipt") {
        dispatch(pastSalesActions.setReceiptScanModalVisibility(false))
      } else if(dataItem.type === "concession") {
        dispatch(concessionsActions.toggleConsessionIssueModalVisibility(false))
      }
      history.push(ROUTE_CONSTANT_MAP.PAST_SALE_DETAIL, stateObj);
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};