export const constants: any = {
    "app.version" : process.env.REACT_APP_VERSION,
    "api_url" : process.env.REACT_APP_API_BASE_URL,
    "payment_sense_integration_type": "pac",
    "payment_sense_connect_version": "v1",
    "app.folder_path" : process.env.REACT_APP_FOLDER_PATH,
    "app.keyboard.enable" : process.env.REACT_APP_KEYBOARD_ENABLE,
    "app.booking_time": 10,
    "app.booking_renew_time": 10,
    "app.booking_renew_alert_time_in_sec": 180,
    "app.is_dynamic_api_url" : process.env.REACT_APP_API_URL_DYNAMIC,
    "app.api_base" : process.env.REACT_APP_API_BASE,
}