import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import TicketService from "../../services/ticketingService";
import { SeatItem, TarifItem, TicketItem } from "./ticketingModel";
import { ticketingSlice } from "./ticketingSlice";
import { layoutSlice } from "../layout/layoutSlice";
import { openAlertBox, updateAlertBoxContent } from "../alertBox/alertBoxSlice";

const ticketActions = ticketingSlice.actions;
const layoutActions = layoutSlice.actions;

export const getTicketingShowTimes = (
  movieDate: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await TicketService.getTicketingShowTimes(
        movieDate
      );
      const { data } = response;

      let movieArr: TicketItem[] = [];

      data.forEach((movieObj: any) => {
        const {
          id,
          movie_name,
          theater_name,
          experience_name,
          movie_date,
          start_time,
          session_start_time,
          session_end_time,
          total_seats,
          available_seats,
          seat_plan_status,
          bbfc_logo,
          tariffs,
        } = movieObj;

        let _tarifsArr: TarifItem[] = [];
        tariffs.forEach((tarifObj: any) => {
          const { tariff_id, tariff_name, price, supplement_id, supplement_name, supplement_price } = tarifObj;

          const tarifData: TarifItem = {
            id: tariff_id,
            label: tariff_name,
            price: price,
            supplementId: supplement_id ?? null,
            supplementName: supplement_name ?? null,
            supplementPrice: supplement_price ?? null
          };

          _tarifsArr.push(tarifData);
        });

        const movieData: TicketItem = {
          id: id,
          roomName: theater_name,
          title: movie_name,
          startTime: start_time,
          sessionStartTime: session_start_time,
          sessionEndTime: session_end_time,
          ticketCount: total_seats,
          availableCount: available_seats,
          hasSeatPlan: seat_plan_status === "1" ? true : false,
          experienceeName: experience_name,
          movieDate: movie_date,
          tarifs: _tarifsArr,
          bbfcLogo: bbfc_logo
        };

        movieArr.push(movieData);
      });

      dispatch(ticketActions.updateTicketing(movieArr));
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({ alertType: "error", message: "Showtime list is not recieved" })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const getTicketingShowTimeSeatPlan = (
  showTimeId: string,
  currentSelectedSeats?: any[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await TicketService.getTicketingShowTimeSeatPlan(
        showTimeId
      );
      const { data } = response;
      const { seat_plan, max_positions, seat_plan_booked} = data;

      let seats: SeatItem[] = [];
      if(seat_plan && seat_plan.length > 0) {
        seat_plan.forEach((seatObj: SeatItem) => {

          if(seat_plan_booked.includes(seatObj.label)) {
            seats.push({
              ...seatObj,
              seatStatus: "unavailable"
            })
          } else{
            seats.push({
              ...seatObj,
              seatStatus: "avaialable"
            })
          }

          if(currentSelectedSeats && currentSelectedSeats.length > 0 && currentSelectedSeats.includes(seatObj.label)) {

            const _seatIndex = seats.findIndex((obj) => { return obj.id === seatObj.id});
            let _seatObj = {...seatObj};
            _seatObj.seatStatus = "selected";
            seats[_seatIndex] = _seatObj;
          }
          
        });
      }

      dispatch(ticketActions.updateSelectedShowTimeSeatObj({
        seatPlans: seats,
        maxPositions: max_positions
      }))

      
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({ alertType: "error", message: "Showtime seat plan is not recieved" })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};