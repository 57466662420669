import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./AlertDialog.scss";
import LangTextLabel from "../LangTextLabel";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { closeAlertBox } from "../../../redux/alertBox/alertBoxSlice";
import { makeStyles, Theme, createStyles, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBaseStyles: {
      padding: 0,
      minHeight: 50,
      width: "100%",
      height: "100%",
      "&:disabled": {
        opacity: 0.5,
        backgroundColor: "var(--color-15)",
      },
    },
    iconButtonStyles: {
      position: "absolute",
      right: "5px",
      top: "5px",
    },
    dialogPaper: {
      // minHeight: '30vh',
      maxWidth: "30vw",
      padding: "30px",
    },
  })
);

const AlertDialog = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { message, isOpen, alertType } = useAppSelector(
    (state) => state.alertBox
  );

  const handleClose = () => {
    dispatch(closeAlertBox());
  };
  const dialogMessage =
    !message || message === "" ? (
      <div className="message">
        <LangTextLabel lngCode="error-message-no-network" />
      </div>
    ) : (
      <div className="message">{message}</div>
    );
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <IconButton className={classes.iconButtonStyles} onClick={handleClose}>
        <Close />
      </IconButton>
      <DialogTitle>
        {alertType === "error" ? (
          <div className="alerBoxtTitle error">
            <LangTextLabel lngCode="error" />
          </div>
        ) : alertType === "validation" ? (
          <div className="alerBoxtTitle error">
            <LangTextLabel lngCode="validation" />
          </div>
        ) : alertType === "success" ? (
          <div className="alerBoxtTitle success">
            <LangTextLabel lngCode="success" />
          </div>
        ) : (
          <div className="alerBoxtTitle info">
            <LangTextLabel lngCode="information" />
          </div>
        )}
        {/* {alertType === "error" ? (
          <div className="alerBoxtTitle error">
            <LangTextLabel lngCode="error" />
          </div>
        ) : (
          <div className="alerBoxtTitle success">
            <LangTextLabel lngCode="success" />
          </div>
        )} */}
      </DialogTitle>
      <DialogContent>
        <div className="dialogContent">{dialogMessage}</div>
      </DialogContent>
    </Dialog>
  );
};

export default AlertDialog;
