import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import PriceConverter from "../../PriceConverter";
import { css } from "../../../../utility";
import "./CardTarifs.scss";
import { TarifItem } from "../../../../redux/ticketing/ticketingModel";
import { useAppSelector } from "../../../../hooks/useRedux";

interface CardTarifsProps {
  disabled?: boolean;
  tarifObj?: TarifItem;
  onTarifClick?: (obj: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBaseStyles: {
      padding: 0,
      minHeight: 50,
      width: "100%",
      height: "100%",

      "&:disabled": {
        opacity: 0.5,
        backgroundColor: "var( --color-15)",
      },
    },
  })
);

const CardTarifs = (props: CardTarifsProps) => {
  const classes = useStyles();
  const currencySymbol = useAppSelector((state) => state.auth.currencySymbol)

  const { disabled, onTarifClick, tarifObj } = props;
  return (
    <div className="cardBtn cardTarifs">
      <ButtonBase
        disabled={disabled}
        className={classes.buttonBaseStyles}
        onClick={() => onTarifClick && onTarifClick(tarifObj)}
      >
        <div className="btnContent">
          <div className="btnWrap">
            <div className="detailWrap">
              <div className="titleTxt">
                <div className={"text "}>{tarifObj?.label}</div>
              </div>
              <div className="detailTxt">
                {currencySymbol}<PriceConverter priceToConvert={tarifObj?.price} />
              </div>
            </div>
          </div>
        </div>
      </ButtonBase>
    </div>
  );
};

export default CardTarifs;
