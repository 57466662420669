import React, { Component, MutableRefObject, useRef } from "react";
import Keyboard, { KeyboardOptions } from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";
import NumberPadOnlyLayout from "./NumberPadOnlyLayout";
import "./NumberPad.scss"

interface NumberPadProps {
  keyboardRef: MutableRefObject<typeof Keyboard | null>;
  onChange?: Function;
  onKeyPress?: Function;
  inputName?: string;
  currentValue?: string;
  layout?: string;
  maxLength?: any
}
const NumberPad: React.FC<NumberPadProps> = (props: NumberPadProps) => {
  const { onChange, inputName, currentValue, layout, maxLength, keyboardRef, onKeyPress } = props;

  return (
    <div className="numberPad">
      <NumberPadForward
        ref={keyboardRef}
        onChange={(input: any) => onChange && onChange(input)}
        layoutName={layout ? layout : "otp"}
        display={{
          "{backspace}": "<",
        }}
        buttonTheme={[
          {
            class: "backspaceBtn",
            buttons: "{backspace}",
          },
          {
            class: "decimalBtn",
            buttons: ".",
          },
        ]}
        inputName={inputName}
        layout={NumberPadOnlyLayout}
        maxLength={maxLength}
        physicalKeyboardHighlight={true}
        syncInstanceInputs={true}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};

const NumberPadForward = React.forwardRef<any, KeyboardOptions>((props, ref) => {
  return <Keyboard
    {...props} />;
});

export default NumberPad;
