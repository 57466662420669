import {
  Button,
  createStyles,
  IconButton,
  Theme,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import {
  getPrintDetails,
  getSinglePastSale,
  pastSalesActions,
} from "../../../redux/pastSales/pastSalesAction";
import LangTextLabel from "../../Common/LangTextLabel";
import POSLayout from "../../Common/POSLayout/POSLayout";
import ComponentToPrint from "../../Common/TicketLayout/ComponentToPrint";
import "./PastDetails.scss";
import PastSaleDetailBody from "../PastSaleDetailsBody";
import {
  setTopNavProps,
  setHeaderProps,
  setIsPageLoader,
  setIssueConcessionBtn,
} from "../../../redux/layout/layoutSlice";
import { useHistory } from "react-router-dom";
import ModalBox from "../../Common/ModalBox";
import OtpModal from "../../Modals/OtpModal";
import { getLangLabel } from "../../../utility";
import ROUTE_CONSTANT_MAP from "../../../route/routes-url";
import {
  closeConfirmationBox,
  openConfirmationBox,
  updateConfirmationBoxContent,
} from "../../../redux/confirmationBox/confirmationBoxSlice";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import {
  issueConsession,
  concessionsActions,
  validateIssueConsession,
} from "../../../redux/concessions/concessionsAction";
import { authActions, counterValidate } from "../../../redux/auth/authActions";
import FullScreenBox from "../../Common/ModalBox/FullScreenBox";
import BarcodeScanner from "../../Modals/BarcodeScanner";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButtonStyles: {
      color: "#fff",
    },
    basketFooterButtonStyle: {
      padding: 0,
      backgroundColor: "var(--cta-color)",
      borderRadius: 0,
      color: "var( --color-white)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "var( --cta-color-hover)",
      },
    },
    printButtonStyle: {
      padding: 0,
      backgroundColor: "transparent",
      borderRadius: 0,
      color: "var( --primary-color)",
      fontSize: "var(--f-13)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    refundFooterButtonStyle: {
      padding: 0,
      backgroundColor: "var(--cta-color)",
      borderRadius: 0,
      color: "var( --color-white)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "var( --cta-color-hover)",
      },
    },
    refundFooterButtonDisabledStyle: {
      padding: 0,
      backgroundColor: "var(  --color-rgba-2)",
      borderRadius: 0,
      color: "var( --color-rgba-3)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
    },
  })
);

const PastSaleDetail = () => {
  let componentRef: any = useRef(null);
  const { state } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [pageType, setPageType] = useState<"detail" | "consession_issuing">(
    "detail"
  );
  const statObj = state as any;
  const { singleSaleObj, printDetailsObj } = useAppSelector(
    (state) => state.passales
  );
  const topNavProps = useAppSelector((state) => state.layout.topNavProps);
  const { headerProps } = useAppSelector((state) => state.layout);
  const { user, managerValidation, terminal } = useAppSelector(
    (state) => state.auth
  );

  const {
    consessionIssueModalVisibility,
    barcodeScannerError,
    isssueConsessionBtnEnabled,
  } = useAppSelector((state) => state.concessions);
  const dispatch = useAppDispatch();

  const otpModalRef = useRef<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [otpModalVisibilty, setOtpModalVisibility] = useState(false);

  const onBeforeGetContentResolve = useRef<any>(null);

  useEffect(() => {
    let _topNavProps = { ...topNavProps };
    _topNavProps.backIcon = true;
    dispatch(setTopNavProps(_topNavProps));
    let _headerProps = { ...headerProps };
    _headerProps.searchBtn = false;
    _headerProps.issueConsessionBtn = true;
    _headerProps.qrSearchBtn = false;
    dispatch(setHeaderProps(_headerProps));
    dispatch(concessionsActions.setIsssueConsessionBtnEnabled(true));
    return () => {
      // dispatch(pastSalesActions.setSinglePastDetails(null));
      dispatch(pastSalesActions.setPrinttDetails(null));
      dispatch(concessionsActions.setIsssueConsessionBtnEnabled(false));
    };
  }, []);

  useEffect(() => {
    if (statObj && statObj.saleId) {
      if (statObj.pageType && statObj.pageType === "receipt_scan") {
        return;
      }

      if (statObj.pageType && statObj.pageType === "consession_issuing") {
        setPageType("consession_issuing");
        dispatch(setIssueConcessionBtn(false));
        return;
      }

      dispatch(
        getSinglePastSale({
          reference_no: statObj.saleId,
          terminal_code: statObj.terminal_code,
          concession: statObj.consession ? true : false,
        })
      );
    } else {
      history.push(ROUTE_CONSTANT_MAP.PAST_SALES);
    }
  }, [statObj]);
  /**
   * checking the consession data if thoes are issued or not
   */
  useEffect(() => {
    if (singleSaleObj) {
      const consessionArr = singleSaleObj.ticketList.filter((obj) => {
        return obj.isTicket === 0;
      });
      const isIssuedConsessions = consessionArr.filter((obj) => {
        return obj.isIssued === false;
      });

      //we are set enable disable here when the consessions issue or not
      if (isIssuedConsessions.length > 0) {
        dispatch(concessionsActions.setIsssueConsessionBtnEnabled(true));
      } else {
        dispatch(concessionsActions.setIsssueConsessionBtnEnabled(false));
      }
    }
  }, [singleSaleObj]);

  /**
   * if the manager validaion validated this will effect
   */
  useEffect(() => {
    if (managerValidation && managerValidation === "MANAGER_VALIDATED") {
      setOtpModalVisibility(true);
      dispatch(authActions.setManagerValidation(""));
      history.push(ROUTE_CONSTANT_MAP.PAST_SALE_REFUND_DETAIL, {
        singleSaleObj,
      });
    }
  }, [managerValidation]);

  const handleAfterPrint = useCallback(() => {}, []);

  const handleBeforePrint = useCallback(() => {}, []);

  const handleOnBeforeGetContent = useCallback(
    (reservationId: any, reference: any, print_type: any, booking_id: any) => {
      dispatch(pastSalesActions.setPrinttDetails(null));
      setLoading(true);
      dispatch(
        getPrintDetails({
          reference_no: reference,
          type: print_type,
          booking_id: booking_id,
          reservation_id: reservationId,
        })
      );

      return new Promise<void>((resolve) => {
        onBeforeGetContentResolve.current = resolve;

        setTimeout(() => {
          setLoading(false);
          resolve();
          setTimeout(() => {
            dispatch(setIsPageLoader(false));
          }, 500);
        }, 2000);
      });
    },
    [setLoading]
  );

  const handleRefundBtnClick = () => {
    if (user?.isManager) {
      history.push(ROUTE_CONSTANT_MAP.PAST_SALE_REFUND_DETAIL, {
        singleSaleObj,
      });
    } else {
      setOtpModalVisibility(true);
    }
  };

  const validateOtpConfirm = () => {
    const optValue = otpModalRef.current.getNumberValue();

    if (!optValue) {
      return;
    }

    dispatch(
      counterValidate({
        code: optValue,
      })
    );
    //setOtpModalVisibility(false);
    //send to otp varification and redirect to the refund page
  };

  /**
   * perform consession issue action
   */
  const handleConsessionIssuing = () => {
    const contentObj = {
      title: "",
      message: getLangLabel("do-you-want-to-issuing-consession"),
      okayTxt: getLangLabel("confirm"),
      cancelTxt: getLangLabel("cancel"),
      type: "consession_issuing",
    };
    dispatch(updateConfirmationBoxContent(contentObj));
    dispatch(openConfirmationBox());
  };

  const confirmationBoxOkayClick = (type: string) => {
    if (type === "consession_issuing") {
      dispatch(closeConfirmationBox());
      dispatch(
        issueConsession(
          {
            reference_no: singleSaleObj?.referenceNo,
          },
          history
        )
      );
    }
  };

  const confirmationBoxCancelClick = (type: string) => {
    if (type === "consession_issuing") {
      dispatch(closeConfirmationBox());
    }
  };

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = useCallback((label: string) => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;
    // Good
    return (
      <Button className={classes.printButtonStyle}>
        <div className="btnContentWrap">
          <LangTextLabel lngCode={label} />
          <div className="icon"></div>
        </div>
      </Button>
    );
  }, []);

  const handleConsessionIssuingConfirm = (input: string) => {
    dispatch(
      validateIssueConsession(
        {
          reference_no: input,
          // valid: true
        },
        history,
        {
          saleId: input,
          terminal_code: terminal?.code,
          consession: true,
          pageType: "consession_issuing",
        }
      )
    );
  };

  function NewlineRefundReason(props: any) {
    const text = props.text;
    const newText = text.split('\n').map((str: any) => <p>{str}</p>);
    
    return newText;
  }

  return (
    <POSLayout>
      <div className="gridScrollContainer past-sales-view">
        <div className="pastSalesView">
          <div className="pastSalesMainCont">
            <div className="pastSalesMainWrap">
              <div className="pastSalesMain">
                <div className="pastSalesMainHeader">
                  <div className="titleDetails">
                    {singleSaleObj?.cashierName ? (
                      <>
                        <div className="title">
                          <LangTextLabel lngCode="cashier-name" />
                        </div>
                        <div className="name">{singleSaleObj?.cashierName}</div>
                      </>
                    ) : null}
                  </div>
                  <div className="purchasedDetails">
                    <ul className="detailList">
                      {/* <li>
                        <div className="detail">
                          <LangTextLabel lngCode="ticket-number" />
                        </div>
                      </li> */}
                      <li>
                        <div className="detail">
                          Ref No - <span>{singleSaleObj?.ticketNumber}</span>
                        </div>
                      </li>
                      {/* <li>
                        <div className="detail">
                          <LangTextLabel lngCode="purchase-date" />
                        </div>
                      </li> */}
                      <li>
                        <div className="detail">
                          Purchase Date -{" "}
                          <span>{singleSaleObj?.purchaseDate}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <PastSaleDetailBody>
                  <div className="detailsTable">
                    <div className="listTitles">
                      <div className="title">
                        <LangTextLabel lngCode="quantity" />
                      </div>
                      <div className="title">
                        <LangTextLabel lngCode="description" />
                      </div>
                      <div className="title">
                        <LangTextLabel lngCode="No of prints" />
                      </div>
                      <div className="title">
                        <LangTextLabel lngCode="Status" />
                      </div>
                      <div className="title">
                        <LangTextLabel lngCode="unit-price" />
                      </div>
                      <div className="title">
                        <LangTextLabel lngCode="total" />
                      </div>
                    </div>

                    <div className="dataWrapper">
                      {singleSaleObj?.ticketList.map((item, index) => (
                        <>
                          {item.isSupplement === 0 ? (
                            <div className="dataRow" key={index}>
                              <div
                                className="ticketData"
                                style={{
                                  textDecorationLine: item.isStrikethrough
                                    ? "line-through"
                                    : "none",
                                  color: item.isStrikethrough
                                    ? "#c5c5c5"
                                    : "#000000",
                                }}
                              >
                                <div>{item.ticketQty}</div>
                              </div>
                              <div
                                className="ticketData"
                                style={{
                                  textDecorationLine: item.isStrikethrough
                                    ? "line-through"
                                    : "none",
                                  color: item.isStrikethrough
                                    ? "#c5c5c5"
                                    : "#000000",
                                }}
                              >
                                <div>{item.description}</div>
                                <div>{item.showTime}</div>
                                <div>{item.movieName}</div>
                                <div
                                  style={{
                                    color: item.isStrikethrough
                                      ? "#c5c5c5"
                                      : "#838383",
                                  }}
                                >
                                  {item.supplementText}
                                </div>
                              </div>
                              <div
                                className="ticketData"
                                style={{
                                  textDecorationLine: item.isStrikethrough
                                    ? "line-through"
                                    : "none",
                                  color: item.isStrikethrough
                                    ? "#c5c5c5"
                                    : "#000000",
                                }}
                              >
                                {item.printCount === 0 ||
                                item.printCount === null
                                  ? "Not Printed"
                                  : item.printCount}
                              </div>
                              <div
                                className="ticketData"
                                style={{
                                  textDecorationLine: item.isStrikethrough
                                    ? "line-through"
                                    : "none",
                                  color: item.isStrikethrough
                                    ? "#c5c5c5"
                                    : "#000000",
                                }}
                              >
                                {item.status}
                              </div>
                              <div
                                className="ticketData"
                                style={{
                                  textDecorationLine: item.isStrikethrough
                                    ? "line-through"
                                    : "none",
                                  color: item.isStrikethrough
                                    ? "#c5c5c5"
                                    : "#000000",
                                }}
                              >
                                <div>{item.ticketPrice}</div>
                                <div
                                  style={{
                                    color: item.isStrikethrough
                                      ? "#c5c5c5"
                                      : "#838383",
                                  }}
                                >
                                  {item.supplementTicketPrice}
                                </div>
                              </div>
                              <div
                                className="ticketData"
                                style={{
                                  textDecorationLine: item.isStrikethrough
                                    ? "line-through"
                                    : "none",
                                  color: item.isStrikethrough
                                    ? "#c5c5c5"
                                    : "#000000",
                                }}
                              >
                                <div>{item.totalPrice}</div>
                                <div
                                  style={{
                                    color: item.isStrikethrough
                                      ? "#c5c5c5"
                                      : "#838383",
                                  }}
                                >
                                  {item.supplementTicketPrice}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="totalDescriptionWrap">
                    <div className="subTotalDescription">
                      <div className="subTitleWrap">
                        <div className="title">
                          <LangTextLabel lngCode="payment-method" />
                        </div>
                        <div className="value">
                          <div>{singleSaleObj?.paymentMethodText}</div>
                        </div>
                      </div>
                    </div>
                    <div className="totalDescription">
                      <div className="subTitleWrap">
                        <div className="title">
                          <LangTextLabel lngCode="Sub Total" />
                        </div>
                        <div className="value">
                          <div>{singleSaleObj?.summeryInfo?.sub_total}</div>
                        </div>
                      </div>
                      <div className="subTitleWrap">
                        <div className="title">
                          <LangTextLabel lngCode="tax" />
                        </div>
                        <div className="value">
                          <div>{singleSaleObj?.summeryInfo?.tax}</div>
                        </div>
                      </div>
                      <div className="subTitleWrap">
                        <div className="title">
                          <LangTextLabel lngCode="Booking Fee" />
                        </div>
                        <div className="value">
                          <div>{singleSaleObj?.summeryInfo?.booking_fee}</div>
                        </div>
                      </div>

                      <div className="subTitleWrap">
                        <div className="title">
                          <LangTextLabel lngCode="Discount" />
                        </div>
                        <div className="value">
                          <div>{singleSaleObj?.summeryInfo?.discount}</div>
                        </div>
                      </div>
                      {singleSaleObj?.summeryInfo?.discount_types.map(
                        (item, index) => (
                          <div className="subTitleWrap">
                            <div className="title">
                              <div>{item.name}</div>
                            </div>
                          </div>
                        )
                      )}
                      <div className="totalTitleWrap">
                        <div className="title">
                          <LangTextLabel lngCode="total" />
                        </div>
                        <div className="value">
                          {singleSaleObj?.summeryInfo?.total}
                        </div>
                      </div>
                    </div>
                  </div>
                </PastSaleDetailBody>
              </div>
            </div>
          </div>

          <div className="refundScreenCont">
            <div className="refundScreenWrap">
              <div className="refundScreen">
                {!singleSaleObj?.isRefundAvailable && singleSaleObj?.refundReason ? (
                  <div>
                    <div className="contentHeader">
                      <div className="headerTxt">
                        <LangTextLabel lngCode="refund-reason" />
                      </div>
                    </div>
                    <div className="refundReasonBlock">
                     <NewlineRefundReason text={singleSaleObj?.refundReason} />
                    </div>
                  </div>
                ) : null}
                <div className="contentHeader">
                  <div className="headerTxt">
                    <LangTextLabel lngCode="tickets-print" />
                  </div>
                </div>

                <div className="basketBody">
                  <div className="basketBodyscrollable">
                    <div className="hide">
                      <ComponentToPrint
                        ticketPrintData={printDetailsObj}
                        ref={componentRef}
                      />
                    </div>
                    {/* Receipt Print */}
                    {singleSaleObj?.printListDetails.map((item, index) => (
                      <ReactToPrint
                        content={reactToPrintContent}
                        onAfterPrint={handleAfterPrint}
                        onBeforeGetContent={() =>
                          handleOnBeforeGetContent(
                            singleSaleObj.reservationId,
                            singleSaleObj?.referenceNo,
                            item.key,
                            item.booking_id
                          )
                        }
                        onBeforePrint={handleBeforePrint}
                        trigger={() => reactToPrintTrigger(item.label)}
                      />
                    ))}
                  </div>
                </div>
                <div className="refundFooter">
                  <div className="refundScreenCont">
                    <div className="refundScreenWrap">
                      <div className="basketScreen">
                        <div className="basketFooter">
                          <div className="mainBtnWrap">
                            {pageType === "detail" ? (
                              <Button
                                disabled={
                                  singleSaleObj?.isRefundAvailable
                                    ? false
                                    : true
                                }
                                onClick={() => handleRefundBtnClick()}
                                className={
                                  singleSaleObj?.isRefundAvailable
                                    ? classes.refundFooterButtonStyle
                                    : classes.refundFooterButtonDisabledStyle
                                }
                              >
                                <div className="btnTxt">
                                  <LangTextLabel lngCode={singleSaleObj?.isRefundAvailable ? "refund" : "refunded"} />
                                </div>
                              </Button>
                            ) : null}
                            {pageType === "consession_issuing" &&
                            isssueConsessionBtnEnabled ? (
                              <Button
                                onClick={() => handleConsessionIssuing()}
                                className={classes.refundFooterButtonStyle}
                              >
                                <div className="btnTxt">
                                  <LangTextLabel lngCode={"issue"} />
                                </div>
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationDialog
          onOkayClick={(type: any) => confirmationBoxOkayClick(type)}
          onCancelClick={(type: any) => confirmationBoxCancelClick(type)}
        />
        {
          <ModalBox
            maxWidth="xs"
            modalTitle={getLangLabel("manager-validation")}
            visibility={otpModalVisibilty}
            onClose={() => {
              setOtpModalVisibility(false);
            }}
            onConfirm={validateOtpConfirm}
          >
            <OtpModal ref={otpModalRef} />
          </ModalBox>
        }
        {
          <ModalBox
            maxWidth="xs"
            modalTitle={getLangLabel("issuing-consessions")}
            visibility={consessionIssueModalVisibility}
            onClose={() =>
              dispatch(
                concessionsActions.toggleConsessionIssueModalVisibility(false)
              )
            }
            isCta1ButtonDisplay={false}
          >
            <BarcodeScanner
              type="qr"
              onConfirm={(value) => {
                handleConsessionIssuingConfirm(value);
              }}
              isError={barcodeScannerError ? true : false}
              errorText={`${barcodeScannerError}`}
              descriptionText={getLangLabel("issueing-concession-description")}
            />
          </ModalBox>
        }
      </div>
    </POSLayout>
  );
};

export default PastSaleDetail;
