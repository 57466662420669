import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const CircularIndeterminate = () => {
  const styles = {
    progress: {
      backgroundColor: 'transparent',
    },
  };
  return (
    <div className="loader">
      <CircularProgress style={styles.progress} />
    </div>
  );
}

export default CircularIndeterminate;
