import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BasketProductItem,
  BasketState,
  BasketTarifItem,
  DiscountDataState,
  OrderBookingState,
  PaymentMethodState,
  SelectedShowTimeObjState,
  TaxDataState,
} from "./basketModel";

// Define the initial state using that type
const initialState: BasketState = {
  isFixedSideBasketOpen: false,
  selectedShowTimeObj: null,
  paymentMethods: [],
  basketObj: {
    ticketing: {
      label: "ticketing",
      title: "tickets",
      showtimeDate: null,
      selectedShowTimes: [],
    },
    confectionery: {
      label: "confectionery",
      title: "confectionery",
      tarifs: [],
    },
  },
  totalPrice: 0.0,
  paybleAmount: 0.0,
  tarifCount: 0,
  isTarifCountChangeModalOpen: false,
  selectedPaymentMethod: null,
  reservationId: null,
  orderId: null,
  discountData: null,
  taxData: null,
  isPaymentConfirmModalOpen: false,
  orderReference: null,
  isPaymentSuccesModalOpen: false,
  isTicketPrintEnable: true,
  isEmailEnable: false,
  basketEmailValue: null,
  bookingCountDown: 0,
  bookingRenewStatus: null,
  basketSelectedShowtime: null,
  basketSelectedTarif: null,
  basketSelectedType: null,
  basketBookingTimerExtnded: false,
  orderBookings: []
};

export const basketSlice = createSlice({
  name: "basket",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initProduct: (state) => {
      state = state;
    },
    setSelectedShowTime: (
      state,
      { payload }: PayloadAction<SelectedShowTimeObjState>
    ) => {
      const showTimeObj: any = state.basketObj.ticketing.selectedShowTimes.find(
        (obj) => {
          return obj.id === payload.id;
        }
      );

      if (!showTimeObj) {
        state.basketObj.ticketing.selectedShowTimes.push(payload);
      }else {
        const showTimeObjIndex: any = state.basketObj.ticketing.selectedShowTimes.findIndex(
          (obj) => {
            return obj.id === payload.id;
          }
        );

        let _showTimeObj = {...showTimeObj}
        _showTimeObj.seats = payload.seats
        state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex] = _showTimeObj;
      }
    },
    initBasketOjectForTicketing: (state, { payload }: PayloadAction<any>) => {
      state.basketObj.ticketing = payload;
    },
    updateRatesForTicketing: (
      state,
      {
        payload,
      }: PayloadAction<{
        showTimeId: string | number;
        tarifObj: BasketTarifItem;
      }>
    ) => {
      const showTimeObj: any = state.basketObj.ticketing.selectedShowTimes.find(
        (obj) => {
          return obj.id === payload.showTimeId;
        }
      );

      const showTimeObjIndex: any =
        state.basketObj.ticketing.selectedShowTimes.findIndex((obj) => {
          return obj.id === payload.showTimeId;
        });

      const objIndex = showTimeObj.tarifs.findIndex((obj: { id: any }) => {
        return obj.id === payload.tarifObj.id;
      });

      if (objIndex >= 0) {
        /**
         * find tarif and add 1 quantity to the qty
         */
        let _temTarifObj = { ...showTimeObj.tarifs[objIndex] };
        _temTarifObj.qty = _temTarifObj.qty && Number(_temTarifObj.qty) + 1;
        const qty = _temTarifObj.qty ? Number(_temTarifObj.qty) : 1;
        _temTarifObj.totalPrice =
          parseFloat(_temTarifObj.price.toString()) * Number(qty);

        _temTarifObj.supplementTotalPrice =
          (_temTarifObj.supplementId
            ? parseFloat(_temTarifObj.supplementPrice.toString())
            : 0) * Number(qty);

        let _tarrifs = [...showTimeObj.tarifs];
        _tarrifs[objIndex] = _temTarifObj;

        /**
         * update showtime avaialableCount decrease by 1
         */
        let showTimeUpdated = { ...showTimeObj };
        showTimeUpdated.availableCount =
          Number(showTimeUpdated.availableCount) - 1;
        showTimeUpdated.tarifs = _tarrifs;

        state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex] =
          showTimeUpdated;
      } else {
        /**
         * if a new tarif to showtime we will set quantity as 1
         */
        let _tarifObj = { ...payload.tarifObj };
        _tarifObj.qty = 1;
        _tarifObj.totalPrice = parseFloat(_tarifObj.price.toString()) * 1;
        _tarifObj.supplementTotalPrice =
          (_tarifObj.supplementId && _tarifObj.supplementPrice
            ? parseFloat(_tarifObj.supplementPrice.toString())
            : 0) * 1;

        /**
         * update show time avaialable count decrease by 1
         */

        let showTimeUpdated = { ...showTimeObj };
        showTimeUpdated.availableCount =
          Number(showTimeUpdated.availableCount) - 1;
        showTimeUpdated.tarifs.push(_tarifObj);

        state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex] =
          showTimeUpdated;
      }
    },
    removeRateFromTicketing: (
      state,
      {
        payload,
      }: PayloadAction<{ tarifId: string | number; showTimeId?: string, seats?: any[] }>
    ) => {
      const showTimeObjIndex: any =
        state.basketObj.ticketing.selectedShowTimes.findIndex((obj) => {
          return obj.id === payload.showTimeId;
        });
      const tarifs = state.basketObj.ticketing.selectedShowTimes[
        showTimeObjIndex
      ].tarifs.filter((obj) => {
        return obj.id !== payload.tarifId;
      });

      /**
       * update showtime avaialable count when removing the tarif
       */
      const selectedTarifObj: any = state.basketObj.ticketing.selectedShowTimes[
        showTimeObjIndex
      ].tarifs.filter((obj) => {
        return obj.id === payload.tarifId;
      });
      let showTimeUpdated = {
        ...state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex],
      };
      showTimeUpdated.availableCount =
        Number(showTimeUpdated.availableCount) +
        Number(`${selectedTarifObj.qty}`);
      state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex] =
        showTimeUpdated;

      if(payload.seats) {
        state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex].seats = payload.seats;
      }

      if (tarifs.length > 0) {
        /**
         * remove selected tarif and assing others
         */
        state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex].tarifs =
          tarifs;
      } else if (tarifs.length === 0) {
        /**
         * if there is no tarifs exisited we will remove show time
         */
        const newShowtime = state.basketObj.ticketing.selectedShowTimes.filter(
          (showime) => {
            return showime.id !== payload.showTimeId;
          }
        );
        state.basketObj.ticketing.selectedShowTimes = newShowtime;
        state.bookingCountDown = 0;
      }
    },
    clearBasket: (state) => {
      state.basketObj = {
        ticketing: {
          label: "ticketing",
          title: "tickets",
          showtimeDate: null,
          selectedShowTimes: [],
        },
        confectionery: {
          label: "confectionery",
          title: "confectionery",
          tarifs: [],
        },
      };
      state.paybleAmount = 0.0;
      state.totalPrice = 0.0;
      state.tarifCount = 0;
      state.paymentMethods = [];
      state.orderId = null;
      state.reservationId = null;
      state.selectedPaymentMethod = null;
      state.discountData = null;
      state.taxData = null;
      state.orderReference = null;
      state.isTicketPrintEnable = true;
      state.isEmailEnable = false;
      state.basketEmailValue = null;
      state.bookingCountDown = 0;
    },
    clearTicketing: (state) => {
      state.basketObj = {
        ticketing: {
          label: "ticketing",
          title: "tickets",
          showtimeDate: null,
          selectedShowTimes: [],
        },
        confectionery: state.basketObj.confectionery,
      };
    },
    openFixedSideBasket: (state) => {
      state.isFixedSideBasketOpen = true;
    },
    closeFixedSideBasket: (state) => {
      state.isFixedSideBasketOpen = false;
    },
    updateBasketObject: (state, { payload }: PayloadAction<any>) => {
      state.basketObj = payload;
    },
    calculateTarifCountAndTotalPrice: (state) => {
      let tarifCount = 0;
      let totalPrice = 0;
      const basketStateObj: any = state.basketObj;

      Object.keys(basketStateObj).map((key) => {
        if (basketStateObj[key].label === "confectionery") {
          const ratesStateObj: any[] = basketStateObj[key].tarifs;
          if (ratesStateObj) {
            ratesStateObj.map((rateStateObj) => {
              totalPrice += parseFloat(rateStateObj.totalPrice);
              tarifCount += 1;
              return true;
            });
          }
          return true;
        } else if (basketStateObj[key].label === "ticketing") {
          const showTimeList: any[] = basketStateObj[key].selectedShowTimes;

          showTimeList.forEach((showTime) => {
            const ratesStateObj: any[] = showTime.tarifs;
            if (ratesStateObj) {
              ratesStateObj.map((rateStateObj) => {
                totalPrice += parseFloat(rateStateObj.totalPrice);

                /**
                 * if there is a suppliment for the rate we will add it to the total
                 */
                if (rateStateObj.supplementId) {
                  totalPrice += parseFloat(rateStateObj.supplementTotalPrice);
                }
                tarifCount += 1;
                return true;
              });
            }
            return true;
          });
        }
      });

      state.totalPrice = totalPrice.toFixed(2);
      state.tarifCount = tarifCount;
    },
    openTarifCountChangeModal: (state) => {
      state.isTarifCountChangeModalOpen = true;
    },
    closeTarifCountChangeModal: (state) => {
      state.isTarifCountChangeModalOpen = false;
    },
    updateTarifQunatityOnTicketing: (
      state,
      {
        payload,
      }: PayloadAction<{ id: string | number; qty: number; showTimeId: string }>
    ) => {
      const showTimeObjIndex: any =
        state.basketObj.ticketing.selectedShowTimes.findIndex((obj) => {
          return obj.id === payload.showTimeId;
        });
      const objIndex = state.basketObj.ticketing.selectedShowTimes[
        showTimeObjIndex
      ].tarifs.findIndex((obj) => {
        return obj.id === payload.id;
      });

      if (objIndex >= 0) {
        let _temTarifObj = {
          ...state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex]
            .tarifs[objIndex],
        };
        const existedQty = _temTarifObj.qty;
        _temTarifObj.qty = Number(payload.qty);

        _temTarifObj.totalPrice =
          parseFloat(_temTarifObj.price.toString()) * Number(payload.qty);

        _temTarifObj.supplementTotalPrice =
          (_temTarifObj.supplementId && _temTarifObj.supplementPrice
            ? parseFloat(_temTarifObj.supplementPrice.toString())
            : 0) * Number(payload.qty);

        let _tarrifs = [
          ...state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex]
            .tarifs,
        ];
        _tarrifs[objIndex] = _temTarifObj;

        /**
         * update showtime avaialableCount
         * add existed qty and remove newly count from aaialable count
         */
        let showTimeUpdated = {
          ...state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex],
        };
        showTimeUpdated.availableCount =
          Number(showTimeUpdated.availableCount) +
          Number(existedQty) -
          Number(payload.qty);
        showTimeUpdated.tarifs = _tarrifs;

        state.basketObj.ticketing.selectedShowTimes[showTimeObjIndex] =
          showTimeUpdated;
      }
    },
    setPaymentMethods: (
      state,
      { payload }: PayloadAction<PaymentMethodState[]>
    ) => {
      state.paymentMethods = payload;
    },
    setOrderReservationID: (state, { payload }: PayloadAction<string>) => {
      state.reservationId = payload;
    },
    setTotalPrice: (state, { payload }: PayloadAction<number | string>) => {
      state.totalPrice = payload;
    },
    updateRatesForConfectionery: (
      state,
      { payload }: PayloadAction<BasketProductItem>
    ) => {
      const objIndex = state.basketObj.confectionery.tarifs.findIndex((obj) => {
        return obj.id === payload.id;
      });

      if (objIndex >= 0) {
        let _temTarifObj = {
          ...state.basketObj.confectionery.tarifs[objIndex],
        };
        _temTarifObj.qty = _temTarifObj.qty && Number(_temTarifObj.qty) + 1;
        _temTarifObj.availableCount = Number(_temTarifObj.availableCount) - 1;
        const qty = _temTarifObj.qty ? _temTarifObj.qty : 1;
        _temTarifObj.totalPrice =
          parseFloat(_temTarifObj.price.toString()) * Number(qty);
        let _tarrifs = [...state.basketObj.confectionery.tarifs];
        _tarrifs[objIndex] = _temTarifObj;
        state.basketObj.confectionery.tarifs = _tarrifs;
      } else {
        let _tarifObj = { ...payload };
        _tarifObj.qty = 1;
        _tarifObj.availableCount = Number(payload.availableCount) - 1;
        _tarifObj.totalPrice = parseFloat(_tarifObj.price.toString()) * 1;
        state.basketObj.confectionery.tarifs.push(_tarifObj);
      }
    },
    updateTarifQunatityOnConfectionery: (
      state,
      {
        payload: { id, qty, availableCount },
      }: PayloadAction<{ id: string | number; qty: number, availableCount: number }>
    ) => {
      const objIndex = state.basketObj.confectionery.tarifs.findIndex((obj) => {
        return obj.id === id;
      });

      if (objIndex >= 0) {
        let _temTarifObj = {
          ...state.basketObj.confectionery.tarifs[objIndex],
        };
        _temTarifObj.qty = Number(qty);
        _temTarifObj.totalPrice =
          parseFloat(_temTarifObj.price.toString()) * Number(qty);
        _temTarifObj.availableCount = availableCount//(Number(_temTarifObj.availableCount) + Number(state.basketObj.confectionery.tarifs[objIndex].qty)) -
          Number(qty);
        let _tarrifs = [...state.basketObj.confectionery.tarifs];
        _tarrifs[objIndex] = _temTarifObj;
        state.basketObj.confectionery.tarifs = _tarrifs;
      }
    },
    removeRateConfectionery: (
      state,
      { payload }: PayloadAction<string | number>
    ) => {
      const taris = state.basketObj.confectionery.tarifs.filter((obj) => {
        return obj.id !== payload;
      });
      state.basketObj.confectionery.tarifs = taris;
    },
    setDiscountData: (state, { payload }: PayloadAction<DiscountDataState | null>) => {
      state.discountData = payload;
    },
    setTxData: (state, { payload }: PayloadAction<TaxDataState | null>) => {
      state.taxData = payload;
    },
    setSelectedPaymentMethod: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.selectedPaymentMethod = payload;
    },
    updateSelectedPaymentMethod: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.selectedPaymentMethod = payload;
    },
    setIsPaymentConfirmModalOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPaymentConfirmModalOpen = payload;
    },
    setOrderReference: (state, { payload }: PayloadAction<string>) => {
      state.orderReference = payload;
    },
    setIsPaymentSuccesModalOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPaymentSuccesModalOpen = payload;
    },
    setTicketingShowTimeDate: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.basketObj.ticketing.showtimeDate = payload;
    },
    setTicketPrintEnable: (state, { payload }: PayloadAction<boolean>) => {
      state.isTicketPrintEnable = payload;
    },
    setEmailEnable: (state, { payload }: PayloadAction<boolean>) => {
      state.isEmailEnable = payload;
    },
    setBasketEmailValue: (state, { payload }: PayloadAction<string | null>) => {
      state.basketEmailValue = payload;
    },
    updateBookedShowtimeUuid: (
      state,
      { payload }: PayloadAction<{ showTime: string; uuid: string }>
    ) => {
      let selectedShowTimes = [...state.basketObj.ticketing.selectedShowTimes];
      let selectedObjIndex = selectedShowTimes.findIndex((obj) => {
        return obj.id === payload.showTime;
      });

      let selectedObj = { ...selectedShowTimes[selectedObjIndex] };

      selectedObj.uuid = payload.uuid;

      selectedShowTimes[selectedObjIndex] = selectedObj;

      state.basketObj.ticketing.selectedShowTimes = selectedShowTimes;
    },
    setBookingCountDown: (state, { payload }: PayloadAction<number>) => {
      state.bookingCountDown = payload;
    },
    setBookingRenewStatus: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.bookingRenewStatus = payload;
    },
    updateAvailableCountOfConfectionoryItem: (
      state,
      {
        payload,
      }: PayloadAction<{ id: string | number; avaialableCount: number }>
    ) => {
      const objIndex = state.basketObj.confectionery.tarifs.findIndex((obj) => {
        return obj.id === payload.id;
      });

      if(payload.avaialableCount === 0) {
        const updatedTarifs = state.basketObj.confectionery.tarifs.filter((obj) => {
          return obj.id !== payload.id;
        });
        state.basketObj.confectionery.tarifs = updatedTarifs;
        state.tarifCount -= 1;
        return;
      }

      if (objIndex >= 0) {
        let _temTarifObj = {
          ...state.basketObj.confectionery.tarifs[objIndex]
        };
        _temTarifObj.qty = Number(payload.avaialableCount);
        _temTarifObj.availableCount = 0;
        let _tarrifs = [...state.basketObj.confectionery.tarifs];
        _tarrifs[objIndex] = _temTarifObj;
        state.basketObj.confectionery.tarifs = _tarrifs;
      }
    },
    setBasketSelectedShowtime: (state, { payload }: PayloadAction<string | null>) => {
      state.basketSelectedShowtime = payload;
    },
    setBasketSelectedTarif: (state, { payload }: PayloadAction<string | null>) => {
      state.basketSelectedTarif = payload;
    },
    setBasketSelectedType: (state, { payload }: PayloadAction<string | null>) => {
      state.basketSelectedType = payload;
    },
    setBasketBookingTimerExtnded: (state, { payload }: PayloadAction<boolean>) => {
      state.basketBookingTimerExtnded = payload;
    },

    setPaybleamount: (state, { payload }: PayloadAction<number | string>) => {
      state.paybleAmount = payload;
    },

    setOrderBookings: (state, { payload }: PayloadAction<OrderBookingState[]>) => {
      state.orderBookings = payload;
    },

    setBasketSelectedShowtimes: (state, { payload }: PayloadAction<SelectedShowTimeObjState[]>) => {
      state.basketObj.ticketing.selectedShowTimes = payload;
    },
    
    removePaymentSummary: (state) => {
      state.discountData = null;
      state.taxData = null;
    }
  },
});

export const {
  initProduct,
  setSelectedShowTime,
  initBasketOjectForTicketing,
  updateRatesForTicketing,
  clearBasket,
  openFixedSideBasket,
  closeFixedSideBasket,
  updateBasketObject,
  calculateTarifCountAndTotalPrice,
  removeRateFromTicketing,
  openTarifCountChangeModal,
  closeTarifCountChangeModal,
  updateTarifQunatityOnTicketing,
  setPaymentMethods,
  setOrderReservationID,
  setTotalPrice,
  updateRatesForConfectionery,
  removeRateConfectionery,
  setTxData,
  setDiscountData,
  setSelectedPaymentMethod,
  setIsPaymentConfirmModalOpen,
  setOrderReference,
  setIsPaymentSuccesModalOpen,
  clearTicketing,
  updateTarifQunatityOnConfectionery,
  setTicketingShowTimeDate,
  setTicketPrintEnable,
  setEmailEnable,
  setBasketEmailValue,
  updateBookedShowtimeUuid,
  setBookingCountDown,
  setBookingRenewStatus,
  updateAvailableCountOfConfectionoryItem,
  setBasketSelectedShowtime,
  setBasketSelectedTarif,
  setBasketSelectedType,
  setBasketBookingTimerExtnded,
  setPaybleamount,
  updateSelectedPaymentMethod,
  setOrderBookings,
  setBasketSelectedShowtimes,
  removePaymentSummary
} = basketSlice.actions;

export default basketSlice.reducer;
