import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import LangTextLabel from "../LangTextLabel";
import "./ModalBox.scss";

interface ModalBoxProps {
  modalTitle: string;
  onClose?: () => void;
  visibility: boolean;
  children: any;
  isCta1ButtonDisplay?: boolean;
  onConfirm?: () => void;
  confirmText?: string;
  maxWidth: any;
  confimDisable?: boolean;
  isCta2Enable?: boolean;
  cta2BtnText?: string;
  onCta2Click?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButtonStyles: {
      position: "absolute",
      right: "5px",
      top: "5px",
    },
    paperStyle: {
      padding: " 30px",
      // paddingTop: "30px",
      // maxWidth: 320,
    },

    titleStyles: {
      padding: 0,
      textAlign: "center",
      marginBottom: 15,
      fontWeight: 700,
    },
    contentStyles: {
      padding: "0px 0px",
    },
    actionStyles: {
      margin: 0,
      marginTop: 15,
      padding: "0px 0px",
    },
    confirmBtnStyle: {
      margin: 0,
      padding: "15px",
      backgroundColor: "var( --cta-color)",
      borderRadius: 5,
      color: "var(--color-white)",
      width: "100%",
      display: "block",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "var(  --cta-color-hover)",
      },
    },
    cancelBtnStyle: {
      margin: 0,
      padding: "15px",
      borderRadius: 5,
      width: "100%",
      display: "block",
      lineHeight: "25px",
    },
    confirmBtnDiableStyle: {
      margin: 0,
      padding: "15px",
      backgroundColor: "var(  --color-rgba-2)",
      borderRadius: 5,
      color: "var( --color-rgba-3)",
      width: "100%",
      display: "block",
      lineHeight: "25px",
    },
  })
);

const ModalBox: React.FC<ModalBoxProps> = (props: ModalBoxProps) => {
  const {
    modalTitle,
    onClose,
    visibility,
    children,
    isCta1ButtonDisplay = true,
    onConfirm,
    confirmText = "confirm",
    maxWidth,
    confimDisable,
    isCta2Enable,
    cta2BtnText = "Cancel",
    onCta2Click,
  } = props;

  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={visibility}
      fullWidth
      maxWidth={maxWidth}
      scroll="body"
      classes={{
        paper: classes.paperStyle,
      }}
    >
      <IconButton className={classes.iconButtonStyles} onClick={onClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.titleStyles}>
        <div className="tarifCountModifierTitleTxt">{modalTitle}</div>
      </DialogTitle>
      <DialogContent className={classes.contentStyles}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actionStyles}>
        {isCta2Enable ? (
          <Button
            onClick={onCta2Click}
            variant="contained"
            color="default"
            className={classes.cancelBtnStyle}
          >
            <LangTextLabel lngCode={cta2BtnText} />
          </Button>
        ) : null}
        {isCta1ButtonDisplay ? (
          <Button
            onClick={onConfirm}
            className={
              confimDisable
                ? classes.confirmBtnDiableStyle
                : classes.confirmBtnStyle
            }
            disabled={confimDisable ? true : false}
          >
            <LangTextLabel lngCode={confirmText} />
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
export default ModalBox;
