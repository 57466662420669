import React, { Component, Fragment, useEffect, useState } from "react";
// import moment from 'moment';
import moment from "moment-timezone";

interface CurrentTimeProps {
  format?: string
}
const CurrentTime = (props: CurrentTimeProps) => {

  const { format } = props;
  const [currentTime, setCurrentTime] = useState<any>(moment().format(format ? format : "LTS"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format(format ? format :"LTS"));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentTime]);

  const currentTimeHtml = <span className="time">{currentTime}</span>;
  return <>{currentTimeHtml}</>;
};
export default CurrentTime;
