import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  openAlertBox,
  updateAlertBoxContent,
} from "../../redux/alertBox/alertBoxSlice";
import {
  calculateTarifCountAndTotalPrice,
  setSelectedShowTime,
  setTicketingShowTimeDate,
  updateRatesForTicketing,
} from "../../redux/basket/basketSlice";
import {
  openConfirmationBox,
  updateConfirmationBoxContent,
} from "../../redux/confirmationBox/confirmationBoxSlice";
import {
  setHeaderProps,
  setTopNav,
  setTopNavProps,
} from "../../redux/layout/layoutSlice";
import { getTicketingShowTimeSeatPlan } from "../../redux/ticketing/ticketingAction";
import { TarifItem, TicketItem } from "../../redux/ticketing/ticketingModel";
import {
  setIsSeatPlanNeedToLoad,
  setSelectedSeats,
  setSelectedTicketingShowTime,
  setSelectedTickets,
  setTotalTicketCount,
  updateAvaialableCountOnSelectedShowTime,
} from "../../redux/ticketing/ticketingSlice";
import { getLangLabel } from "../../utility";
import BasketScreen from "../Common/BasketScreen";
import LangTextLabel from "../Common/LangTextLabel";
import POSLayout from "../Common/POSLayout/POSLayout";
import CardTarif from "../Common/ScreenCards/CardTarifs";
import SeatPlan from "../SeatPlan";
import TicketSelect from "../SeatPlan/TicketSelect";
import "./Tarifs.scss";

interface TarifProps {}

const Tarifs = (props: TarifProps) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const topNavProps = useAppSelector((state) => state.layout.topNavProps);
  const headerProps = useAppSelector((state) => state.layout.headerProps);
  const { basketObj, reservationId } = useAppSelector((state) => state.basket);
  const { selectedShowTime, isSeatPlanNeedToLoad } = useAppSelector(
    (state) => state.ticketing
  );
  const stateObj = state as any;
  const showTimeObj: TicketItem = stateObj.showTimeObj;

  useEffect(() => {
    dispatch(setTopNav(true));
    let _topNavProps = { ...topNavProps };
    _topNavProps.backIcon = true;
    dispatch(setTopNavProps(_topNavProps));
    let _headerProps = { ...headerProps };
    _headerProps.cartBtn = showTimeObj.hasSeatPlan ? true : false;
    _headerProps.datePicker = false;
    _headerProps.consessionMenu = false;
    _headerProps.issueConsessionBtn = false;
    dispatch(setHeaderProps(_headerProps));

    if (reservationId && showTimeObj.id !== selectedShowTime?.id) {
      if (showTimeObj?.hasSeatPlan) {
        dispatch(getTicketingShowTimeSeatPlan(showTimeObj.id, []));
      }

      dispatch(setSelectedSeats([]));
      dispatch(setSelectedTickets([]));
      dispatch(setTotalTicketCount(0));
    } else {
      const _basketShowtimeObj = basketObj.ticketing.selectedShowTimes.find(
        (show) => {
          return show.id === showTimeObj.id;
        }
      );

      if (_basketShowtimeObj) {
        let _selectedSeatsList = _basketShowtimeObj.seats.map(function (item) {
          return item["label"];
        });

        if (showTimeObj.hasSeatPlan) {
          dispatch(
            getTicketingShowTimeSeatPlan(showTimeObj.id, _selectedSeatsList)
          );

          dispatch(setSelectedSeats(_basketShowtimeObj.seats));
          dispatch(setSelectedTickets(_basketShowtimeObj.tarifs));
          dispatch(setTotalTicketCount(_basketShowtimeObj.seats.length));
        }
      } else {
        if (showTimeObj.hasSeatPlan) {
          dispatch(getTicketingShowTimeSeatPlan(showTimeObj.id, []));
        }
      }
    }

    dispatch(setSelectedTicketingShowTime(showTimeObj));

    return () => {
      dispatch(setSelectedTicketingShowTime(null));
      dispatch(setSelectedSeats([]));
      dispatch(setSelectedTickets([]));
      dispatch(setTotalTicketCount(0));
    };
  }, []);

  useEffect(() => {
    if (isSeatPlanNeedToLoad) {
      let _selectedSeatsList = isSeatPlanNeedToLoad.seats.map(function (
        item: any
      ) {
        return item["label"];
      });
      dispatch(
        getTicketingShowTimeSeatPlan(showTimeObj.id, _selectedSeatsList)
      );
      dispatch(setIsSeatPlanNeedToLoad(null));
    }
  }, [isSeatPlanNeedToLoad]);

  const handleTarifClick = (tarifObj: TarifItem) => {
    const _basketShowtimeObj = basketObj.ticketing.selectedShowTimes.find(
      (show) => {
        return show.id === showTimeObj.id;
      }
    );

    if (_basketShowtimeObj?.availableCount === 0) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: getLangLabel("not-avaialable-count-error-message"),
        })
      );
      dispatch(openAlertBox());
      return;
    }

    dispatch(setTicketingShowTimeDate(showTimeObj.movieDate ?? null));

    dispatch(
      setSelectedShowTime({
        id: showTimeObj.id,
        title: showTimeObj.title,
        startTime: showTimeObj.startTime,
        startDate: showTimeObj.movieDate ?? null,
        availableCount: Number(showTimeObj.availableCount),
        tarifs: [],
        seats: [],
      })
    );

    dispatch(
      updateRatesForTicketing({
        showTimeId: showTimeObj.id,
        tarifObj: tarifObj,
      })
    );
    dispatch(calculateTarifCountAndTotalPrice());
  };

  return (
    <POSLayout>
      {
        <div className="tarifsView">
          <div className="tarifsMainCont">
            <div className="tarifsMainWrap">
              <div className="tarifsMain">
                <div className="tarifsMainHeader">
                  <div className="flag">
                    <div className="flagTxt">{showTimeObj.roomName}</div>
                  </div>
                  <div className="productTitle">{showTimeObj.title}</div>
                  <div className="productDetails">
                    <ul className="detailList">
                      <li>
                        <div className="detail">
                          {showTimeObj.startTime} (
                          {showTimeObj.sessionStartTime} -{" "}
                          {showTimeObj.sessionEndTime})
                        </div>
                      </li>
                      {
                        <li key={"tg"}>
                          <div className="detail">
                            {showTimeObj.experienceeName}
                          </div>
                        </li>
                      }

                      <li>
                        <div className="detail">
                          {showTimeObj.availableCount}/{showTimeObj.ticketCount}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {selectedShowTime && selectedShowTime.hasSeatPlan && selectedShowTime.seatsObj ? (
                  <SeatPlan showTimeId={selectedShowTime.id} />
                ) : (
                  <div className="tarifsMainBody">
                    <div className="tarifsMainScrollable">
                      <div className="tarifsSection">
                        <div className="title">
                          <LangTextLabel lngCode="tarifs" />
                        </div>
                        <div className="tarifCardsContainer">
                          <div className="tarifCardsRow">
                            {showTimeObj.tarifs.length > 0
                              ? showTimeObj.tarifs.map(
                                  (tarifObj: TarifItem) => {
                                    return (
                                      <div
                                        className="tarifCardsGrid"
                                        key={tarifObj.id}
                                      >
                                        <CardTarif
                                          disabled={false}
                                          tarifObj={tarifObj}
                                          onTarifClick={(obj: any) =>
                                            handleTarifClick(obj)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="basketScreenCont">
            <div className="basketScreenWrap">
              {showTimeObj.hasSeatPlan ? (
                <TicketSelect
                  tickets={showTimeObj.tarifs}
                  showTime={showTimeObj}
                ></TicketSelect>
              ) : (
                <BasketScreen
                  isResetBtn={false}
                  onBasketCleared={() => {}}
                  isRetractable={true}
                  isEditable={true}
                />
              )}
            </div>
          </div>
        </div>
      }
    </POSLayout>
  );
};

export default Tarifs;
