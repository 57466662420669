import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import {
  setHeaderProps,
  setIsPageLoader,
  setTopNavProps,
} from "../../../redux/layout/layoutSlice";
import LangTextLabel from "../../Common/LangTextLabel";
import POSLayout from "../../Common/POSLayout/POSLayout";
import PriceConverter from "../../Common/PriceConverter";
import PastSaleDetailBody from "../PastSaleDetailsBody";
import "./RefundDetail.scss";
import { createRefund } from "../../../redux/pastSales/pastSalesAction";
import usePayment from "../../../hooks/usePayment";
import InputError from "../../Common/InputError";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButtonStyles: {
      color: "#fff",
    },
    basketFooterButtonStyle: {
      padding: 0,
      backgroundColor: "var(--cta-color)",
      borderRadius: 0,
      color: "var( --color-white)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "var( --cta-color-hover)",
      },
    },
    refundFooterButtonStyle: {
      padding: 0,
      backgroundColor: "var(--cta-color)",
      borderRadius: 0,
      color: "var( --color-white)",
      width: "100%",
      display: "block",
      textTransform: "initial",
      lineHeight: "25px",
      "&:hover": {
        backgroundColor: "var( --cta-color-hover)",
      },
    },
  })
);

const RefundDetail = () => {
  let componentRef: any = useRef(null);
  const { state } = useLocation();
  const classes = useStyles();
  const statObj = state as any;
  const topNavProps = useAppSelector((state) => state.layout.topNavProps);
  const { terminal } = useAppSelector((state) => state.auth);
  const { headerProps } = useAppSelector((state) => state.layout);
  const { currentPaymentSenceMessage } = useAppSelector(
    (state) => state.payment
  );
  const dispatch = useAppDispatch();
  const [singleSaleObj, setSingleSaleObj] = useState<any>(null);
  const [refundTicketList, setRefundTicketList] = useState<any[]>([]);
  const [refundTotalAmount, setRefunTotalAmount] = useState<number>(0);
  const [refundReason, setRefundReason] = useState<string>("");
  const [showValidateMsg, setShowValidateMsg] = useState<boolean>(false);
  const history = useHistory();
  const { sendTerminalDetailsRequest, applyTerminalDetailsForRefund } =
    usePayment();

  useEffect(() => {
    let _topNavProps = { ...topNavProps };
    _topNavProps.backIcon = true;
    dispatch(setTopNavProps(_topNavProps));
    let _headerProps = { ...headerProps };
    _headerProps.searchBtn = false;
    _headerProps.issueConsessionBtn = false;
    dispatch(setHeaderProps(_headerProps));
  }, []);

  useEffect(() => {
    if (statObj && statObj.singleSaleObj) {
      setSingleSaleObj(statObj.singleSaleObj);
      if (statObj.singleSaleObj.ticketList) {
        let _ticketArr: any[] = [];
        statObj.singleSaleObj.ticketList.forEach((item: any) => {
          _ticketArr.push({
            ...item,
            isChecked: false,
          });
        });
        setRefundTicketList(_ticketArr);
      }
    }
  }, [statObj]);

  const handleCheckBoxClick = (e: any, item: any) => {
    const isChecked = e.target.checked;
    let _refundTicketList = [...refundTicketList];
    let itemObjIndex = _refundTicketList.findIndex((obj) => {
      return obj.ticketName === item.ticketName;
    });
    let _itemObj = { ..._refundTicketList[itemObjIndex] };
    _itemObj.isChecked = isChecked;

    _refundTicketList[itemObjIndex] = _itemObj;

    if (isChecked) {
      setRefunTotalAmount((prev) => prev + parseFloat(item.totalPrice));
    } else {
      setRefunTotalAmount((prev) => prev - parseFloat(item.totalPrice));
    }

    setRefundTicketList(_refundTicketList);
  };

  const handleCheckBoxAllClick = (e: any) => {
    setRefunTotalAmount(0);
    const isChecked = e.target.checked;

    let _refundTicketList: any[] = [];
    if (isChecked) {
      refundTicketList.forEach((item: any) => {
        if (item.isSupplement === 0) {
          setRefunTotalAmount((prev) => prev + parseFloat(item.totalPrice));
          item.isChecked = true;
          _refundTicketList.push(item);
        }
      });
    } else {
      setRefunTotalAmount(0);
      refundTicketList.forEach((item: any) => {
        item.isChecked = false;
        _refundTicketList.push(item);
      });
    }
    setRefundTicketList(_refundTicketList);
  };

  const handleRefundReason = (e: any) => {
    setRefundReason(e.target.value);
  };

  const hadnleRefundSbmit = () => {
    /**
     * if the payment method vis master
     * we wiill send request to payment sense to check terminal is available
     */

    const cardPaymentObj = singleSaleObj.paymentMethodList.find((obj:any) => { return obj.paymentMethodId === "2"})

    if (cardPaymentObj) {
      dispatch(setIsPageLoader(true));
      sendTerminalDetailsRequest(terminal ? terminal?.terminalId : "");
      return;
    }

    /**
     * perform refund for cash
     */
    performRefund();
  };

  useEffect(() => {
    if (currentPaymentSenceMessage) {
      if (currentPaymentSenceMessage.id === 1) {
        dispatch(setIsPageLoader(true));
        const cardPaymentObj = singleSaleObj.paymentMethodList.find((obj:any) => { return obj.paymentMethodId === "2"})
        applyTerminalDetailsForRefund(
          currentPaymentSenceMessage,
          cardPaymentObj.amount
        );
      } else if (currentPaymentSenceMessage.id === 9) {
        dispatch(setIsPageLoader(false));
        const { result } = currentPaymentSenceMessage;
        /**
         * perform refund for card
         */
        performRefund(result);
      }
    }
  }, [currentPaymentSenceMessage]);

  const performRefund = (paymentSenseData?: any) => {
    if (refundReason) {
      dispatch(
        createRefund(
          {
            reservation_id: singleSaleObj.reservationId,
            reason: refundReason,
            response: paymentSenseData ? paymentSenseData : null,
          },
          history
        )
      );
    } else {
      setShowValidateMsg(true);
    }
  };

  useEffect(() => {
    if (refundReason) {
      setShowValidateMsg(false);
    }
  }, [refundReason]);

  return (
    <POSLayout>
      <div className="gridScrollContainer past-sales-view">
        <div className="pastSalesView">
          <div className="pastSalesMainCont">
            <div className="pastSalesMainWrap">
              <div className="pastSalesMain">
                <div className="pastSalesMainHeader">
                  <div className="titleDetails">
                    {singleSaleObj?.cashierName ? (
                      <>
                        <div className="title">
                          <LangTextLabel lngCode="cashier-name" />
                        </div>
                        <div className="name">{singleSaleObj?.cashierName}</div>
                      </>
                    ) : null}
                  </div>
                  <div className="purchasedDetails">
                    <ul className="detailList">
                      <li>
                        <div className="detail">
                          <LangTextLabel lngCode="ticket-number" />
                        </div>
                      </li>
                      <li>
                        <div className="detail">
                          <div>{singleSaleObj?.ticketNumber}</div>
                        </div>
                      </li>
                      <li>
                        <div className="detail">
                          <LangTextLabel lngCode="purchase-date" />
                        </div>
                      </li>
                      <li>
                        <div className="detail">
                          <div>{singleSaleObj?.purchaseDate}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <PastSaleDetailBody>
                  <div className="detailsTable">
                    <div className="listTitles">
                      <div className="title">
                        <LangTextLabel lngCode="quantity" />
                      </div>
                      <div className="title refund">
                        <LangTextLabel lngCode="item" />
                      </div>
                      <div className="title price">
                        <LangTextLabel lngCode="unit-price" />
                      </div>
                      <div className="title">
                        <LangTextLabel lngCode="total" />
                      </div>
                    </div>

                    <div className="dataWrapper">
                      {refundTicketList.map((item: any, index: number) => (
                        <>
                          {item.isSupplement === 0 ? (
                            <div className="dataRow" key={index}>
                              <div className="ticketData">
                                <div>{item.ticketQty}</div>
                              </div>
                              <div className="ticketData refund">
                                <div>{item.description}</div>
                                <div>{item.showTime}</div>
                                <div>{item.movieName}</div>
                                <div style={{ color: "#838383" }}>
                                  {item.supplementText}
                                </div>
                              </div>
                              <div className="ticketData price">
                                <div>{item.ticketPrice}</div>
                                <div style={{ color: "#838383" }}>
                                  {item.supplementTicketPrice}
                                </div>
                              </div>
                              <div className="ticketData">
                                <div>{item.ticketPrice}</div>
                                <div style={{ color: "#838383" }}>
                                  {item.supplementTicketPrice}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="totalDescriptionWrap">
                    <div className="totalDescription">
                      <div className="totalTitleWrap">
                        <div className="title">
                          <LangTextLabel lngCode="refund-amount" />
                        </div>
                        <div className="value">
                          {singleSaleObj?.totalAmount}
                        </div>
                      </div>
                    </div>
                  </div>
                </PastSaleDetailBody>
              </div>
            </div>
          </div>
          <div className="refundScreenCont">
            <div className="refundScreenWrap">
              <div className="refundScreen">
                <div className="contentHeader">
                  <div className="headerTxt">
                    <LangTextLabel lngCode="refund-reason" />
                  </div>
                </div>

                <div className="basketBody">
                  <div className="basketBodyscrollable">
                    <div className="formControl">
                      <textarea
                        className="formInput"
                        onChange={handleRefundReason}
                      ></textarea>
                      {showValidateMsg ? (
                        <InputError error={"This is required field"} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="refundFooter">
                  <div className="refundScreenCont">
                    <div className="refundScreenWrap">
                      <div className="refundScreen">
                        <div className="basketFooter">
                          <div className="mainBtnWrap">
                            <Button
                              onClick={() => hadnleRefundSbmit()}
                              className={classes.refundFooterButtonStyle}
                            >
                              <div className="btnTxt">
                                <LangTextLabel lngCode={"refund"} />
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </POSLayout>
  );
};

export default RefundDetail;
