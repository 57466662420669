import { TicketingState } from "../redux/ticketing/ticketingModel";
import { API } from "./Api";
import API_CONSTANT_MAP from "./api-url";

export default {
  async getTicketingShowTimes(movieDate: string) {
    var response = await API.post(API_CONSTANT_MAP.GET_SHOWTIMES, {
      movie_date: movieDate
    });
    return response.data;
  },
  async getTicketingShowTimeSeatPlan(showTimeId: string) {
    var response = await API.post(API_CONSTANT_MAP.GET_SHOWTIME_SEAT_PLAN, {
      showtime: showTimeId
    });
    return response.data;
  },
};
