import {
  openAlertBox,
  updateAlertBoxContent,
} from "../redux/alertBox/alertBoxSlice";
import { completeBooking } from "../redux/basket/basketAction";
import { PaymentMethodState } from "../redux/basket/basketModel";
import { setIsPaymentConfirmModalOpen } from "../redux/basket/basketSlice";
import {
  openConfirmationBox,
  updateConfirmationBoxContent,
} from "../redux/confirmationBox/confirmationBoxSlice";
import {
  setIsPageLoader,
  setIsPageLoaderText,
} from "../redux/layout/layoutSlice";
import { sendEOD } from "../redux/payment/paymentAction";
import {
  setCurrentPaymentSenceMessage,
  setPaymentSenseMessageHistory,
  setPaymentSenseNotifiction,
  setPaymentSenseResult,
  setPaymentSenseSignatureRequestParams,
} from "../redux/payment/paymentSlice";
import { getLangLabel } from "../utility";
import usePaymentSense from "./usePaymentSense";
import { useAppDispatch, useAppSelector } from "./useRedux";

const usePayment = () => {
  const { terminal } = useAppSelector((state) => state.auth);
  const {
    terminalDetails,
    performTransaction,
    signartureVerificationRequest,
    duplicateTransaction,
    cancelTransaction,
    sendEndOfDayReport,
    performRefundTransaction,
  } = usePaymentSense({ socketUrl: terminal?.paymentWebsocketUrl ?? "" });
  const dispatch = useAppDispatch();

  const checkPaymentMethodAndPerform = (
    reservationId: string | null,
    paymentMethod: PaymentMethodState,
    paybleAmount: number | string,
    terminalId: string,
    emailAddress: string | null,
    redeemedPoints?: number,
    redeemedPointvalue?: number,
    loyaltyEmail?: string
  ) => {
    const { id } = paymentMethod;

    switch (id) {
      case "2":
        terminalDetails(terminalId);
        break;
      case "3":
        let bookingData: any = {
          reservation_id: reservationId,
          amount: paybleAmount,
          payment_method_id: id,
        };

        if (emailAddress) {
          bookingData.email = emailAddress;
        }

        if (redeemedPoints && redeemedPoints > 0) {
          bookingData.redeemed_points = redeemedPoints;
          bookingData.redeemed_amount = redeemedPointvalue;
          bookingData.loyalty_email = loyaltyEmail;
        }
        dispatch(completeBooking(bookingData));
        dispatch(setIsPaymentConfirmModalOpen(false));
        break;
      case "0":
        let bookingData1: any = {
          reservation_id: reservationId,
          amount: paybleAmount,
          payment_method_id: id,
        };

        if (emailAddress) {
          bookingData1.email = emailAddress;
        }

        if (redeemedPoints && redeemedPoints > 0) {
          bookingData1.redeemed_points = redeemedPoints;
          bookingData1.redeemed_amount = redeemedPointvalue;
          bookingData1.loyalty_email = loyaltyEmail;
        }
        dispatch(completeBooking(bookingData1));
        dispatch(setIsPaymentConfirmModalOpen(false));
        break;
    }
  };

  const applyPaymentSteps = (
    messageData: any,
    paybleAmount: string | number,
    reservationId: string | number | null,
    completeBookingStatus: string,
    basketEmailValue: string | null,
    paymentMethodId: string | number,
    redeemedPoints?: number,
    redeemedPointvalue?: number,
    loyaltyEmail?: string
  ) => {
    if (!messageData) return;

    if (messageData.method && messageData.method === "terminalNotification") {
      const { params } = messageData;
      dispatch(setPaymentSenseNotifiction(params.notificationValue));
      dispatch(
        setIsPageLoaderText(
          params.notificationValue.replace("_", " ").toLowerCase()
        )
      );
    }

    if (messageData.id === 1) {
      if (messageData.error) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: messageData.error.message,
          })
        );
        dispatch(openAlertBox());
        dispatch(setIsPageLoader(false));
        return;
      }

      if (
        messageData.method &&
        messageData.method === "signatureVerificationRequest"
      ) {
        dispatch(setPaymentSenseNotifiction(""));
        dispatch(setIsPageLoaderText(""));
        dispatch(setIsPageLoader(false));
        dispatch(setPaymentSenseSignatureRequestParams(messageData.id));
        const contentObj = {
          title: "",
          message: getLangLabel("is-the-signature-valid"),
          okayTxt: getLangLabel("confirm"),
          cancelTxt: getLangLabel("cancel"),
          type: "payment_sense_signature_request",
        };
        dispatch(updateConfirmationBoxContent(contentObj));
        dispatch(openConfirmationBox());
        return;
      }

      if (!messageData.result) {
        dispatch(setIsPageLoader(false));
        dispatch(setIsPageLoader(true));
        return;
      }

      const { result: terminalDetailResult } = messageData;
      const { status: terminalStatus, tid, currency } = terminalDetailResult;

      if (terminalStatus !== "AVAILABLE") {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: "Terminal not available, Please try again",
          })
        );
        dispatch(openAlertBox());
        dispatch(setIsPageLoader(false));
        return;
      }

      performTransaction(
        tid,
        currency,
        parseFloat(paybleAmount.toString()) * 100
      );
    } else if (messageData.id === 2) {
      if (messageData.error) {
        dispatch(setIsPageLoader(false));
        dispatch(setIsPageLoader(true));
        return;
      }

      if (messageData.result) {
        const { transactionResult, tid } = messageData.result;

        switch (transactionResult) {
          case "SUCCESSFUL":
            if (completeBookingStatus === "started") return;

            let bookingData: any = {
              reservation_id: reservationId,
              response: messageData.result,
              amount: paybleAmount,
              payment_method_id: paymentMethodId,
            };

            if (basketEmailValue) {
              bookingData.email = basketEmailValue;
            }

            if (redeemedPoints && redeemedPoints > 0) {
              bookingData.redeemed_points = redeemedPoints;
              bookingData.redeemed_amount = redeemedPointvalue;
              bookingData.loyalty_email = loyaltyEmail;
            }

            dispatch(completeBooking(bookingData));
            dispatch(setIsPaymentConfirmModalOpen(false));
            dispatch(setCurrentPaymentSenceMessage(null));
            dispatch(setPaymentSenseMessageHistory(null));
            break;
          case "DECLINED":
          case "VOID":
          case "UNSUCCESSFUL":
            // cancelTransaction(tid)
            dispatch(setPaymentSenseNotifiction(""));
            dispatch(setIsPageLoaderText(""));
            dispatch(
              updateAlertBoxContent({
                alertType: "error",
                message: "Card has been declined, Please try again",
              })
            );
            dispatch(setIsPageLoader(false));
            dispatch(openAlertBox());
            dispatch(setCurrentPaymentSenceMessage(null));
            dispatch(setPaymentSenseMessageHistory(null));
            break;
          case "TIMED_OUT":
            dispatch(setPaymentSenseNotifiction(""));
            dispatch(setIsPageLoaderText(""));
            dispatch(setIsPageLoader(false));
            dispatch(setPaymentSenseResult(messageData.result));
            const contentObj = {
              title: "",
              message: getLangLabel("terminal-timeout-message"),
              okayTxt: getLangLabel("successfull"),
              cancelTxt: getLangLabel("unsuccessfull"),
              hasExtraBtn: true,
              extraBtnText: getLangLabel("retry"),
              type: "payment_sense_time_out",
            };
            dispatch(updateConfirmationBoxContent(contentObj));
            dispatch(openConfirmationBox());
            dispatch(setCurrentPaymentSenceMessage(null));
            dispatch(setPaymentSenseMessageHistory(null));
            break;
          default:
            dispatch(setCurrentPaymentSenceMessage(null));
            dispatch(setPaymentSenseMessageHistory(null));
            break;
        }
      } else if (messageData.id === 7) {
        if (messageData.error) {
          dispatch(
            updateAlertBoxContent({
              alertType: "error",
              message: messageData.error.message,
            })
          );
          dispatch(openAlertBox());
          dispatch(setIsPageLoader(false));
          dispatch(setCurrentPaymentSenceMessage(null));
          dispatch(setPaymentSenseMessageHistory(null));
          return;
        }

        if (messageData.result) {
          const { transactionResult } = messageData.result;

          switch (transactionResult) {
            case "TIMED_OUT":
              dispatch(setPaymentSenseNotifiction(""));
              dispatch(setIsPageLoaderText(""));
              dispatch(setIsPageLoader(false));
              dispatch(setPaymentSenseResult(messageData.result));
              const contentObj = {
                title: "",
                message: getLangLabel("terminal-timeout-message"),
                okayTxt: getLangLabel("successfull"),
                cancelTxt: getLangLabel("unsuccessfull"),
                hasExtraBtn: true,
                extraBtnText: getLangLabel("retry"),
                type: "payment_sense_time_out",
              };
              dispatch(updateConfirmationBoxContent(contentObj));
              dispatch(setCurrentPaymentSenceMessage(null));
              dispatch(setPaymentSenseMessageHistory(null));
              break;
            default:
              break;
          }
        }
      }
    }
  };

  const manageEndOfTheDayPayment = (messageData: any) => {
    dispatch(setIsPageLoader(false));
    if (!messageData) return;

    if (messageData.id === 8) {
      if (messageData.error) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: messageData.error.message,
          })
        );
        dispatch(openAlertBox());
        dispatch(setIsPageLoader(false));
        return;
      }

      if (!messageData.result) {
        dispatch(setIsPageLoader(false));
        return;
      }

      if (messageData.result) {
        const reportResult = messageData.result;

        dispatch(
          sendEOD({
            terminal_id: reportResult.tid,
            report: reportResult,
          })
        );
        dispatch(setCurrentPaymentSenceMessage(null));
        dispatch(setPaymentSenseMessageHistory(null));
      }
    }
  };

  const sendTerminalDetailsRequest = (terminalId: string) => {
    terminalDetails(terminalId);
  };

  const sendCancelTransaction = (terminalId: string) => {
    cancelTransaction(terminalId);
  };

  const sendSignatureRequest = (params: any, signaturAccept: boolean) => {
    signartureVerificationRequest(params, signaturAccept);
  };

  const sendDuplicateRequest = (terminalId: string) => {
    duplicateTransaction(terminalId);
  };

  const sendEndOfDayRequest = (terminalId: string) => {
    sendEndOfDayReport(terminalId);
  };

  const sendPerformRefundRequest = (
    terminalId: string,
    currency: string,
    amount: number | string
  ) => {
    performRefundTransaction(terminalId, currency, amount);
  };

  const applyTerminalDetailsForRefund = (
    messageData: any,
    refundAmount: number | string
  ) => {
    if (!messageData) return;

    if (messageData.id === 1) {
      if (messageData.error) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: messageData.error.message,
          })
        );
        dispatch(openAlertBox());
        dispatch(setIsPageLoader(false));
        return;
      }

      if (!messageData.result) {
        dispatch(setIsPageLoader(false));
        dispatch(setIsPageLoader(true));
        return;
      }

      const { result: terminalDetailResult } = messageData;
      const { status: terminalStatus, tid, currency } = terminalDetailResult;

      if (terminalStatus !== "AVAILABLE") {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: "Terminal not available, Please try again",
          })
        );
        dispatch(openAlertBox());
        dispatch(setIsPageLoader(false));
        return;
      }

      /**
       * if terminal avaialable we will send refund request to payment sense
       */
      sendPerformRefundRequest(
        tid,
        currency,
        parseFloat(refundAmount.toString()) * 100
      );
    }
  };

  return {
    checkPaymentMethodAndPerform,
    applyPaymentSteps,
    sendSignatureRequest,
    sendDuplicateRequest,
    sendCancelTransaction,
    manageEndOfTheDayPayment,
    sendEndOfDayRequest,
    sendPerformRefundRequest,
    sendTerminalDetailsRequest,
    applyTerminalDetailsForRefund,
  };
};

export default usePayment;
