import React, { useEffect, useState } from "react";
import ImgNoInternet from "../../../assets/images/no-internet.svg";
import { getConfig } from "../../../utility";
import "./NoInternet.scss";

const NoInternet = (props: any) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className="noInternetPageContent pageContent fullHeight">
        <div className="noInternetPage">
          <div className="imgWrap">
            <img src={ImgNoInternet} className="noInternetPageImg" alt="404" />
          </div>
        </div>
        <div className="loginFooter">
          <div className="versionDetails">
            <div className="icon version" />
            <div className="text">{getConfig("app.version")}</div>
          </div>
        </div>
      </div>
    );
  }
};
export default NoInternet;
