import {
  Button,
  createStyles,
  Grid,
  Theme,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import Legend from "../legend";
import Screen from "../../../assets/images/screen.png";
import "./TicketSelect.scss";
import LangTextLabel from "../../Common/LangTextLabel";
import { SeatPlanTarifItem } from "../../../redux/ticketing/ticketingModel";
import { css, getLangLabel } from "../../../utility";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import {
  pushToSelectedTickets,
  removeFromSelectedTickets,
  setSelectedSeats,
  setSelectedTickets,
  setTotalTicketCount,
} from "../../../redux/ticketing/ticketingSlice";
import PriceConverter from "../../Common/PriceConverter";
import {
  calculateTarifCountAndTotalPrice,
  openFixedSideBasket,
  setSelectedShowTime,
  setTicketingShowTimeDate,
  updateRatesForTicketing,
  updateTarifQunatityOnTicketing,
} from "../../../redux/basket/basketSlice";
import {
  openAlertBox,
  updateAlertBoxContent,
} from "../../../redux/alertBox/alertBoxSlice";
import {
  openConfirmationBox,
  updateConfirmationBoxContent,
} from "../../../redux/confirmationBox/confirmationBoxSlice";

interface TicketSelectProps {
  showTime: any;
  tickets: SeatPlanTarifItem[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmBtnStyle: {
      margin: 0,
      padding: "0",
      backgroundColor: "var( --cta-color)",
      borderRadius: 0,
      color: "var(--color-white)",
      display: "block",
      lineHeight: "25px",
      position: "sticky",
      bottom: "0",
      width: "100%",
      "&:hover": {
        backgroundColor: "var(--cta-color-hover)",
      },
    },
    confirmBtnDisableStyle: {
      margin: 0,
      padding: "0",
      backgroundColor: "var(--color-rgba-2)",
      borderRadius: 0,
      color: "var(--color-rgba-3)",
      display: "block",
      lineHeight: "25px",
      position: "sticky",
      bottom: "0",
      width: "100%",
    },
  })
);

const SeatPlan: React.FC<TicketSelectProps> = (props: TicketSelectProps) => {
  const classes = useStyles();
  const { tickets, showTime } = props;
  const dispatch = useAppDispatch();
  const currencySymbol = useAppSelector((state) => state.auth.currencySymbol);
  const basketObj = useAppSelector((state) => state.basket.basketObj);
  const selectedTicketList = useAppSelector(
    (state) => state.ticketing.selectedTicketList
  );
  const selectedSeatsList = useAppSelector(
    (state) => state.ticketing.selectedSeatsList
  );
  const totalTicketCount = useAppSelector(
    (state) => state.ticketing.totalTicketCount
  );

  function incrementOrDecrement(
    ticketID: string | number,
    type: "increment" | "decrement"
  ) {
    if (type === "increment") {
      if (selectedSeatsList.length === totalTicketCount) {
        dispatch(
          updateAlertBoxContent({
            alertType: "validation",
            message: getLangLabel("max-seat-count-reached"),
          })
        );
        dispatch(openAlertBox());
        return;
      }

      let ticketObj = tickets.find((obj) => {
        return obj.id === ticketID;
      });

      if (ticketObj) {
        dispatch(
          pushToSelectedTickets({
            selectedObj: ticketObj,
            selectedTickets: selectedTicketList,
          })
        );
      }
    } else {
      let ticketObj = tickets.find((obj) => {
        return obj.id === ticketID;
      });
      if (ticketObj) {
        dispatch(
          removeFromSelectedTickets({
            selectedObj: ticketObj,
            selectedTickets: selectedTicketList,
          })
        );
      }
    }
  }

  function getQty(ticketId: string | number) {
    if (!selectedTicketList) return;

    let tObj = selectedTicketList.find((obj) => {
      return obj.id === ticketId;
    });

    return tObj?.qty;
  }

  function getIsActive(ticketId: string | number) {
    if (!selectedTicketList) return;

    let tObj = selectedTicketList.find((obj) => {
      return obj.id === ticketId;
    });

    if (tObj?.qty && tObj.qty >= 1) {
      return true;
    }
    return false;
  }

  const handleAddToBasket = () => {
    if (selectedSeatsList.length < totalTicketCount) {
      dispatch(
        updateAlertBoxContent({
          alertType: "validation",
          message: "Selected seats count and ticket types count mismatch",
        })
      );
      dispatch(openAlertBox());
      return;
    }

    dispatch(setTicketingShowTimeDate(showTime.movieDate ?? null));
    dispatch(
      setSelectedShowTime({
        id: showTime.id,
        title: showTime.title,
        startDate: showTime.movieDate ?? null,
        startTime: showTime.startTime,
        availableCount: Number(showTime.avaialableCount),
        tarifs: [],
        seats: selectedSeatsList,
      })
    );

    selectedTicketList.forEach((ticketObj) => {
      dispatch(
        updateRatesForTicketing({
          showTimeId: showTime.id,
          tarifObj: {
            id: ticketObj.id,
            label: ticketObj.label,
            price: ticketObj.price,
            qty: ticketObj.qty,
            supplementId: ticketObj.supplementId,
            supplementName: ticketObj.supplementName,
            supplementPrice: ticketObj.supplementPrice,
          },
        })
      );

      dispatch(
        updateTarifQunatityOnTicketing({
          id: ticketObj.id,
          qty: ticketObj.qty ? ticketObj.qty : 1,
          showTimeId: showTime.id,
        })
      );
    });

    // dispatch(setTotalTicketCount(0))
    // dispatch(setSelectedSeats([]))
    // dispatch(setSelectedTickets([]))
    dispatch(calculateTarifCountAndTotalPrice());

    dispatch(openFixedSideBasket());
  };

  return (
    <>
      <div className="ticketSelectWrapper">
        <div className="ticketItemWrapper">
          <div className="contentHeader">
            <div className="headerTxt">
              <LangTextLabel lngCode={"tarifs"} />
            </div>
          </div>
          {tickets && tickets.length > 0
            ? tickets.map((ticketObj, index) => {
                return (
                  <div
                    key={`seat_ticket_${ticketObj.id}`}
                    className={css(
                      "ticketItem",
                      index === tickets.length - 1 ? "" : " line-devider"
                    )}
                  >
                    <div className="countDevider">
                      <div className="devideIconWrap">
                        <span
                          className={`devideIcon minus ${
                            getIsActive(ticketObj.id) ? "active" : ""
                          }`}
                          onClick={() =>
                            incrementOrDecrement(ticketObj.id, "decrement")
                          }
                        ></span>
                      </div>
                      <div className="devideBody">
                        <div className="seatTicketWrap">
                          <div className="label">
                            <LangTextLabel lngCode={ticketObj.label} />
                          </div>
                          <div className="mainInfo">
                            <span className="qty">
                              {getQty(ticketObj.id) ?? 0}
                            </span>
                            <span className="typeText">
                              <LangTextLabel lngCode={"ticket-s"} />
                            </span>
                          </div>
                          <div className="ticketPrice">
                            {currencySymbol}
                            <b className="price">
                              <PriceConverter
                                priceToConvert={ticketObj.price}
                              />
                            </b>
                          </div>
                        </div>
                      </div>
                      <div className="devideIconWrap">
                        <span
                          className={`devideIcon add ${
                            selectedSeatsList.length > 0 ? "active" : ""
                          }`}
                          onClick={() =>
                            incrementOrDecrement(ticketObj.id, "increment")
                          }
                        ></span>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <Button
          className={
            selectedSeatsList.length !== totalTicketCount ||
            selectedSeatsList.length === 0
              ? classes.confirmBtnDisableStyle
              : classes.confirmBtnStyle
          }
          onClick={() => handleAddToBasket()}
          disabled={
            selectedSeatsList.length !== totalTicketCount ||
            selectedSeatsList.length === 0
              ? true
              : false
          }
        >
          <div className="btnTxt">
            <LangTextLabel lngCode="add-to-basket" />
          </div>
        </Button>
      </div>
    </>
  );
};
export default SeatPlan;
