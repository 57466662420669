import React from "react";
import { FormHelperText } from "@material-ui/core"

interface InputErrorProps {
    error: string
}
const InputError: React.FC<InputErrorProps> = (props: InputErrorProps) => {
    const { error } = props;
  return <FormHelperText error={true}>{error}</FormHelperText>;
};
export default InputError;
