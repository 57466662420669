import React, { Component, useState, MutableRefObject, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import Keyboard, { KeyboardOptions } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

interface KeyboardNormalProps {
  keyboardRef: MutableRefObject<typeof Keyboard | null>;
  onChange: (input: string) => void;
  onKeyPressHandle?: (input: string) => void;
  inputName: string
}

interface KeyboardNormalRef {
}

const KeyboardNormal = React.forwardRef<KeyboardNormalRef, KeyboardNormalProps>((props, ref) => {
  const { keyboardRef, onChange, inputName, onKeyPressHandle } = props;
  const [layoutName, setLayoutName] = useState("default");

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
    onKeyPressHandle && onKeyPressHandle(button)
  };

  const onChangeAll = (inputs: any) => {
    onChange(inputs)
  };

  return (
    <KeyboardNormalForward
      innerRef={ref}
      ref={(r: any) => (keyboardRef.current = r)}
      layoutName={layoutName}
      onChangeAll={onChangeAll}
      onKeyPress={(button: string) => onKeyPress(button)}
      inputName={inputName}
      physicalKeyboardHighlight={true}
      syncInstanceInputs={true}
    />
  );
});

const KeyboardNormalForward = React.forwardRef<KeyboardNormalRef, KeyboardOptions>((props, ref) => {
  return <Keyboard
    {...props} />;
});

export default KeyboardNormal;
