import { t } from "i18next";
import { constants } from "../assets/constants/config";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

export const TOKEN_KEY = "@pos-token";
export const POS_DOMAIN = "@pos-domain";

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setToken = (token: string) => {
  return storage.setItem(TOKEN_KEY, token);
};

export const removeToken = () => {
  return storage.removeItem(TOKEN_KEY);
};

export const getPosDomain = () => {
  return localStorage.getItem(POS_DOMAIN);
};

export const setPosDomain = (url: string) => {
  return storage.setItem(POS_DOMAIN, url);
};

export const removePosDomain = () => {
  return storage.removeItem(POS_DOMAIN);
};

export const removePersistObj = () => {
  return storage.removeItem('persist:root');
};

export const css = (...arg: any) => {
  return arg.filter((t: string) => t !== undefined).join(" ");
};

export const getConfig = (variable: string) => {
  return constants[variable] ?? "";
}

export const getConfigUrlFromStorage = () => {
  console.log(getPosDomain(), "sdsddsd")
  return getPosDomain() ?? "";
}

export const getLangLabel = (lngCode: string) => {
  return t(lngCode);
}

export const formatDate = (date: string) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}


// set params for route url
export const stringFormat = (template: string, ...args: any[]) => {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

export const hexToRGBA = (hex: any, opacity: number = 1) => {

  hex = hex.replace("#", "");

  hex = '0x' + hex
  let r = hex >> 16 & 0xFF
  let g = hex >> 8 & 0xFF
  let b = hex & 0xFF
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}