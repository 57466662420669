import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  openAlertBox,
  updateAlertBoxContent,
} from "../../redux/alertBox/alertBoxSlice";
import {
  calculateTarifCountAndTotalPrice,
  updateAvailableCountOfConfectionoryItem,
  updateRatesForConfectionery,
} from "../../redux/basket/basketSlice";
import {
  getConcessions,
  concessionsActions,
} from "../../redux/concessions/concessionsAction";
import { ProductModel } from "../../redux/concessions/concessionsModel";
import { concessionsSlice } from "../../redux/concessions/concessionsSlice";
import { initLayout, setHeaderProps } from "../../redux/layout/layoutSlice";
import { getLangLabel } from "../../utility";
import BasketScreen from "../Common/BasketScreen";
import ModalBox from "../Common/ModalBox";
import FullScreenBox from "../Common/ModalBox/FullScreenBox";
import POSLayout from "../Common/POSLayout/POSLayout";
import FoodCategory from "../Common/ScreenCards/FoodCategory";
import ProductDetails from "../Common/ScreenCards/ProductDetails";
import BarcodeScanner from "../Modals/BarcodeScanner";
import "./confectionery.scss";

const Confectionery = (label: []) => {
  const {
    categoryDetails,
    selectedCategory,
    consessionMenuVisibility,
    consessionNotAvaialableList,
  } = useAppSelector((state) => state.concessions);
  const concessionsAction = concessionsSlice.actions;
  const { headerProps, topNavProps } = useAppSelector((state) => state.layout);
  const { basketObj } = useAppSelector((state) => state.basket);
  const dispatch = useAppDispatch();
  const [barcodeScannerError, setBarcodeScannerError] = useState<string>("");
  const [isBarcodeScannerError, setIsBarcodeScannerError] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(initLayout());
    let _topNavProps = { ...topNavProps };
    let _headerProps = { ...headerProps };
    _headerProps.cartBtn = false;
    _headerProps.datePicker = false;
    _headerProps.searchBtn = false;
    _headerProps.consessionMenu = true;
    _headerProps.issueConsessionBtn = false;
    _headerProps.qrSearchBtn = false;
    dispatch(setHeaderProps(_headerProps));
    dispatch(getConcessions());

    return () => {
      let _temHeaderProps = { ...headerProps };
      _temHeaderProps.consessionMenu = false;
      dispatch(setHeaderProps(_temHeaderProps));
    };
  }, []);

  useEffect(() => {
    if (consessionNotAvaialableList && consessionNotAvaialableList.length > 0) {
      dispatch(getConcessions(consessionNotAvaialableList));
      dispatch(concessionsActions.setConsessionNotAvailableList([]));
    }
  }, [consessionNotAvaialableList]);

  const handleCategoryChange = (id: any) => {
    dispatch(concessionsAction.setSelectedCategory(id));
  };

  const handleProductClick = (productObj: any) => {
    const _basketProductObj = basketObj.confectionery.tarifs.find((product) => {
      return product.id === productObj.id;
    });

    if (_basketProductObj?.availableCount === 0) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: getLangLabel("not-avaialable-consesion-count-error-message"),
        })
      );
      dispatch(openAlertBox());
      return;
    }

    dispatch(updateRatesForConfectionery(productObj));
    dispatch(calculateTarifCountAndTotalPrice());
  };

  const handleBarcodeConfirm = (barcode: string) => {
    setIsBarcodeScannerError(false);
    setBarcodeScannerError("");
    let productsArray: ProductModel[] = [];
    categoryDetails.forEach((categoryObj) => {
      if (categoryObj) {
        productsArray.push(...categoryObj.product);
      }
    });

    const prodObj = productsArray.find((obj) => {
      return obj.barcode === barcode;
    });

    if (!prodObj) {
      setBarcodeScannerError(getLangLabel("product-not-found"));
      setIsBarcodeScannerError(true);
      return;
    }
    dispatch(concessionsAction.toggleConseionMenuVisibility(false));

    handleProductClick({
      id: prodObj.id,
      label: prodObj.productName,
      price: prodObj.productUnitPrice,
      availableCount: prodObj.availableCount,
    });
  };

  return (
    <POSLayout>
      <div className="confectioneryView">
        <div className="confectioneryMainCont">
          <div className="confectioneryMainWrap">
            <div className="confectioneryMain">
              <div className="foodCategories">
                {categoryDetails?.map((item, index) => (
                  <FoodCategory
                    key={index}
                    color={item.color}
                    label={item.categoryName}
                    active={item.id === selectedCategory ? true : false}
                    onPressHandler={() => handleCategoryChange(item.id)}
                  />
                ))}
              </div>
              <div className="categoryDetailsWrap">
                <div className="categoryDetails">
                  {categoryDetails
                    .filter((category) => category.id == selectedCategory)
                    .map((item, index) =>
                      item?.product.map((product, index) => (
                        <ProductDetails
                          id={product.id}
                          key={index}
                          color={item.color}
                          name={product.productName}
                          description={product.productDescription}
                          image={product.productImage}
                          price={product.productUnitPrice}
                          currencySymbol={product.currencySymbol}
                          availableCount={product.availableCount}
                          onProductClick={(obj: any) => handleProductClick(obj)}
                        />
                      ))
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basketScreenCont">
          <div className="basketScreenWrap">
            <BasketScreen
              isResetBtn={false}
              onBasketCleared={() => {}}
              isRetractable={true}
              isEditable={true}
            />
          </div>
        </div>
        {
          <ModalBox
            maxWidth={"xs"}
            modalTitle={getLangLabel("menu")}
            visibility={consessionMenuVisibility}
            onClose={() => {
              setIsBarcodeScannerError(false);
              setBarcodeScannerError("");
              dispatch(concessionsAction.toggleConseionMenuVisibility(false));
            }}
            isCta1ButtonDisplay={false}
          >
            <BarcodeScanner
              type="barcode"
              onConfirm={(value) => {
                handleBarcodeConfirm(value);
              }}
              isError={isBarcodeScannerError}
              errorText={barcodeScannerError}
              descriptionText={getLangLabel("concession-scan-description")}
            />
          </ModalBox>
        }
      </div>
    </POSLayout>
  );
};
export default Confectionery;
