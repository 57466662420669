import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import { getToken } from "../../utility";
import { clearKeyboard } from "../../redux/keyboard/keyboardSlice";

const withAuth = (Component: any) => {
  const ComponentWithAuth = (props: any) => {

    const { user } = useAppSelector((state) => state.auth);
    const history = useHistory();
    const {pathname} = useLocation();
    const token = getToken();
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(clearKeyboard())
    },[])


  useEffect(() => {
    if (user === null) {
      history.push(ROUTE_CONSTANT_MAP.LOGIN);
    }
  }, [user]);

      
    return <Component {...props} />;
  };

  return ComponentWithAuth;
};

export default withAuth;