import { Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { setHeaderProps, setTopNavProps } from "../../redux/layout/layoutSlice";
import { getPastSales, getSinglePastSaleByQr, pastSalesActions } from "../../redux/pastSales/pastSalesAction";
import LangTextLabel from "../Common/LangTextLabel";
import ModalBox from "../Common/ModalBox";
import POSLayout from "../Common/POSLayout/POSLayout";
import SearchTypeModal from "../Modals/SearchTypeModal";
import DataTable from "../DataTable";
import "./PastSale.scss";
import FullScreenBox from "../Common/ModalBox/FullScreenBox";
import BarcodeScanner from "../Modals/BarcodeScanner";
import { getLangLabel } from "../../utility";
import {
  concessionsActions,
  issueConsession,
  validateIssueConsession,
} from "../../redux/concessions/concessionsAction";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import { useHistory } from "react-router-dom";

const PastSales = () => {
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("ref_no");

  const { terminal } = useAppSelector((state) => state.auth);
  const { headerProps, topNavProps } = useAppSelector((state) => state.layout);
  const { receiptScanModalVisibility } = useAppSelector((state) => state.passales);
  const { consessionIssueModalVisibility, barcodeScannerError } =
    useAppSelector((state) => state.concessions);
  const dispatch = useAppDispatch();
  const inputRef = useRef<any>(null);
  const history = useHistory();

  let currentPage = 1;
  let perPage = 10;

  useEffect(() => {
    handlePageChange(1);

    let _topNavProps = { ...topNavProps };
    _topNavProps.backIcon = false;
    dispatch(setTopNavProps(_topNavProps));
    let _headerProps = { ...headerProps };
    _headerProps.searchBtn = true;
    _headerProps.cartBtn = false;
    _headerProps.date = false;
    _headerProps.datePicker = false;
    _headerProps.consessionMenu = false;
    _headerProps.issueConsessionBtn = true;
    _headerProps.qrSearchBtn = true;
    dispatch(setHeaderProps(_headerProps));
    dispatch(concessionsActions.setIsssueConsessionBtnEnabled(true));
    return () => {
      let _tempHeaderProps = { ...headerProps };
      _tempHeaderProps.searchBtn = true;
      _tempHeaderProps.cartBtn = false;
      _tempHeaderProps.date = false;
      _tempHeaderProps.datePicker = false;
      _tempHeaderProps.consessionMenu = false;
      _tempHeaderProps.issueConsessionBtn = false;
      _tempHeaderProps.qrSearchBtn = false;
      dispatch(setHeaderProps(_tempHeaderProps));
    };
  }, []);

  useEffect(() => {
    if (searchText.length > 2) {
      if (searchType) {
        handlePageChange(1);
      }
    }
  }, [searchType]);

  const handlePageChange = (page: React.SetStateAction<any>) => {
    currentPage = page;
    dispatch(
      getPastSales({
        type: searchType,
        searchTerm: searchText,
        rowsPerPage: perPage,
        currentPage: page,
      })
    );
  };

  const { pastSalesDetails, totalRows } = useAppSelector(
    (state) => state.passales
  );

  const handlePerRowsChange = (newPerPage: any, page: any) => {
    perPage = newPerPage;
    handlePageChange(page);
  };

  const handleConsessionIssuingConfirm = (input: string) => {
    dispatch(
      getSinglePastSaleByQr({
        barcode: input,
        type: "concession",
        terminal_code: terminal ? terminal.code : "",
        valid: true
      },
      history,
      {
        saleId: input,
        terminal_code: terminal?.code,
        consession: true,
        pageType: "consession_issuing",
      })
    );
  };

  const handleQrScanConfirm = (value: string) => {
    dispatch(
      getSinglePastSaleByQr({
        barcode: value,
        type: "receipt",
        terminal_code: terminal ? terminal.code : "",
        valid: true
      },
      history,
      {
        saleId: value,
        terminal_code: terminal?.code,
        consession: true,
        pageType: "receipt_scan",
      })
    );
  };

  return (
    <POSLayout>
      <div className="gridScrollContainer">
        <DataTable
          dataList={pastSalesDetails}
          totalRows={totalRows}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
        />
      </div>
      {
        <ModalBox
          maxWidth="xs"
          modalTitle={getLangLabel("issuing-consessions")}
          visibility={consessionIssueModalVisibility}
          onClose={() =>
            dispatch(
              concessionsActions.toggleConsessionIssueModalVisibility(false)
            )
          }
          isCta1ButtonDisplay={false}
        >
          <BarcodeScanner
            type="qr"
            onConfirm={(value) => {
              handleConsessionIssuingConfirm(value);
            }}
            isError={barcodeScannerError ? true : false}
            errorText={`${barcodeScannerError}`}
            descriptionText={getLangLabel("issueing-concession-description")}
          />
        </ModalBox>
      }
      {
        <ModalBox
          maxWidth="xs"
          modalTitle={getLangLabel("receipt-scan")}
          visibility={receiptScanModalVisibility}
          onClose={() => dispatch(pastSalesActions.setReceiptScanModalVisibility(false))}
          isCta1ButtonDisplay={false}
        >
          <BarcodeScanner
            type="qr"
            onConfirm={(value) => {
              handleQrScanConfirm(value);
            }}
            isError={barcodeScannerError ? true : false}
            errorText={`${barcodeScannerError}`}
            descriptionText={getLangLabel("receipt-scan-description")}
          />
        </ModalBox>
      }
    </POSLayout>
  );
};

export default PastSales;
