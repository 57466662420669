import React, { useEffect, useRef, useState } from "react";
import KeyboardNormal from "../../components/Common/KeyboardLayouts/KeyboardNormal/KeyboardNormal";
import { getConfig } from "../../utility";
import "./Login.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { setKeyboardVisibility } from "../../redux/keyboard/keyboardSlice";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import LoginForm from "../../components/Forms/LoginForm";

const Login: React.FC = () => {
  const keyboardVisibility = useAppSelector(
    (state) => state.keyboard.visibility
  );
  const dispatch = useAppDispatch();
  const [inputName, setInputName] = useState("terminalCode");
  const [inputs, setInputs] = useState<any>({})
  
  const keyboard = useRef<any>();
  const logoRef = useRef<HTMLDivElement>(null);
  const layoutRef = useRef<any>();
  const loginRef = useRef<any>(null);

  useEffect(() => {
    setInputName(loginRef.current?.getInputName());
  }, []);

  const onChangeAll = (inputs: any) => {
    loginRef.current?.handleSetInput(inputs);
  };

  const handleClickOutside = () => {
    dispatch(setKeyboardVisibility(false));
  };

  const handleKeyboardPress = (button: string) => {
    if(button === "{tab}") {
      loginRef.current.moveToNextInput()
    }
  }

  const setInputNameValue = (id: string) => {
    setInputName(id)
  }

  const scrollToBottomLogin = () => {
    logoRef.current?.scrollIntoView()    
  }

  useOnClickOutside(layoutRef, handleClickOutside);

  return (
    <div className="loginPageContent pageContent fullHeight">
      <div className="loginPageCont">
        <div className="loginPageWrap">
          <div className="loginFormCont">
            <div className="logoCont">
              <div className="logoWrap" ref={logoRef}>
                <img className="App-logo img-fluid" alt="logo" />
              </div>
            </div>
            <div className="loginFormWrap">
              <LoginForm ref={loginRef} keyboard={keyboard} setInputNameValue={(id: string) => setInputNameValue(id)} scrollToBottomLogin={() => scrollToBottomLogin()} />
            </div>
          </div>
        </div>

        {getConfig("app.keyboard.enable") === "TRUE" ? <div
          ref={layoutRef}
          className={`keyboardContainer ${!keyboardVisibility ? "hide" : ""}`}
        >
          <div className="numberPadWrap">
            <KeyboardNormal
              ref={keyboard}
              keyboardRef={keyboard}
              onChange={(inputs) => {
                onChangeAll(inputs);
              }}
              inputName={inputName}
              onKeyPressHandle={handleKeyboardPress}
            />
          </div>
        </div>: null }
      </div> 
      <div className="loginFooter">
        <div className="versionDetails">
          <div className="icon version" />
          <div className="text">{getConfig("app.version")}</div>
        </div>
      </div>
    </div>
  );
};

export default Login;
