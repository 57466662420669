import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter } from "domutils";
import {
  SeatItem,
  SeatObjState,
  SeatPlanTarifItem,
  TicketingState,
} from "./ticketingModel";

// Define the initial state using that type
const initialState: TicketingState = {
  currentDate: "",
  cardItems: [],
  selectedShowTime: null,
  selectedSeatsList: [],
  selectedTicketList: [],
  totalTicketCount: 0,
  isSeatPlanNeedToLoad: null
};

export const ticketingSlice = createSlice({
  name: "ticketing",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initTicketing: (state) => {
      state.cardItems = [];
    },
    updateTicketing: (state, { payload }: PayloadAction<any[]>) => {
      state.cardItems = payload;
    },
    setCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.currentDate = payload;
    },
    clearTickets: (state) => {
      state.selectedShowTime = null;
      state.selectedSeatsList = [];
      state.selectedTicketList = [];
      state.totalTicketCount = 0;
    },
    setSelectedTicketingShowTime: (state, { payload }: PayloadAction<any>) => {
      state.selectedShowTime = payload;
    },
    updateSelectedShowTimeSeatObj: (
      state,
      { payload }: PayloadAction<SeatObjState>
    ) => {
      if (state.selectedShowTime) {
        state.selectedShowTime.seatsObj = payload;
      }
    },
    setSelectedSeats: (state, { payload }: PayloadAction<any>) => {
      state.selectedSeatsList = payload;
    },
    updateSeatObjFromSeat: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        label: string;
        seats: any[];
        type: string;
      }>
    ) => {
      if (state.selectedShowTime && state.selectedShowTime.seatsObj) {
        let seats = [...payload.seats]; //state.selectedShowTime.seatsObj.seatPlans;
        // const seatObj = seats.find((seat) => { return seat.id === payload.id});
        const seatObjIndex = seats.findIndex((seat) => {
          return seat.id === payload.id;
        });

        let _seatObj = { ...seats[seatObjIndex] };
        _seatObj.seatStatus =
          payload.type === "selected" ? "avaialable" : "selected";

        if (payload.type === "selected") {
          const exsitsSeatsCount = state.selectedSeatsList.length;
          const _seats = state.selectedSeatsList.filter((seatO) => {
            return seatO.id !== payload.id;
          });
          const newSeatsCount = _seats.length;

          if(newSeatsCount < exsitsSeatsCount) {
            state.selectedTicketList = [];
            state.totalTicketCount = 0;
          }
          state.selectedSeatsList = _seats;
        } else {
          const _seat = state.selectedSeatsList.find((seatO) => {
            return seatO.id === payload.id;
          });

          if (!_seat) {
            state.selectedSeatsList.push({
              id: payload.id,
              label: payload.label,
            });
          }
        }

        seats[seatObjIndex] = _seatObj;

        state.selectedShowTime.seatsObj = {
          seatPlans: seats,
          maxPositions: state.selectedShowTime.seatsObj.maxPositions,
        };
      }
    },
    setSelectedTickets: (state, { payload }: PayloadAction<any>) => {
      state.selectedTicketList = payload;
    },
    pushToSelectedTickets: (
      state,
      {
        payload,
      }: PayloadAction<{
        selectedObj: SeatPlanTarifItem;
        selectedTickets: SeatPlanTarifItem[];
      }>
    ) => {
      let ticketObjIndex = payload.selectedTickets.findIndex((ticket) => {
        return ticket.id === payload.selectedObj.id;
      });

      if (ticketObjIndex >= 0) {
        let ticketObj = payload.selectedTickets.find((ticket) => {
          return ticket.id === payload.selectedObj.id;
        });

        if (ticketObj) {
          let _ticketObj = { ...ticketObj };
          let _selectedTickets = [...payload.selectedTickets];
          let _qty: number = ticketObj.qty as number;
          _ticketObj.qty = _qty + 1;
          state.totalTicketCount += 1;
          _ticketObj.totalPrice = parseFloat(`${ticketObj.price}`) * _qty;
          _selectedTickets[ticketObjIndex] = _ticketObj;
          state.selectedTicketList = _selectedTickets;
        }
      } else {
        let ticketObj: SeatPlanTarifItem = {
          id: payload.selectedObj.id,
          label: payload.selectedObj.label,
          qty: 1,
          price: payload.selectedObj.price,
          totalPrice: parseFloat(`${payload.selectedObj.price}`) * 1,
          supplementId: payload.selectedObj.supplementId,
          supplementName: payload.selectedObj.supplementName,
          supplementPrice: payload.selectedObj.supplementPrice,
        };
        state.totalTicketCount += 1;
        state.selectedTicketList.push(ticketObj);
      }
    },
    removeFromSelectedTickets: (
      state,
      {
        payload,
      }: PayloadAction<{
        selectedObj: SeatPlanTarifItem;
        selectedTickets: SeatPlanTarifItem[];
      }>
    ) => {
      let ticketObjIndex = payload.selectedTickets.findIndex((ticket) => {
        return ticket.id === payload.selectedObj.id;
      });

      if (ticketObjIndex >= 0) {
        let ticketObj = payload.selectedTickets.find((ticket) => {
          return ticket.id === payload.selectedObj.id;
        });

        if (ticketObj) {
          let _ticketObj = { ...ticketObj };
          let _selectedTickets = [...payload.selectedTickets];
          let _qty: number = ticketObj.qty as number;

          _ticketObj.qty = _qty - 1;
          state.totalTicketCount -= 1;

          if (_ticketObj.qty === 0) {
            let filtredTickets = _selectedTickets.filter((obv) => {
              return obv.id !== payload.selectedObj.id;
            });
            state.selectedTicketList = filtredTickets;
          } else {
            _ticketObj.totalPrice = parseFloat(`${ticketObj.price}`) * _qty;
            _selectedTickets[ticketObjIndex] = _ticketObj;
            state.selectedTicketList = _selectedTickets;
          }
        }
      }
    },
    setTotalTicketCount: (state, { payload }: PayloadAction<number>) => {
      state.totalTicketCount = payload;
    },
    updateSelectedSeatsForSeatPlan: (state, { payload }: PayloadAction<{selectedSeats: any[], selectedShowTime: any}>) => {
      
      if(!state.selectedShowTime) return;

      let _seatplan = state.selectedShowTime.seatsObj ? [...state.selectedShowTime.seatsObj.seatPlans] : [];

      payload.selectedSeats && payload.selectedSeats.forEach(selectedSeat => {

        const seatObjIndex = _seatplan.findIndex((seat) => { return seat.id === selectedSeat.id});
        let _seatObj = {..._seatplan[seatObjIndex]}
        _seatObj.seatStatus = "selected";

        _seatplan[seatObjIndex] = _seatObj;
        
      });
      
      let onj: any = {
        maxPositions: payload.selectedShowTime.seatsObj.maxPositions,
        seatPlans: _seatplan
      }
      state.selectedShowTime = onj
    },
    updateAvaialableCountOnSelectedShowTime: (state, { payload }: PayloadAction<number>) => {
      if(state.selectedShowTime) {
        state.selectedShowTime.availableCount = payload;
      }
    },
    setIsSeatPlanNeedToLoad: (state, { payload }: PayloadAction<any>) => {
        state.isSeatPlanNeedToLoad = payload;
    },

    
  },
});

export const {
  initTicketing,
  updateTicketing,
  setCurrentDate,
  clearTickets,
  setSelectedTicketingShowTime,
  updateSelectedShowTimeSeatObj,
  updateSeatObjFromSeat,
  setSelectedSeats,
  pushToSelectedTickets,
  setSelectedTickets,
  removeFromSelectedTickets,
  setTotalTicketCount,
  updateSelectedSeatsForSeatPlan,
  updateAvaialableCountOnSelectedShowTime,
  setIsSeatPlanNeedToLoad
} = ticketingSlice.actions;

export default ticketingSlice.reducer;
