import i18n from 'i18next';
import enTranslation from '../assets/lang/en/translations.json';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation : enTranslation,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

i18n.changeLanguage("en")

export default i18n;