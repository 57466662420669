import { useDispatch } from "react-redux";
import { initLayout } from "../redux/layout/layoutSlice";
import { authActions } from "../redux/auth/authActions";
import { concessionsActions } from "../redux/concessions/concessionsAction";
import { pastSalesActions } from "../redux/pastSales/pastSalesAction";
import { clearAlertBox } from "../redux/alertBox/alertBoxSlice";
import { clearBasket } from "../redux/basket/basketSlice";
import { clearConfirmationBox } from "../redux/confirmationBox/confirmationBoxSlice";
import { clearTickets } from "../redux/ticketing/ticketingSlice";
import { clearPayment } from "../redux/payment/paymentSlice";
import { clearPrintTicket } from "../redux/printTicket/printTicketSlice";
import { removePersistObj, removeToken } from "../utility";

const useAuth = (): {
  appLogout: Function;
} => {
  const dispatch = useDispatch();

  const appLogout = () => {
    dispatch(initLayout());
    dispatch(clearAlertBox());
    dispatch(clearBasket());
    dispatch(clearConfirmationBox());
    dispatch(clearTickets());
    dispatch(concessionsActions.clearConcession());
    dispatch(pastSalesActions.clearPastSales());
    dispatch(clearPayment());
    dispatch(clearPrintTicket());
    dispatch(authActions.clearAuth());
    removeToken();
    removePersistObj();
  };

  return {
    appLogout,
  };
};

export default useAuth;
