import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import TicketingNow from "../components/Ticketing/TicketingNow/TicketingNow";
import Confectionery from "../components/Confectionery";
import ROUTE_CONSTANT_MAP from "./routes-url";
import Tarifs from "../components/Tarifs";
import PastSales from "../components/PastSales";
import Management from "../components/Management";
import Payment from "../components/Payment";
import PastSaleDetailPage from "../components/PastSales/PastSaleDetail";
import RefundDetail from "../components/PastSales/RefundDetail";

export const getRoutes = () => {
  const routes = [
    {
      path: ROUTE_CONSTANT_MAP.LOGIN,
      exact: true,
      component: Login,
      name: "Login",
      auth: false,
    },
    {
      path: ROUTE_CONSTANT_MAP.DASHBOARD,
      exact: true,
      name: "Dashboard",
      component: Dashboard,
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.TICKETING_NOW,
      name: "Ticketing Now",
      component: TicketingNow,
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.CONFECTIONARY,
      name: "Confectionery",
      component: Confectionery,
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.TARIFS,
      name: "Tarifs",
      component: Tarifs,
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.PAST_SALES,
      name: "Past Sales",
      component: PastSales,
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.PAST_SALE_DETAIL,
      component: PastSaleDetailPage,
      name: "Past sale detail",
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.PAST_SALE_REFUND_DETAIL,
      component: RefundDetail,
      name: "Refund detail",
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.MANAGEMENT,
      name: "Management",
      component: Management,
      auth: true,
    },
    {
      path: ROUTE_CONSTANT_MAP.PAYMENT,
      name: "Payment",
      component: Payment,
      auth: true,
    },
  ];

  return routes;
};
