import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface PrintTicketState {
  isTicketPrintable: boolean;
  ticketPrintData: any;
}

// Define the initial state using that type
const initialState: PrintTicketState = {
  isTicketPrintable: false,
  ticketPrintData: null,
};

export const printTicketSlice = createSlice({
  name: "printTicket",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTicketPrintable: (state, { payload }: PayloadAction<boolean>) => {
      state.isTicketPrintable = payload;
    },
    setTicketPrintData: (state, { payload }: PayloadAction<any>) => {
      state.ticketPrintData = payload;
      state.isTicketPrintable = true;
    },
    clearPrintTicket: (state) => {
      state.isTicketPrintable = false;;
      state.ticketPrintData = null;
    },
  },
});

export const { setTicketPrintable, setTicketPrintData, clearPrintTicket } =
  printTicketSlice.actions;

export default printTicketSlice.reducer;
