import React, { useEffect, useImperativeHandle, useState } from 'react'
// import moment from 'moment';
import moment from 'moment-timezone'
import CalendarView from '../../Common/CalendarView'
import NumberPad from '../../Common/KeyboardLayouts/NumberPad/NumberPad'
import CustomInput from '../../Common/CustomInput'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import InputError from '../../Common/InputError'
import './SearchTypeModal.scss'
import PriceConverter from '../../Common/PriceConverter'
import LangTextLabel from '../../Common/LangTextLabel'
import { useAppSelector } from '../../../hooks/useRedux'
import { Button, makeStyles, Theme, createStyles } from '@material-ui/core'

interface SearchTypeModalRef {
  getSelectedValue(): void
}

interface SearchTypeModalProps {
  searchType?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBaseStyles: {
      backgroundColor: 'var(--color-white)',
      padding: 0,
      minHeight: 50,
      width: '100%',
      margin: 'auto',
      color: 'var(--color-black)',
      border: 'var(--border-1)',
      boxShadow: 'var(--shadow-2)',
    },
    buttonBaseActiveStyles: {
      backgroundColor: 'var(--color-14)',
      padding: 0,
      minHeight: 50,
      margin: 'auto',
      width: '100%',
      border: 'var(--border-1)',
      color: 'var(--color-rgba-1)',
      boxShadow: 'var(--shadow-2)',
    },
  }),
)
const SearchTypeModal = React.forwardRef<SearchTypeModalRef, SearchTypeModalProps>((props, ref) => {
  const { searchType } = props;
  const [selectType, setSelectType] = useState('')
  const classes = useStyles()
  //const classes = useStyles();
  useImperativeHandle(ref, () => ({
    getSelectedValue() {
      return selectType
    },
  }))

  useEffect(() => {

    if(searchType) {
      setSelectType(searchType)
    }

  },[searchType])

  return (
    <div className="priceDetailsWrapper">
      <div>
        {/* Ref number */}
        <div className="button-wrapper">
          <Button
            className={
              selectType === 'ref_no'
                ? classes.buttonBaseActiveStyles
                : classes.buttonBaseStyles
            }
            onClick={() => setSelectType('ref_no')}
          >
            <LangTextLabel lngCode="reference-number" />
          </Button>
        </div>

        {/* Name */}
        <div className="button-wrapper">
          <Button
            className={
              selectType === 'name'
                ? classes.buttonBaseActiveStyles
                : classes.buttonBaseStyles
            }
            onClick={() => setSelectType('name')}
          >
            <LangTextLabel lngCode="name" />
          </Button>
        </div>

        {/* Email */}
        <div className="button-wrapper">
          <Button
            className={
              selectType === 'email'
                ? classes.buttonBaseActiveStyles
                : classes.buttonBaseStyles
            }
            onClick={() => setSelectType('email')}
          >
           <LangTextLabel lngCode="email" />
          </Button>
        </div>

        {/* Purchased date */}
        <div className="button-wrapper">
          <Button
            className={
              selectType === 'created_date'
                ? classes.buttonBaseActiveStyles
                : classes.buttonBaseStyles
            }
            onClick={() => setSelectType('created_date')}
          >
           <LangTextLabel lngCode="purchased-date" />
          </Button>
        </div>
      </div>
    </div>
  )
})
export default SearchTypeModal
