import React, { useEffect } from "react";
import "./TopNavigation.scss";
import ListItem from "@material-ui/core/ListItem";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import LangTextLabel from "../../LangTextLabel";
import ROUTE_CONSTANT_MAP from "../../../../route/routes-url";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useRedux";
import {initLayout, setTopNavProps } from "../../../../redux/layout/layoutSlice";
import { authActions } from "../../../../redux/auth/authActions";
import { removePersistObj, removeToken } from "../../../../utility";
import { clearAlertBox } from "../../../../redux/alertBox/alertBoxSlice";
import { clearBasket } from "../../../../redux/basket/basketSlice";
import { concessionsActions } from "../../../../redux/concessions/concessionsAction";
import { pastSalesActions } from "../../../../redux/pastSales/pastSalesAction";
import { clearConfirmationBox } from "../../../../redux/confirmationBox/confirmationBoxSlice";
import { clearPayment } from "../../../../redux/payment/paymentSlice";
import { clearPrintTicket } from "../../../../redux/printTicket/printTicketSlice";
import { clearTickets } from "../../../../redux/ticketing/ticketingSlice";
import useAuth from "../../../../hooks/useAuth";

interface TopNavigationProps {}
const TopNavigation: React.FC<TopNavigationProps> = (
  props: TopNavigationProps
) => {
  const styles = {
    listItem: {
      padding: 0,
    },
  };

  const history = useHistory();
  const { pathname } = useLocation();
  const { topNavProps } = useAppSelector((state) => state.layout);
 
  const goBackToPrevPage = () => {
    history.goBack();
  };

  const activeNav = (slug: string) => {
    if(pathname.includes(slug)) {
      return true
    }
    return false;
  };

  return (
    <div className="topNavigation">
      <div className="topNavListWrap">
        <ul className="topNavList">
          {topNavProps.backIcon ? (
            <li className="listIconOnly">
              <button type="button" className="listBtn iconBtn" onClick={goBackToPrevPage}>
                <div className="btnInside">
                  <div className="icon back" />
                </div>
              </button>
            </li>
          ) : null}
          {topNavProps.ticketing ? (
            <li>
              <ListItem button style={styles.listItem}>
                <NavLink className="link" to={ROUTE_CONSTANT_MAP.TICKETING_NOW} isActive={() => activeNav("ticketing")}>
                  <div className="icon ticket" />
                  <div className="text">
                    <LangTextLabel lngCode="ticketing" />
                  </div>
                </NavLink>
              </ListItem>
            </li>
          ) : null}
          {topNavProps.confectionery ? (
            <li>
              <ListItem button style={styles.listItem}>
                <NavLink className="link" to={ROUTE_CONSTANT_MAP.CONFECTIONARY}>
                  <div className="icon confectionery" />
                  <div className="text">
                    <LangTextLabel lngCode="confectionery" />
                  </div>
                </NavLink>
              </ListItem>
            </li>
          ) : null}
          {topNavProps.pastSales ? (
            <li>
              <ListItem button style={styles.listItem}>
                <NavLink className="link" to={ROUTE_CONSTANT_MAP.PAST_SALES} isActive={() => activeNav("past-sales")}>
                  <div className="icon pastSales" />
                  <div className="text">
                    <LangTextLabel lngCode="past-sales" />
                  </div>
                </NavLink>
              </ListItem>
            </li>
          ) : null}
          {topNavProps.management ? (
            <li>
              <ListItem button style={styles.listItem}>
                <NavLink className="link" to={ROUTE_CONSTANT_MAP.MANAGEMENT}>
                  <div className="icon management" />
                  <div className="text">
                    <LangTextLabel lngCode="management" />
                  </div>
                </NavLink>
              </ListItem>
            </li>
          ) : null}
          {topNavProps.logOut ? (
            <li className="listIconOnly">
              <ListItem button style={styles.listItem}>
                <NavLink className="link" to={ROUTE_CONSTANT_MAP.DASHBOARD}>
                  <div className="icon exit" />
                </NavLink>
              </ListItem>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};
export default TopNavigation;
