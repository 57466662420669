import React, { useCallback, useEffect } from "react";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import POSLayout from "../../Common/POSLayout/POSLayout";
import CardNow from "../../Common/ScreenCards/CardNow/CardNow";
import DataNotFound from "../../Common/DataNotFound";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { initLayout, setHeaderDatePicker } from "../../../redux/layout/layoutSlice";
import { getTicketingShowTimes } from "../../../redux/ticketing/ticketingAction";
import "./Ticketing.scss";
import { setCurrentDate } from "../../../redux/ticketing/ticketingSlice";

const TicketingNow: React.FC = () => {
  const cardItems = useAppSelector((state) => state.ticketing.cardItems);
  const currentDate = useAppSelector((state) => state.ticketing.currentDate);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initLayout());
    dispatch(setHeaderDatePicker(true))
    dispatch(setCurrentDate(currentDate));
    //dispatch(setCurrentDate(moment().format("YYYY-MM-DD")))
    return () => {
      dispatch(setHeaderDatePicker(false))
    }
  }, []);

  const loadProgrammsList = useCallback(() => {
    if (currentDate) {
      dispatch(getTicketingShowTimes(currentDate));
    }
  }, [currentDate]);

  useEffect(() => {
    loadProgrammsList();
  }, [loadProgrammsList]);

  return (
    <POSLayout>
      <div className="gridScrollContainer">
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            {cardItems.map((cardObj, index) => (
              <Grid container item md={4} key={index}>
                <CardNow cardObj={cardObj} currentDate={currentDate} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </POSLayout>
  );
};

export default TicketingNow;
