import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { openAlertBox, updateAlertBoxContent } from "../../redux/alertBox/alertBoxSlice";
import { getTicketingShowTimeSeatPlan } from "../../redux/ticketing/ticketingAction";
import { updateSeatObjFromSeat } from "../../redux/ticketing/ticketingSlice";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import SeatBox from "./SeatBox";
import SeatLayout from "./SeatLayout";
import "./SeatPlan.scss";

interface SeatPlanProps {
  showTimeId: string;
}

const SeatPlan = (props: SeatPlanProps) => {
  const { showTimeId } = props;
  const dispatch = useAppDispatch();
  const { selectedShowTime, selectedSeatsList } = useAppSelector(
    (state) => state.ticketing
  );

  // useEffect(() => {
  //   if (showTimeId) {
  //     // let _selectedSeatsList = selectedSeatsList.map(function(item) {
  //     //   return item['label'];
  //     // });
  //     // console.log(_selectedSeatsList, "seat plan load")
  //     // dispatch(getTicketingShowTimeSeatPlan(showTimeId, _selectedSeatsList));
  //   }
  // }, [showTimeId]);

  const seatBoxSelect = (seatObj: any) => {
    dispatch(
      updateSeatObjFromSeat({
        ...seatObj,
        seats:
          selectedShowTime?.seatsObj && selectedShowTime?.seatsObj.seatPlans,
      })
    );
  };

  if (!selectedShowTime || !selectedShowTime.seatsObj || selectedShowTime?.seatsObj.seatPlans.length === 0) {
    dispatch(
      updateAlertBoxContent({
        alertType: "error",
        message: "Seat plan loading failed",
      })
    );
    dispatch(openAlertBox());
    return (
      <div>
        <Redirect to={{ pathname: ROUTE_CONSTANT_MAP.TICKETING_NOW }} />
      </div>
    );
  }
  
  return (
    <>
      <SeatLayout
        maxPositions={selectedShowTime.seatsObj?.maxPositions}
        seats={
          <>
            {selectedShowTime &&
              selectedShowTime.seatsObj &&
              selectedShowTime?.seatsObj.seatPlans.map((seatObj, i) => (
                <SeatBox
                  key={`seat_${seatObj.id}`}
                  id={seatObj.id}
                  label={seatObj.label}
                  type={seatObj.seatStatus}
                  top={`${parseInt(seatObj.y) * 1.5}px`}
                  left={`${parseInt(seatObj.x) * 1.8}px`}
                  onSeatClick={seatBoxSelect}
                />
              ))}
          </>
        }
      />
    </>
  );
};
export default SeatPlan;
