import { API } from "./Api";
import API_CONSTANT_MAP from "./api-url";

export default {
  async getConcessions() {
    var response = await API.get(API_CONSTANT_MAP.GET_CONCESSIONS);
    return response.data;
  },


  /**
   * issuing consession or validate the consession reference
   * @param data 
   * @returns 
   */
   async issuingConsession(data: any) {
    var response = await API.post(API_CONSTANT_MAP.ISSUING_CONSESSION, data);
    return response.data;
  },
};
