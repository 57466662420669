import React, {
  ReactNode,
  useRef,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Route } from "react-router-dom";
import NoInternet from "../Common/NoInternet";

interface LayoutProps {
  children: ReactNode;
}
const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  return <NoInternet>{props.children}</NoInternet>;
};

const LayoutRoute: FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps: any) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default LayoutRoute;
