import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import ROUTE_CONSTANT_MAP from "../../route/routes-url";
import BookingService from "../../services/bookingService";
import { openAlertBox, updateAlertBoxContent } from "../alertBox/alertBoxSlice";
import { basketSlice, calculateTarifCountAndTotalPrice, setBasketSelectedShowtimes, setSelectedPaymentMethod, setSelectedShowTime, updateSelectedPaymentMethod } from "../basket/basketSlice";
import { layoutSlice } from "../layout/layoutSlice";
import { paymentSlice, setPaymentCampaign } from "../payment/paymentSlice";
import { setTicketPrintable } from "../printTicket/printTicketSlice";
import { concessionsSlice } from "../concessions/concessionsSlice";
import { RootState } from "../store";
import { PaymentMethodState, OrderBookingState } from "./basketModel";
import { getTicketingShowTimes } from "../ticketing/ticketingAction";
import moment from "moment";
import { setCurrentDate } from "../ticketing/ticketingSlice";

const layoutActions = layoutSlice.actions;
const basketActions = basketSlice.actions;
const paymentActions = paymentSlice.actions;
const consessionActions = concessionsSlice.actions;

export const createBooking = (
  bookingData: any,
  history: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await BookingService.createBooking(bookingData);
      const { data, status, message, status_code } = response;

      if (!status) {

        if(status_code === 1100) {
          dispatch(consessionActions.setConsessionNotAvailableList(data))
        }

        if(status_code === 1110) {
          const selectedShowtimes = getState().basket.basketObj.ticketing.selectedShowTimes;
          
          if(data && data.unavailable) {
            const unAvailableList = data.unavailable;

            let _tempSelectedShowtimes: any[] = selectedShowtimes;
            unAvailableList.forEach((showTimeId: any) => {
              _tempSelectedShowtimes = _tempSelectedShowtimes.filter((obj) => { return obj.id !== showTimeId});
            });

            dispatch(setBasketSelectedShowtimes(_tempSelectedShowtimes));
            dispatch(calculateTarifCountAndTotalPrice())
          }
        }

        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: message ? message : "Create booking failed",
          })
        );
        dispatch(openAlertBox());
        return;
      }

      const { reservation_id, total_amount, payment_methods = [], bookings, campaign } = data;

      /**
       * format campaign
       */
      if(campaign) {
        const { id: campaignId, name: campaignName, image: campaignImage } = campaign;
        dispatch(setPaymentCampaign({
          id: campaignId,
          name: campaignName,
          image: campaignImage
        }))
      }

      let paymentMethodArr: PaymentMethodState[] = [];
      payment_methods.forEach(
        (paymentObj: {
          id: any;
          name: any;
          payment_method_id: any;
          icon: any;
        }, index: number) => {
          const { id, name, payment_method_id, icon } = paymentObj;

          const paymentDataObj: PaymentMethodState = {
            id,
            name,
            paymentMethodId: payment_method_id,
            icon,
          };

          paymentMethodArr.push(paymentDataObj);
        }
      );

      bookings && bookings.map((booking: any) => {
        const { uuid, showtime_id } = booking;
        dispatch(basketActions.updateBookedShowtimeUuid({showTime: showtime_id, uuid: uuid}));
      })
      
      dispatch(basketActions.setPaymentMethods(paymentMethodArr));
      dispatch(basketActions.setTotalPrice(total_amount));
      dispatch(basketActions.setPaybleamount(total_amount));
      dispatch(basketActions.setOrderReservationID(reservation_id));

      history.push(ROUTE_CONSTANT_MAP.PAYMENT);
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({ alertType: "error", message: "Booking failed" })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const setPaymentMethod = (
  paymentMethodData: any,
  loyaltyRedeemedValue: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await BookingService.setPaymentMethod(
        paymentMethodData
      );
      const { data, status, message } = response;

      if (!status) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: message ? message : "Payment method select failed",
          })
        );
        dispatch(openAlertBox());
        return;
      }

      dispatch(setSelectedPaymentMethod(paymentMethodData.paymentObj));

      const { total_amount, discount_amount, tax_data, booking_fee, bookings } = data;

      dispatch(basketActions.setTotalPrice(total_amount.toFixed(2)));

      const calcPayble = (Number(total_amount) - Number(loyaltyRedeemedValue)).toFixed(2);

      dispatch(basketActions.setPaybleamount(calcPayble))

      if(bookings && bookings.length > 0) {

        const _bookings: OrderBookingState[] = [];

        bookings.forEach((bookingObj: any) => {
          
          const { discount_amount, discount_name, uuid, showtime_id } = bookingObj;

          const _bookingObj:OrderBookingState = {
            uuid: uuid,
            discountAmount: discount_amount.toFixed(2),
            discountName: discount_name,
            isConcession: showtime_id === "concessions" ? true : false
          }

          _bookings.push(_bookingObj)
        });

        dispatch(basketActions.setOrderBookings(_bookings));
      }
      
      if (discount_amount) {
        dispatch(
          basketActions.setDiscountData({
            discountAmount: discount_amount
          })
        );
      } else {
        dispatch(
          basketActions.setDiscountData(null)
        );
      }

      if (tax_data) {
        const { concession_tax, ticket_tax } = tax_data;
        dispatch(
          basketActions.setTxData({
            tax: (parseFloat(concession_tax) + parseFloat(ticket_tax)).toFixed(
              2
            ),
            bookingFee: booking_fee,
          })
        );
      } else {
        dispatch(
          basketActions.setTxData(null)
        );
      }

      //dispatch(setTicketPrintable(true));
    } catch (ex) {
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const completeBooking = (
  bookingData: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    dispatch(paymentActions.setCompleteBookingStatus("started"));
    try {
      const response: any = await BookingService.completeBooking(bookingData);
      const { data, status, message } = response;

      if (!status) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: message ? message : "Payment method select failed",
          })
        );
        dispatch(openAlertBox());
        return;
      }

      const { reference_number } = data;

      if (reference_number) {
        dispatch(basketActions.setOrderReference(reference_number));
        dispatch(setCurrentDate(moment().format("YYYY-MM-DD")))
        dispatch(basketActions.setIsPaymentSuccesModalOpen(true));
        return;
      }

      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: "Booking completion failed",
        })
      );
      dispatch(openAlertBox());
      return;
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: "Booking completion failed",
        })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const printTicket = (
  bookingData: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await BookingService.printTicket(bookingData);
      const { data, status } = response;

      if (!status) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: "Booking completion failed",
          })
        );
        dispatch(openAlertBox());
        return;
      }

      //need to print ticket here
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: "Booking completion failed",
        })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const cancelBooking = (
  bookingData: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await BookingService.cancelBooking(bookingData);
      const { data, status, message } = response;

      if (!status) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: message ? message : "Booking cancelation failed",
          })
        );
        dispatch(openAlertBox());
        return;
      }
      dispatch(getTicketingShowTimes(getState().ticketing.currentDate));
      // dispatch(
      //   updateAlertBoxContent({
      //     alertType: "success",
      //     message: "Booking cancelation success"
      //   })
      // );
      // dispatch(openAlertBox());
      return;
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: "Booking completion failed",
        })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};