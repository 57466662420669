import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import {
  setCurrentPaymentSenceMessage,
  setPaymentSenseMessageHistory,
} from "../redux/payment/paymentSlice";
import { useAppDispatch } from "./useRedux";

interface usePaymentSenseProps {
  socketUrl: string;
}

interface SignatureRequestState {
  applicationId: string;
  applicationLabel: string;
  authCode: string;
  cardholderVerificationMethod: string;
  paymentMethod: string;
  primaryAccountNumber: string;
  receiptLines: any;
  tid: string;
  timeout: number;
  transactionType: any;
}
const usePaymentSense = (props: usePaymentSenseProps) => {
  const { socketUrl } = props;
  const dispatch = useAppDispatch();
  const [currentSocketUrl, setCurrentSocketUrl] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [inputtedMessage, setInputtedMessage] = useState("");
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,
    {
      share: true,
      shouldReconnect: () => false,
    }
  );

  useEffect(() => {
    if (lastMessage) {
      setMessageHistory((prev) => prev.concat(JSON.parse(lastMessage.data)));
      dispatch(setCurrentPaymentSenceMessage(JSON.parse(lastMessage.data)));
      dispatch(setPaymentSenseMessageHistory(JSON.parse(lastMessage.data)));
    }
  }, [lastMessage]);

  const readyStateString = {
    "-1": "",
    "0": "CONNECTING",
    "1": "OPEN",
    "2": "CLOSING",
    "3": "CLOSED",
  }[readyState];

  const terminalDetails = (terminalId: string) => {
    sendMessage(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "terminalDetails",
        params: {
          tid: terminalId,
        },
        id: 1,
      })
    );
  };

  const performTransaction = (
    terminalId: string,
    currency: string,
    amount: number | string
  ) => {
    sendMessage(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "sale",
        params: {
          tid: terminalId,
          currency: currency,
          amount: amount,
        },
        id: 2,
      })
    );
  };

  const performRefundTransaction = (
    terminalId: string,
    currency: string,
    amount: number | string
  ) => {
    sendMessage(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "refund",
        params: {
          tid: terminalId,
          currency: currency,
          amount: amount,
        },
        id: 9,
      })
    );
  };

  const cancelTransaction = (terminalId: string) => {
    sendMessage(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "cancelTransaction",
        params: {
          tid: terminalId,
        },
        id: 3,
      })
    );
  };

  const signartureVerificationRequest = (
    params: any,
    signaturAccept: boolean
  ) => {
    sendMessage(
      JSON.stringify({
        jsonrpc: "2.0",
        id: params,
        result: { accepted: signaturAccept },
      })
    );
  };

  const duplicateTransaction = (terminalId: string) => {
    sendMessage(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "duplicate",
        params: { tid: terminalId },
        id: 7,
      })
    );
  };

  const sendEndOfDayReport = (terminalId: string) => {
    sendMessage(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "reportEndOfDay",
        params: { tid: terminalId },
        id: 8,
      })
    );
  };

  return {
    terminalDetails,
    performTransaction,
    cancelTransaction,
    signartureVerificationRequest,
    duplicateTransaction,
    sendEndOfDayReport,
    performRefundTransaction
  };
};

export default usePaymentSense;
