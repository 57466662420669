const NumberPadOnlyLayout = {
  default: [
    '7 8 9',
    '4 5 6',
    '1 2 3',
    '. 0 {backspace}',
  ],
  otp: [
    '7 8 9',
    '4 5 6',
    '1 2 3',
    ' 0 {backspace}',
  ],
};

export default NumberPadOnlyLayout;
