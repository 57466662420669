import parse from 'html-react-parser';
import React from "react";
import "./ComponentToPrint.scss";

interface ComponentToPrintProps {
  ticketPrintData: any;
}
const ComponentToPrint = React.forwardRef<any, ComponentToPrintProps>(
  (props, ref) => {
    const { ticketPrintData } = props;
    return <div ref={ref}>{parse(`${ticketPrintData}`)}</div>;
  }
);
export default ComponentToPrint;
