import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
// import moment from 'moment';
import moment from "moment-timezone";
import CalendarView from "../../Common/CalendarView";
import NumberPad from "../../Common/KeyboardLayouts/NumberPad/NumberPad";
import CustomInput from "../../Common/CustomInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../../Common/InputError";
import "./LoyaltyPaymentModal.scss";
import PriceConverter from "../../Common/PriceConverter";
import LangTextLabel from "../../Common/LangTextLabel";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import loylatyIcon from "../../../assets/icons/payment/cash.svg";


interface LoyaltyPaymentModalProps {}

interface LoyaltyPaymentModalRef {
  getNumberValue(): void;
  getPointsValue(): void;
}





const LoyaltyPaymentModal = React.forwardRef<
  LoyaltyPaymentModalRef,
  LoyaltyPaymentModalProps
>((props, ref) => {
  const keyboardRef = useRef<any>(null);
  const currencySymbol = useAppSelector((state) => state.auth.currencySymbol);
  const { paymentCampaign, loyaltyData } = useAppSelector(
    (state) => state.payment
  );
  const { paybleAmount } = useAppSelector(
    (state) => state.basket
  );
  const [avaialableValue, setAvailableValue] = useState<any>(0);
  const [numberValue, setNumberValue] = useState<any>(0);
  const [redeemValue, setRedeemValue] = useState<any>(null);

  useEffect(() => {
    if (numberValue && loyaltyData) {
      const redeemVal = Math.round(numberValue / loyaltyData.burnRatio)
      setRedeemValue(redeemVal);
    } else {
      setRedeemValue(0);
    }
  }, [numberValue]);

  const numberPadSchema = Yup.object().shape({
    numberValue: Yup.number().required("Filed is required"),
  });

  const formik = useFormik({
    initialValues: {
      numberValue: "",
    },
    validationSchema: numberPadSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values));
    },
  });

  useEffect(() => {
    if(loyaltyData) {
      const numberVal = (loyaltyData.availablePoints * loyaltyData.burnRatio).toFixed(2)
      setNumberValue(numberVal)
      setAvailableValue(numberVal)
      formik.setFieldValue("numberValue", numberVal);
    }
  },[])

  const onChangeKeyboard = (input: any) => {
    formik.setFieldValue("numberValue", input);
    setNumberValue(input);
    keyboardRef.current && keyboardRef.current.setInput(input);
  };

  const handleInputChange = (event: any) => {
    formik.setFieldValue("numberValue", event.target.value);
    let inputValue = event.target.value;
    if (event.target.value.length === 0) {
      inputValue = 0;
    }
    setNumberValue(inputValue);
    keyboardRef.current && keyboardRef.current.setInput(inputValue);
  };

  useImperativeHandle(ref, () => ({
    getNumberValue() {
      let isError = false;
      if (!formik.values.numberValue) {
        formik.setFieldError("numberValue", "Field is required");
        isError = true;
      } else if (
        loyaltyData &&
        Number(formik.values.numberValue) > Number(avaialableValue)
      ) {
        formik.setFieldError(
          "numberValue",
          "Insufficient balance. Please enter valid amount to continue"
        );
        isError = true;
      } else if(Number(formik.values.numberValue) > Number(paybleAmount)) {
        formik.setFieldError(
          "numberValue",
          "Cannot redeeem more than payble amount"
        );
        isError = true;
      }

      if (!isError) {
        return formik.values.numberValue;
      }
    },
    getPointsValue() {
        return redeemValue;
    },
  }));

  return (
    <div className="priceDetailsWrapper">
      <div className="paymentImageWrapper">
        <img className="img-fluid" src={paymentCampaign?.image} />
      </div>
      <div className="priceWrapper">
        {`You have earned ${currencySymbol}${avaialableValue}(${loyaltyData?.availablePoints} points)`}
      </div>
      <div>
        <div className="cashdescWrapper">
          <LangTextLabel lngCode="enter-the-points-you-want-to-redeem" />
        </div>
        <div>
          <CustomInput
            name="numberValue"
            onChange={(e) => handleInputChange(e)}
            type="number"
            value={formik.values.numberValue}
          />
        </div>
        <div>
          {formik.errors.numberValue ? (
            <InputError error={formik.errors.numberValue} />
          ) : null}
        </div>

        <div className="numberPadWrap">
          <NumberPad
            keyboardRef={keyboardRef}
            onChange={onChangeKeyboard}
            inputName="countModInput"
            currentValue={""}
            layout={'default'}
          />
        </div>
        <div className="returnPriceWrapper">
          <LangTextLabel lngCode="redeem" /> {currencySymbol}
          <PriceConverter priceToConvert={numberValue} />{" "}
          {`(${redeemValue} points)`}
        </div>
      </div>
    </div>
  );
});
export default LoyaltyPaymentModal;
