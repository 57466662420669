import React, { Component } from "react";
import { useAppSelector } from "../../../hooks/useRedux";
import CircularIndeterminate from "./CircularIndeterminate";
import "./PageLoader.scss";

const PageLoader = () => {
  const pageLoaderText = useAppSelector((state) => state.layout.pageLoaderText);

  return (
    <div className="loaderCont">
      <div className="loaderWrap">
        <CircularIndeterminate />
        {pageLoaderText !== "" ? <div className="loadingText">{pageLoaderText}</div> : null}
      </div>
    </div>
  );
};
export default PageLoader;
