import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import AuthService from "../../services/authService";
import BookingService from "../../services/bookingService";
import { openAlertBox, updateAlertBoxContent } from "../alertBox/alertBoxSlice";
import { layoutSlice } from "../layout/layoutSlice";
import { RootState } from "../store";
import { setLoyaltyCustomerEmailModalVisibility, setLoyaltyCustomerOtpModalVisibility, setLoyaltyData, setLoyaltyPointsModalVisibility } from "./paymentSlice";

const layoutActions = layoutSlice.actions;

export const sendEOD = (
  reportData: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await BookingService.sendEodReport(reportData);
      const { data, status, message } = response;

      if (!status) {
        dispatch(
          updateAlertBoxContent({
            alertType: "error",
            message: message ? message : "EOD Report sending failed",
          })
        );
        dispatch(openAlertBox());
        return;
      } else {
        dispatch(
          updateAlertBoxContent({
            alertType: "success",
            message: message ? message : "EOD Report sending success",
          })
        );
        dispatch(openAlertBox());
        return;
      }
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: "EOD Report sending failed",
        })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const validateCustomer = (
  cutomerData: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await AuthService.validateCustomer(cutomerData);
      const { data, status, message } = response;

      if (!status) {
        dispatch(
          updateAlertBoxContent({
            alertType: "validation",
            message: message ? message : "Customer validate failed",
          })
        );
        dispatch(openAlertBox());
        return;
      } else {
        dispatch(setLoyaltyCustomerEmailModalVisibility(false));
        dispatch(setLoyaltyCustomerOtpModalVisibility(true))
        return;
      }
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: "Customer validate failed",
        })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};

export const validateCustomerOtp = (
  cutomerData: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(layoutActions.setIsPageLoader(true));
    try {
      const response: any = await AuthService.validateCustomerOtp(cutomerData);
      const { data, status, message } = response;

      if (!status) {
        dispatch(
          updateAlertBoxContent({
            alertType: "validation",
            message: message ? message : "OTP validatation failed",
          })
        );
        dispatch(openAlertBox());
        return;
      } else {

        const { loyalty } = data;  
        if(loyalty) {
          const { redeemable_points, burn_ratio, total_points, tier } = loyalty;
          dispatch(setLoyaltyData({
            availablePoints: redeemable_points,
            burnRatio: parseFloat(burn_ratio),
            totalPoints: total_points,
            tier: tier
          }))
        }      
        dispatch(setLoyaltyCustomerOtpModalVisibility(false))
        dispatch(setLoyaltyPointsModalVisibility(true))
        return;
      }
    } catch (ex) {
      dispatch(
        updateAlertBoxContent({
          alertType: "error",
          message: "OTP validatation failed",
        })
      );
      dispatch(openAlertBox());
      console.log(ex);
    } finally {
      dispatch(layoutActions.setIsPageLoader(false));
    }
  };
};
