import PropTypes from "prop-types";
import React, { Component } from "react";
import LangTextLabel from "../Common/LangTextLabel";
import "./SampleTemplate.scss";

const SampleTemplate = (props: any) => {
  const { pageName } = props;
  return (
    <div className="sampleTemplate">
      <div className="templateContent">
        <div className="pageTitle">
          <LangTextLabel lngCode={pageName} />
        </div>
        <div className={`${pageName} pageIcon`} />
      </div>
    </div>
  );
};

export default SampleTemplate;
