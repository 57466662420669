import React from 'react';
import { useAppSelector } from '../../../hooks/useRedux';

interface PriceConverterProps {
  priceToConvert: any
}

const PriceConverter: React.FC<PriceConverterProps> = (props: PriceConverterProps) => {

  const { priceToConvert } = props;
  const currencySeperator = useAppSelector((state) => state.auth.currencySeperator)
  const thousandSeperator = useAppSelector((state) => state.auth.thousandSeperator)

  const formatNumber = (val: any) => {
    let str = val;
    if (str === undefined || str === null) {
      return str;
    }
    const decimalSpliter = str.toString()
      .replace(/\s+/g, '')
      .split('.');
    if (decimalSpliter.length > 1) {
      decimalSpliter[0] = decimalSpliter[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeperator);
    } else {
      decimalSpliter[1] = '00';
    }
    str = decimalSpliter.join(currencySeperator);

    return `${str}`;
  }

  return (
    <>
      {formatNumber(priceToConvert)}
    </>
  );
}

export default PriceConverter;
