import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PastSalesModel,
  SingleSaleModel,
  PrintDetailsModel,
} from "./pastSalesModel";

// Define the initial state using that type
const initialState: PastSalesModel = {
  pastSalesDetails: [],
  totalRows: 0,
  singleSaleObj: null,
  printDetailsObj: null,
  receiptScanModalVisibility: false
};

export const pastSalesSlice = createSlice({
  name: "pastSales",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPastSales(state, action: PayloadAction<[]>) {
      state.pastSalesDetails = action.payload;
    },

    setTotalRows(state, action: PayloadAction<number>) {
      state.totalRows = action.payload;
    },

    setSinglePastDetails(state, action: PayloadAction<SingleSaleModel | null>) {
      state.singleSaleObj = action.payload;
    },

    setPrinttDetails(state, action: PayloadAction<PrintDetailsModel | null>) {
      state.printDetailsObj = action.payload;
    },
    clearPastSales(state) {
      state.pastSalesDetails = [];
      state.totalRows = 0;
      state.singleSaleObj = null;
      state.printDetailsObj = null;
    },

    setReceiptScanModalVisibility(state, action: PayloadAction<boolean>) {
      state.receiptScanModalVisibility = action.payload;
    },
  },
});

export default pastSalesSlice.reducer;
