import React from "react";
import { Route, Switch } from "react-router-dom";
import LayoutRoute from "../components/Layout";
import AuthLayout from "../components/AuthLayout";
import { getRoutes } from "./router-links";
import Page404 from "../pages/Page404";

const AppRoutes = () => {
  const routeLinks = getRoutes();
  return (
    <Switch>
      {routeLinks.map((route, i: number) => (
        route.auth ? <AuthLayout
          key={`ar_${i}`}
          path={route.path}
          exact={route.exact}
          component={route.component}
        /> :
          <LayoutRoute
            key={`r_${i}`}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
      ))}
      <Route path="*" component={Page404} />
    </Switch>
  );
};

export default AppRoutes;
