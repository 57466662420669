import { API } from "./Api";
import API_CONSTANT_MAP from "./api-url";

export default {
  async createBooking(data: any) {
    var response = await API.post(API_CONSTANT_MAP.CREATE_BOOKING, { ...data });
    return response.data;
  },
  async setPaymentMethod(data: any) {
    var response = await API.post(API_CONSTANT_MAP.SET_PAYMENT_METHOD, {
      ...data,
    });
    return response.data;
  },
  async completeBooking(data: any) {
    var response = await API.post(API_CONSTANT_MAP.COMPLETE_BOOKING, {
      ...data,
    });
    return response.data;
  },
  async cancelBooking(data: any) {
    var response = await API.post(API_CONSTANT_MAP.CANCEL_BOOKING, {
      ...data,
    });
    return response.data;
  },
  async printTicket(data: any) {
    var response = await API.post(API_CONSTANT_MAP.WEB_PRINT, { ...data });
    return response.data;
  },
  async sendEodReport(data: any) {
    var response = await API.post(API_CONSTANT_MAP.SEND_EOD_REPORT, {
      ...data,
    });
    return response.data;
  },
};
