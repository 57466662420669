import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface KeyboardState {
  visibility: boolean;
  keyboardInputName: string;
  keyboardInputValue: string;
}

// Define the initial state using that type
const initialState: KeyboardState = {
  visibility: false,
  keyboardInputName: "",
  keyboardInputValue: ""
};

export const keyboardSlice = createSlice({
  name: "keyboard",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setKeyboardVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.visibility = payload;
    },
    setKeyboardInputName: (state, { payload }: PayloadAction<string>) => {
      state.keyboardInputName = payload;
    },
    setKeyboardInputValue: (state, { payload }: PayloadAction<string>) => {
      state.keyboardInputValue = payload;
    },
    clearKeyboard: (state) => {
      state.visibility = false;
      state.keyboardInputName = "";
      state.keyboardInputValue = "";
    }
  },
});

export const { setKeyboardVisibility, setKeyboardInputName, setKeyboardInputValue, clearKeyboard } = keyboardSlice.actions;

export default keyboardSlice.reducer;
