import React from "react";
// import moment from 'moment';
import moment from "moment-timezone";

interface CurrentDateProps {
  curDate?: string
  format?: string
}
const CurrentDate = (props: CurrentDateProps) => {
  const { curDate, format } = props;
  const _curDate = moment().format(format ? format : "DD/MM/YYYY");

  const formatedDate = curDate ? moment(curDate).format(format ? format : "DD/MM/YYYY") : _curDate;

  const currentDate = <span className="date">{formatedDate}</span>;
  return <>{currentDate}</>;
};
export default CurrentDate;

