import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
// import moment from 'moment';
import moment from "moment-timezone";
import CalendarView from "../../Common/CalendarView";
import NumberPad from "../../Common/KeyboardLayouts/NumberPad/NumberPad";
import CustomInput from "../../Common/CustomInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputError from "../../Common/InputError";
import "./ValidatePaymentModal.scss";
import PriceConverter from "../../Common/PriceConverter";
import LangTextLabel from "../../Common/LangTextLabel";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import {
  setCompleteBookingStatus,
  setIsPaymentConfirmEnabled,
} from "../../../redux/payment/paymentSlice";

interface ValidatePaymentModalProps {
  paymentMethod: any;
  total: string | number;
}

interface ValidatePaymentModalRef {
  getNumberValue(): void;
}
const ValidatePaymentModal = React.forwardRef<
  ValidatePaymentModalRef,
  ValidatePaymentModalProps
>((props, ref) => {
  const keyboardRef = useRef<any>(null);
  const { paymentMethod, total } = props;
  const currencySymbol = useAppSelector((state) => state.auth.currencySymbol);
  const dispatch = useAppDispatch();

  const [numberValue, setNumberValue] = useState<any>(null);
  const [balance, setBalance] = useState<any>(null);
  const [visibleBalance, setVisibleBalance] = useState<any>(true);

  const numberPadSchema = Yup.object().shape({
    numberValue: Yup.number().required("Filed is required"),
  });

  const formik = useFormik({
    initialValues: {
      numberValue: "",
    },
    validationSchema: numberPadSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    },
  });

  const onChangeKeyboard = (input: any) => {
    formik.setFieldValue("numberValue", input);
    setNumberValue(input);
  };

  const handleInputChange = (event: any) => {
    formik.setFieldValue("numberValue", event.target.value);
    let inputValue = event.target.value;
    if (event.target.value.length === 0) {
      inputValue = 0;
    }
    setNumberValue(inputValue);
  };

  useEffect(() => {
    dispatch(setCompleteBookingStatus(""));
    formik.setFieldValue("numberValue", total);
    setNumberValue(total);
  }, []);

  useEffect(() => {
    if (numberValue) {
      let _balance = (parseFloat(numberValue) - parseFloat(`${total}`)).toFixed(
        2
      );
      setBalance(_balance);
    } else {
      setBalance(-1);
    }
  }, [numberValue]);

  useEffect(() => {
    if (parseFloat(`${balance}`) >= 0) {
      setVisibleBalance(true);
      dispatch(setIsPaymentConfirmEnabled(true));
    } else {
      setVisibleBalance(false);
      dispatch(setIsPaymentConfirmEnabled(false));
    }
  }, [balance]);

  return (
    <div className="priceDetailsWrapper">
      {paymentMethod.icon ? (
        <div className="paymentImageWrapper">
          <img className="img-fluid" src={paymentMethod.icon} />
        </div>
      ) : null}
      <div className="priceWrapper">
        {currencySymbol}
        <PriceConverter priceToConvert={total} />
      </div>
      {paymentMethod.id !== "3" ? (
        <div className="descWrapper">
          <LangTextLabel lngCode="validate-payment-with-customer" />
        </div>
      ) : null}
      {paymentMethod.id === "3" ? (
        <div>
          <div className="cashdescWrapper">
            <LangTextLabel lngCode="enter-the-cash-amount-received-by-the-customer" />
          </div>
          <div className="formWrap">
            <CustomInput
              name="numberValue"
              onChange={(e) => handleInputChange(e)}
              type="number"
              value={formik.values.numberValue}
            />
          </div>
          <div>
            {formik.touched.numberValue && formik.errors.numberValue ? (
              <InputError error={formik.errors.numberValue} />
            ) : null}
          </div>

          <NumberPad
            keyboardRef={keyboardRef}
            onChange={onChangeKeyboard}
            inputName="countModInput"
            currentValue={""}
          />
          {visibleBalance ? (
            <div className="returnPriceWrapper">
              <LangTextLabel lngCode="return" /> {currencySymbol}
              <PriceConverter priceToConvert={balance} />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
});
export default ValidatePaymentModal;
