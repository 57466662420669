/* eslint-disable */
const API_CONSTANT_MAP = {
  LOGIN: "pos/counter/login",
  GET_SHOWTIMES: "pos/ticket_reservation/get_showtimes",
  GET_CONCESSIONS: "pos/concessions/get_sellable_products",
  GET_PAST_SALES: "pos/ticket_sales/past_sales",
  GET_SINGLE_PAST_SALE: "pos/ticket_sales/past_sales_single",
  GET_PRINT_DETAILS: "pos/ticket_reservation/web_print",
  REFUND: "pos/ticket_sales/refund",
  CREATE_BOOKING: "pos/ticket_reservation/create_booking",
  SET_PAYMENT_METHOD: "pos/ticket_reservation/select_payment_method",
  COMPLETE_BOOKING: "pos/ticket_reservation/complete_booking",
  CANCEL_BOOKING: "pos/ticket_reservation/cancel_booking",
  WEB_PRINT: "pos/ticket_reservation/web_print",
  GET_SHOWTIME_SEAT_PLAN: "pos/ticket_reservation/check_seats",
  SEND_EOD_REPORT: "pos/eod_report/post_eod",
  ISSUING_CONSESSION: "pos/concessions/issuing",
  COUNTER_VALIDATE: "pos/counter/counter_validate",
  VALIDATE_CUSTOMER: "pos/loyalty/validate_customer",
  VALIDATE_CUSTOMER_OTP: "pos/loyalty/validate_otp",
};

export default API_CONSTANT_MAP;
