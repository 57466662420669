import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthModal, UserModel, TerminalModel } from "./authModel";

// Define the initial state using that type
const initialState: AuthModal = {
  user: null,
  terminal: null,
  validationError: null,
  concessionEnabled: false,
  currencySymbol: "$",
  currencyCode: "USD",
  posVersion: "",
  idleTime: 10,
  dateFormat: "MM-DD-YYYY",
  timeFormat: "LTS",
  managerValidation: "",
  dateFormatPlaceholder: "MM-DD-YYYY",
  currencySeperator: ".",
  thousandSeperator: ","
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserModel | null>) {
      state.user = action.payload;
    },

    setTerminal(state, action: PayloadAction<TerminalModel | null>) {
      state.terminal = action.payload;
    },

    setValidationError(state, action: PayloadAction<string | null>) {
      state.validationError = action.payload;
    },

    clearAuth(state) {
      state.user = null;
      state.terminal = null;
      state.validationError = null;
    },

    setConcessionEnable(state, action: PayloadAction<boolean>) {
      state.concessionEnabled = action.payload;
    },

    setCurrencySymbol(state, action: PayloadAction<string>) {
      state.currencySymbol = action.payload;
    },

    setCurrencyCode(state, action: PayloadAction<string>) {
      state.currencyCode = action.payload;
    },

    setPosVersion(state, action: PayloadAction<string>) {
      state.posVersion = action.payload;
    },

    setIdleTime(state, action: PayloadAction<number>) {
      state.idleTime = action.payload;
    },

    setDateFormat(state, action: PayloadAction<string>) {
      state.dateFormat = action.payload;
    },

    setTimeFormat(state, action: PayloadAction<string>) {
      state.timeFormat = action.payload;
    },

    setManagerValidation(state, action: PayloadAction<string>) {
      state.managerValidation = action.payload;
    },

    setDateFormatPlaceholder(state, action: PayloadAction<string>) {
      state.dateFormatPlaceholder = action.payload;
    },

    setCurrencySeperator(state, action: PayloadAction<string>) {
      state.currencySeperator = action.payload;
    },

    setThousandSeperator(state, action: PayloadAction<string>) {
      state.thousandSeperator = action.payload;
    },
  },
});
