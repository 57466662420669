import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertBoxState, AlertType } from "./alertBoxModel";

// Define the initial state using that type
const initialState: AlertBoxState = {
  alertType: 'error',
  title: '',
  message: '',
  isOpen: false,
};

export const alertBoxSlice = createSlice({
  name: "alertBox",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearAlertBox: (state) => {
      state.alertType= 'error';
      state.title= '';
      state.message= '';
      state.isOpen= false;
    },
    openAlertBox: (state) => {
      state.isOpen = true;
    },
    closeAlertBox: (state) => {
      state.isOpen = false;
    },
    updateAlertBoxContent: (state, { payload: { alertType, message } }: PayloadAction<{alertType: AlertType, message: string}>) => {
      state.alertType = alertType;
      state.message = message;
    }
  },
});

export const { clearAlertBox, openAlertBox, closeAlertBox, updateAlertBoxContent } = alertBoxSlice.actions;

export default alertBoxSlice.reducer;
