import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaymentCampaignState {
  id: string
  name: string,
  image: string
}

interface LoyaltyDataState {
  availablePoints: number
  burnRatio: number,
  totalPoints: number,
  tier: string
}

// Define a type for the slice state
interface PaymentState {
  currentPaymentSenceMessage: any;
  paymentSenseMessageHistory: any[];
  completeBookingStatus: string;
  paymentSenseNotifiction: string;
  paymentSenseSignatureRequestParams: any;
  paymentSenseResult: any;
  isPaymentConfirmEnabled: boolean,
  loyaltyCustomerEmailModalVisibility: boolean
  loyaltyCustomerOtpModalVisibility: boolean
  loyaltyPointsModalVisibility: boolean,
  customerLoyaltyEmail: string,
  paymentCampaign: PaymentCampaignState | null,
  loyaltyData: LoyaltyDataState | null,
  pointsToRedeem: number,
  pointsValueToRedeem: number
}

// Define the initial state using that type
const initialState: PaymentState = {
  currentPaymentSenceMessage: null,
  paymentSenseMessageHistory: [],
  completeBookingStatus: "",
  paymentSenseNotifiction: "",
  paymentSenseSignatureRequestParams: null,
  paymentSenseResult: null,
  isPaymentConfirmEnabled: true,
  loyaltyCustomerEmailModalVisibility: false,
  loyaltyCustomerOtpModalVisibility: false,
  loyaltyPointsModalVisibility: false,
  customerLoyaltyEmail: "",
  paymentCampaign: null,
  loyaltyData: null,
  pointsToRedeem: 0,
  pointsValueToRedeem: 0.0
};

export const paymentSlice = createSlice({
  name: "payment",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentPaymentSenceMessage: (state, { payload }: PayloadAction<any>) => {
      state.currentPaymentSenceMessage = payload;
    },
    setPaymentSenseMessageHistory: (state, { payload }: PayloadAction<any>) => {
      state.paymentSenseMessageHistory =
        state.paymentSenseMessageHistory.concat(payload);
    },
    setPaymentSenseNotifiction: (state, { payload }: PayloadAction<any>) => {
      state.paymentSenseNotifiction = payload;
    },
    setCompleteBookingStatus: (state, { payload }: PayloadAction<any>) => {
      state.completeBookingStatus = payload;
    },
    clearPayment: (state) => {
      state.currentPaymentSenceMessage = "";
      state.paymentSenseMessageHistory = [];
      state.paymentSenseNotifiction = "";
      state.completeBookingStatus = "";
      state.paymentSenseSignatureRequestParams = null;
      state.paymentSenseResult = null;
      state.isPaymentConfirmEnabled = true;
      state.loyaltyCustomerEmailModalVisibility = false
      state.loyaltyCustomerOtpModalVisibility = false
      state.loyaltyPointsModalVisibility = false
      state.customerLoyaltyEmail = ""
      state.paymentCampaign = null
      state.loyaltyData = null,
      state.pointsToRedeem = 0;
      state.pointsValueToRedeem = 0.0;
    },
    setPaymentSenseSignatureRequestParams: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.paymentSenseSignatureRequestParams = payload;
    },
    setPaymentSenseResult: (state, { payload }: PayloadAction<any>) => {
      state.paymentSenseResult = payload;
    },
    setIsPaymentConfirmEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isPaymentConfirmEnabled = payload;
    },
    setLoyaltyCustomerEmailModalVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyCustomerEmailModalVisibility = payload;
    },
    setLoyaltyPointsModalVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyPointsModalVisibility = payload;
    },
    setLoyaltyCustomerOtpModalVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyCustomerOtpModalVisibility = payload;
    },
    setCustomerLoyaltyEmail: (state, { payload }: PayloadAction<string>) => {
      state.customerLoyaltyEmail = payload;
    },
    setPaymentCampaign: (state, { payload }: PayloadAction<PaymentCampaignState | null>) => {
      state.paymentCampaign = payload;
    },
    setLoyaltyData: (state, { payload }: PayloadAction<LoyaltyDataState | null>) => {
      state.loyaltyData = payload;
    },
    setPointsToRedeem: (state, { payload }: PayloadAction<number>) => {
      state.pointsToRedeem = payload;
    },
    setPointsValueToRedeem: (state, { payload }: PayloadAction<number>) => {
      state.pointsValueToRedeem = payload;
    },
  },
});

export const {
  setCurrentPaymentSenceMessage,
  setPaymentSenseMessageHistory,
  setPaymentSenseNotifiction,
  clearPayment,
  setPaymentSenseSignatureRequestParams,
  setPaymentSenseResult,
  setCompleteBookingStatus,
  setIsPaymentConfirmEnabled,
  setLoyaltyCustomerEmailModalVisibility,
  setLoyaltyPointsModalVisibility,
  setLoyaltyCustomerOtpModalVisibility,
  setCustomerLoyaltyEmail,
  setPaymentCampaign,
  setLoyaltyData,
  setPointsToRedeem,
  setPointsValueToRedeem
} = paymentSlice.actions;

export default paymentSlice.reducer;
