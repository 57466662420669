import React from "react";
import { css } from "../../../utility";
import LangTextLabel from "../../Common/LangTextLabel";
import "./legend.scss";

const Legend = () => {
  return (
    <>
      <div className="col-12 seatLegend">
        <div className="legendsWrap">
          <ul className="legends">
            <li className="legendBox selected">
              <span className="text">
              <LangTextLabel lngCode="Selected" />
              </span>
            </li>
            <li className="legendBox available">
              <span className="text">
              <LangTextLabel lngCode="Available" />
              </span>
            </li>          
            <li className="legendBox unavailable">
              <span className="text">
              <LangTextLabel lngCode="Unavailable" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Legend;
