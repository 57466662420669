import React, { Component } from "react";
import { ButtonBase } from "@material-ui/core";
import { css, hexToRGBA } from "../../../../utility";
import "./FoodCategory.scss";

interface FoodCategoryProps {
  label: string;
  color: string;
  active?: boolean;
  onPressHandler: any;
}

const FoodCategory = (props: FoodCategoryProps) => {
  const { label, active, onPressHandler, color } = props;
  return (
    <div className={css("cardBtn", "foodsCategory", active ? " active" : "")} style={{ backgroundColor: active ? hexToRGBA(color,0.5) : "" }}>
      <ButtonBase className={"categoryBtn"} onClick={onPressHandler}>
        <div className="btnContent">
          <div className="btnWrap">
            <div className="detailWrap">
              <div className="colorCodeWrap">
                <div className="color" style={{ backgroundColor: color }}></div>
              </div>
              <div className="titleTxt">
                <div className="text">{label}</div>
              </div>
            </div>
          </div>
        </div>
      </ButtonBase>
    </div>
  );
};

export default FoodCategory;
